import React from 'react';
import './article.style.css';

const ArticleUa = () => {
    return (
        <div className="mislivska">
            <p>Піца Мисливська 30 см - найкраща «здобич» для любителів італійської кухні. Також ця випічка припаде до смаку кожному, хто любить їжу погостріше. А де найсмачніша, пікантна їжа? Звичайно ж в IQ Pizza! Ми приготуємо вам смачний гострий обід з нашого меню і доставимо його гарячим на зручний час і адресу!</p>
            <p>Що входить до складу:</p>
            <ul>
                <li>соус томатний зі спеціями;</li>
                <li>сир "Моцарелла";</li>
                <li>цибуля: маринований вид;</li>
                <li>ковбаски;</li>
                <li>печериці;</li>
                <li>халапеньйо;</li>
                <li>часникова олія.</li>
            </ul>
            <p>Піца стане ще кращою, якщо додасте на тісто додаткові інгредієнти. До такої страви відмінно підійдуть мариновані огірки, маслини і начинка з грибами. Ви можете доповнити її так, як вашій душі завгодно! Також не забудьте про наповнювачі для бортиків: сирний або сосисковий бортик.</p>
            <p>Бажаєте замовити обід? Це просто! Ми приймаємо замовлення на доставку за номером телефону 068 115 1010, через сайт, додаток для смартфонів. Зробити заявку можна щодня, з 10:00 до 22:00. Ваша мисливська піца з апетитною начинкою вже чекає на зустріч!</p>
        </div>
    );
}

export default ArticleUa;