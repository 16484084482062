import React from 'react';  
import { getRoutes } from './ohter';

import MainPage from '../Main/mainContainer';
import Menu from '../Menu/Menu';
import Delivery from '../Main/delivery/delivery';
import AboutUs from '../Main/aboutUs/aboutUs';
import Basket from '../Basket/basketContainer';
import Blog from '../Main/blog/blog';
import Vacancies from '../Main/vacancies';
import PublicOffer from '../Main/public-offer';
import Product from '../Product/productContainer';
import Success from '../Success/Success';
import PrivacyPolicy from '../Main/privacy-policy';
import Checkout from '../Checkout/checkoutContainer';

export const routes = (lng) => [
    {
        component: <MainPage />,
        path: getRoutes('main', lng),
        exact: true,
    },
    {
        component: <Product />,
        path: getRoutes('productCombo', lng),
        exact: true,
    },
    {
        component: <Product />,
        path: getRoutes('productNapitki', lng),
        exact: true,
    },
    {
        component: <Product />,
        path: getRoutes('productHophey', lng),
        exact: true,
    },
    {
        component: <Product />,
        path: getRoutes('productHelloween', lng),
        exact: true,
    },
    {
        component: <Product />,
        path: getRoutes('productBoxMenu', lng),
        exact: true,
    },
    {
        component: <Product />,
        path: getRoutes('productSezonnemenu', lng),
        exact: true,
    },
    {
        component: <Product />,
        path: getRoutes('product30sm', lng),
        exact: true,
    },
    {
        component: <Product />,
        path: getRoutes('product45sm', lng),
        exact: true,
    },
    {
        component: <Product />,
        path: getRoutes('productPizza30', lng),
        exact: true,
    },
    {
        component: <Product />,
        path: getRoutes('productPizza45', lng),
        exact: true,
    },
    {
        component: <Product />,
        path: getRoutes('productLove', lng),
        exact: true,
    },
    {
        component: <Menu />,
        path: getRoutes('menuCategory', lng),
        exact: true,
    },
    {
        component: <Delivery />,
        path: getRoutes('dostavka', lng),
        exact: true,
    },
    {
        component: <AboutUs />,
        path: getRoutes('aboutUs', lng),
        exact: true,
    },
    {
        component: <Menu />,
        path: getRoutes('menuCombo', lng),
        exact: false,
    },
    {
        component: <Menu />,
        path: getRoutes('menuNapitki', lng),
        exact: false,
    },
    {
        component: <Menu />,
        path: getRoutes('menuHophey', lng),
        exact: false,
    },
    {
        component: <Menu />,
        path: getRoutes('menuHelloween', lng),
        exact: true,
    },
    {
        component: <Menu />,
        path: getRoutes('menuBoxMenu', lng),
        exact: true,
    },
    {
        component: <Menu />,
        path: getRoutes('menuSezonnemenu', lng),
        exact: true,
    },
    {
        component: <Menu />,
        path: getRoutes('menu30sm', lng),
        exact: true,
    },
    {
        component: <Menu />,
        path: getRoutes('menu45sm', lng),
        exact: true,
    },
    {
        component: <Menu />,
        path: getRoutes('menuPizza30', lng),
        exact: false,
    },
    {
        component: <Menu />,
        path: getRoutes('menuPizza45', lng),
        exact: false,
    },
    {
        component: <Menu />,
        path: getRoutes('menuLove', lng),
        exact: false,
    },
    {
        component: <Basket />,
        path: getRoutes('basket', lng),
        exact: false,
    },
    {
        component: <Blog />,
        path: getRoutes('blog', lng),
        exact: true,
    },
    {
        component: <Success />,
        path: getRoutes('success', lng),
        exact: false,
    },
    {
        component: <PublicOffer />,
        path: getRoutes('offer', lng),
        exact: false,
    },
    {
        component: <PrivacyPolicy />,
        path: getRoutes('privacy', lng),
        exact: false,
    },
    {
        component: <Vacancies />,
        path: getRoutes('vacancies', lng),
        exact: false,
    },
    {
        component: <Checkout />,
        path: getRoutes('checkout', lng),
        exact: true,
    }
];