import React from 'react';

const ArticleRu = ({ cityName }) => {
    return (
        <div style={{
            height: 400,
            overflowY: 'scroll',
        }}>
            <h1>Пицца меню {cityName}</h1>
            <p>Ищете свою идеальную и самую аппетитную еду? В Пицца меню от IQ Pizza точно найдете свое любимое вкусное угощение. Не верите нам? Убедитесь сами! </p>
            <h2>Виды пиццы</h2>
            <p>Меню от IQ Pizza предлагает вам огромный выбор самых вкусных и разнообразных блюд. У вас есть любима выпечка? Вы точно найдете ее у нас. </p>
            Наши хиты продаж:
            <ul>
                <li>«Гавайская» – пикантная свежая выпечка, в состав которой входит ананас, курица, сыр «Моцарелла», легкий сливочный соус, маринованный лук и зелень.</li>
                <li>«Карбонара» – сытный обед, в состав которого входит сыр «Моцарелла», бекон, пармезан, и душистый перец. «Карбонару» мы также дополняем яйцом. Чтобы еда была еще вкуснее и сочнее, наши повара добавляют сливочный соус.  </li>
                <li>«Маргарита» – самая аппетитная выпечка для тех, кто не ест мясо. В состав входят: томатный соус, сыр «Моцарелла», помидоры, зелень. Совет от повара: приправьте «Маргариту» шампиньонами, чтобы она стала еще вкуснее! </li>
                <li>Барбекю – любите, чтобы в блюде было побольше мяса? Тогда такое угощение вам точно понравится! Состав «Барбекю»: соус bbq, сыр «Моцарелла», курица, кукуруза и маринованный лук.</li>
                <li>«Четыре сыра» – самая вкусная сырная выпечка! Состав: соус сырный, сыр моцарелла, сыр "Гауда", крем-сыр "Дор Блю". </li>
                <li>«Чизбургер» - не можете решить: бургер или пицца? Закажите наш «Чизбургер» с солеными огурцами! Состав: соус болоньезе BBQ, сыр «Моцарелла«, курица, сыр «Чеддер», лук маринованный.</li>
            </ul>
            <p>Хотите, чтобы свежую и ароматную еду вам привезли по адресу на удобное время? Оформите курьерскую доставку от IQ Pizza!  Мы доставляем заказы по многим крупным городам Украины. Со списком городов, которые входят в зону доставки, и с адресами пунктов самовывоза вы можете ознакомиться в пункте меню «Доставка», зайдя на сайт IQ Pizza. </p>
            <h2>Размеры пиццы</h2>
            <p>В пицца меню от IQ Pizza вы найдете свой идеальный размер блюда. В нашем ассортименте представлена выпечка с таким диаметром:</p>
            <ul>
                <li>30 см – от 350 грамм (мини) до 530 грамм;</li>
                <li>45 см – большая выпечка от 640 грамм до 1020 грамм.</li>
            </ul>
            <p>Вес заказа зависит от начинки блюда и дополнительных ингредиентов из меню. </p>
            <p>Хотите сытно перекусить? Закажите в пицца меню себе маленькую пиццу 30 см. Или может вы планируете веселый вечер с друзьями? Чтобы кусков вкусного угощения хватило каждому, закажите большую пиццу! С таким ужином никто из вашей компании не останется голодным! </p>
            <h2>Основа для пиццы</h2>
            <p>Каждая выпечка из пицца меню от IQ Pizza изготавливается на вкуснейшем тонком тесте. Мы готовим корж для блюда максимально легким. Так наши повара могут поместить в блюдо больше соуса, начинки и дополнительных ингредиентов, чтобы вы чувствовали вкус пиццы в каждом кусочке, а не ели просто мягкое тонкое тесто. Наши повара очень ответственно подходят к выпеканию коржей. В изготовлении наших основ используется самая лучшая мука высшего качества.</p>
            <h2>Пицца с бортиками</h2>
            <p>Устали от стандартной подачи пиццы? Добавьте в любимое угощение интересную и вкусную деталь – бортик с наполнением.</p>
            <p>Пицца меню нашего заведения предлагает такие ингредиенты, которые можно добавить в бортик:</p>
            <ul>
                <li>Сырный бортик – нежный крем-сыр, добавляющий пикантности. Любителям пряного сыра, в бортик можно добавить сулугуни или пикантный «Дор Блю»;</li>
                <li>Бортик с сосисками – вкусные колбаски, которые делают любимую выпечку еще вкуснее и сытнее.</li>
            </ul>
            <p>Чтобы добавить любимый ингредиент к бортику, зайдя в меню, выберите любимую выпечку, кликните на ссылку «В корзину» и укажите любимую начинку. Стоимость бортика с наполнителями зависит от ингредиентов и размера блюда. Удобный калькулятор пицца меню поможет просчитать стоимость любимой еды легко и быстро!</p>
            <h2>Ингредиенты и соус для пиццы</h2>
            <p>Без чего еда не будет такой сочной и пряной? Конечно же без соуса! Пицца меню от IQ Pizza предлагает вам добавить к любимому блюду такие соусы: </p>
            <ul>
                <li>Нежный белый сливочный;</li>
                <li>Пряный сырный;</li>
                <li>Пикантный болоньезе;</li>
                <li>Оригинальный BBQ, который сделает мясные блюда из пицца меню еще вкуснее;</li>
                <li>Любимый многими томатный. Для любителей ярких впечатлений, рекомендуем добавить острый соус томатный. </li>
                <li>Оригинальный и нежный сырно–шпинатный, который сделает любую куриную выпечку еще вкуснее.</li>
            </ul>
            <p>Каждый соус готовится нашими поварами из лучших ингредиентов. Цель IQ Pizza – максимальное удовольствие от каждого кусочка! </p>
            <p>Скорее выбирайте свой любимый соус и заказывайте вкусный, горячий обед для себя и своих близких из нашего пицца меню!</p>
            <p>Но не только при помощи соуса можно сделать любимое блюдо еще аппетитнее! Добавьте к своему заказу дополнительные ингредиенты! </p>  
            <p>По вашей просьбе наши повара могут добавить к начинке больше свежих продуктов из нашего пицца меню:</p>
            <ul>
                <li>ананас</li>
                <li>бекон;</li>
                <li>грибы;</li>
                <li>желток;</li>
                <li>кукурузу;</li>
                <li>копченую колбасу;</li>
                <li>курицу;</li>
                <li>маслины;</li>
                <li>маринованный огурец;</li>
                <li>пепперони;</li>
                <li>петрушку;</li>
                <li>помидор;</li>
                <li>рукколу;</li>
                <li>салями;</li>
                <li>сливочный сыр «Дор Блю»;</li>
                <li>дополнительную начинку с сыром «Чеддер»;</li>
                <li>дополнительную начинку с ветчиной;</li>
                <li>маринованный лук;</li>
                <li>охотничьи сосиски;</li>
                <li>сосиску «баварская»;</li>
                <li>двойной сыр;</li>
                <li>курицу;</li>
                <li>болгарский перец;</li>
                <li>дополнительную начинку с грибами.</li>
            </ul>
            <p>Для того, чтобы добавить дополнительные ингредиенты в классическое блюдо, кликните в пицца меню на понравившееся блюдо. Дальше через удобный конструктор добавьте любимые продукты к начинке. Стоимость дополнительных ингредиентов автоматически добавится к общей стоимости заказа.</p>
            <h2>Что заказать кроме пиццы</h2>
            <p>Не пиццой единой! В пицца меню от IQ Pizza вы также можете найти и заказать освежающие напитки: </p>
            <ul>
                <li>минеральную воду;</li>
                <li>сладкие содовые напитки;</li>
                <li>холодное пиво. </li>
            </ul>
            <p>Теперь не придётся бежать в магазин в перерыве, пока вы ждете доставку.  Наши курьеры привезут вам полноценный обед, с едой и напитками. Все, что нужно от вас, – выбрать желаемый товар из нашего пицца меню!</p>
        </div>
    );
}

export default ArticleRu;