import React from 'react';
import { withRouter } from 'react-router-dom';
import Scroll from 'react-scroll';
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";

import Product from './productCard';
import Preloader from '../../components/preloader';
import Categories from './categories/categories';

import MenuArticleUk from './article/uk';
import MenuArticleRu from './article/ru';
import CategoryArticlePica30smUk from './categories/articles/pica-30-sm/uk';
import CategoryArticlePica30smRu from './categories/articles/pica-30-sm/ru';
import CategoryArticlePica45smUk from './categories/articles/pica-45-sm/uk';
import CategoryArticlePica45smRu from './categories/articles/pica-45-sm/ru';
import CategoryArticleNapitkiUk from './categories/articles/napitki/uk';
import CategoryArticleNapitkiRu from './categories/articles/napitki/ru';
// import CategoryArticleComboUk from './categories/articles/combo/uk';
// import CategoryArticleComboRu from './categories/articles/combo/ru';

import s from './product.module.css';

var Element = Scroll.Element;

const Products = (props) => {
    const lng = useSelector(state => state.menu.languageMenu);
    const pathMatch = props.match.path.split('/').filter(t => !!t);
    const isMenuUrl = pathMatch.length === 1 && pathMatch[0] === 'menu'; 
    const isPicca30sm = pathMatch.includes('pica-30-sm') || pathMatch.includes('picca-30-sm'); 
    const isPicca45sm = pathMatch.includes('pica-45-sm') || pathMatch.includes('picca-45-sm'); 
    const isNapitki = pathMatch.includes('napitki') || pathMatch.includes('napoji'); 
    const isCombo = pathMatch.includes('combo') || pathMatch.includes('combo'); 

    let products = [...props.products];
    let preloader = true;
    let city = props.cities.find(it => it.id === props.activeCity.id);
    const cityName = city.name[lng];
    let cityProducts = [];

    if(products.length > 0){
        preloader = false;
            let items = city.terminals;
            products = products.filter(item => {
            let hide = false;
            let prohibitedToSaleOn = item.prohibitedToSaleOn;

            for(let key in items){
                let search = prohibitedToSaleOn.find(it => it.terminalId === items[key].id);
                if(search){
                    hide = true;
                } else {
                    cityProducts.push(item);
                }
            };
            
            if(item.parentGroup === props.activeCategory && !hide){
                return true
            } else {
                return false
            }
        });
    } 

    const { t, i18n } = useTranslation();
    // console.log('isPicca30sm --->>>', isPicca30sm);
    // console.log('isPicca45sm --->>>', isPicca45sm);
    return(
        <Element name='menuMain'>
        <div className='container'>
            <div className='row' style={{position: 'relative'}}>
                {/* <h1>{t('menuHeader')}</h1> */}
                {preloader && <Preloader/>}
                <Categories products={cityProducts} languageMenu={props.languageMenu} categories={props.categories} activeCategory={props.activeCategory} updateCategory={props.updateCategory}/>
                <div className={s.products_container}>
                    {products
                    .sort((a, b) => a.order-b.order)
                    .map((item, index) => {
                        return(
                                <Product languageMenu={props.languageMenu} item={item} updateProduct={props.updateProduct} updateModifire={props.updateModifire} key={index}/>
                        )  
                    })}
                </div>
            </div>
            <div className="row">
                {isMenuUrl && (lng === 'uk' 
                    ? <MenuArticleUk cityName={cityName} /> 
                    : <MenuArticleRu cityName={cityName} />)}

                {isPicca30sm && (lng === 'uk'
                    ? <CategoryArticlePica30smUk cityName={cityName} />
                    : <CategoryArticlePica30smRu cityName={cityName} />
                )}

                {isPicca45sm && (lng === 'uk'
                    ? <CategoryArticlePica45smUk cityName={cityName} />
                    : <CategoryArticlePica45smRu cityName={cityName} />
                )}

                {isNapitki && (lng === 'uk'
                    ? <CategoryArticleNapitkiUk cityName={cityName} />
                    : <CategoryArticleNapitkiRu cityName={cityName} />
                )}
                {/* {isCombo && (lng === 'uk'
                    ? <CategoryArticleComboUk cityName={cityName} />
                    : <CategoryArticleComboRu cityName={cityName} />
                )} */}
            </div>
        </div>
        </Element>
    )
}

export default withRouter(Products);