import React from 'react';
import './article.style.css';

const ArticleRu = () => {
    return (
        <div className="4siri">
            <p>Пицца 4 Сыра 30 см – выпечка для тех, кто любит очень много сыра в еде. Только представьте эту вкуснятину: четыре вида аппетитного продукта на тонком тесте. Невозможно устоять перед таким обедом.</p>
            <p>А где же можно заказать самое вкусное блюдо с золотистой корочкой? Конечно же в IQ Pizza! </p>
            <p>Мы приготовим, доставим вам свежую, горячую и сытную пиццу по любому удобному адресу, к нужному времени. Это блюдо, диаметр которого 30 см, разрезано на восемь кусочков. Вы сможете не только сами наестся вкусной едой, но и поделиться ею с близкими. </p>
            <p>Ингредиенты блюда: </p>
            <ul>
                <li>соус сырный;</li> 
                <li>"моцарелла";</li> 
                <li>"гауда";</li> 
                <li>"дорблю";</li> 
                <li>пармезан.</li> 
            </ul>
            <p>Хотите, чтобы угощение стало еще вкуснее и сытнее? Добавьте на тесто дополнительную начинку из нашего меню. Включите в состав сливочный соус, кукурузу, зелень или что вашей душе угодно! Мы учтем и выполним все ваши пожелания, чтобы сделать вкус любимой пиццы еще насыщеннее. Также не забудьте про наполнители для бортиков: сыр или сосиски.</p>
            <p>Хотите полакомиться аппетитной выпечкой «Четыре сыра», но нет времени или лень идти за ней в пиццерию? Вас выручит доставка. Все, что вам нужно – добавить любимую еду в корзину, провести оплату. Об остальном позаботится IQ Pizza! Пицца 4 Сыра 30 см приедет к вам свежей и горячей! </p>
        </div>
    );
}

export default ArticleRu;