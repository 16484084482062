import React from 'react';
import { useTranslation } from "react-i18next";
import s from './modalcheckinterval.module.css';
import Button from '../../components/button';

const ModalCheckInterval = (props) => {
    const { t, i18n } = useTranslation();
    return (
        <div className={s.modalCities}>
            <div className={s.screen}></div>
            <div className={s.citiesRow}>
                <div className={s.modal}>
                    {/* <h6 className={s.title}>{t('modalCheckIntervalHeader')}</h6> */}
                    <div className={s.message}>
                        {t('modalCheckIntervalHeader')} {props.time}
                    </div>
                    <div className={s.buttons}>
                        <Button title={t('cancel')} handleClick={props.modalCancel} className={s.button_cancel} />
                        <Button title={t('ok')} handleClick={props.modalSubmit} className={s.button_submit} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalCheckInterval;
