import React from 'react';
import './article.style.css';

const ArticleUa = () => {
    return (
        <div className="4siri">
            <p>Піца Гавайська 30 см - пікантна випічка, що має насичений смак. Ця страва з куркою та апетитними додатковими інгредієнтами нікого не залишить байдужим! Особливо влітку, коли так хочеться в теплі краї. Подаруйте собі шматочок чарівного курорту в океані разом з IQ Pizza!</p>
            <p>Інгредієнти начинки, які викладаються на тісто:</p>
            <ul>
                <li>вершковий соус;</li>
                <li>"Моцарелла";</li>
                <li>курка;</li>
                <li>наповнення з ананасами;</li>
                <li>цибуля маринована;</li>
                <li>зелень.</li>
            </ul>
            <p>Хочете, щоб піца Гавайська 30 см стала ще ситнішою і смачнішою? Легко! Додайте до випічки додаткові інгредієнти  з нашого меню. Страву відмінно доповнять маслини, кукурудза і подвійний сир. Також не забудьте додати наповнення бортика: крем чи сулугуні. З додатковою начинкою ваша піца стане ще смачнішою і пікантнішою!</p>
            <p>Ви можете замовити обід за номером телефону 068 115 1010, на сайті або в нашому мобільному додатку. Замовлення приймаються щодня, з 10:00 до 22:00. Швидше оформляйте доставку на свій гавайський обід. Насичений смак і задоволення в кожному шматочку гарантовано!</p>
        </div>
    );
}

export default ArticleUa;