import React from 'react'
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom'
import { useTranslation } from "react-i18next";
import s from './menu.module.css'

const MobileMenu = (props) => {
    const { t, i18n } = useTranslation();
    const language = useSelector(state => state.menu.languageMenu);
    const activeCity = useSelector(state => state.menu.activeCity);
    return (
        <div className={s.menuWrapper}>
            <div className={s.navMenu}>
                <NavLink onClick={props.closeMobileMenu} to={`/${language === 'ru' ? 'ru/menu/' : 'menu/'}`}>{t('menu')}</NavLink>
                <NavLink onClick={props.closeMobileMenu} to={`/${language === 'ru' ? 'ru/dostavka/' : 'dostavka/'}`}>{t('delivery')}</NavLink>
                <NavLink onClick={props.closeMobileMenu} to={`/${language === 'ru' ? 'ru/o-nas/':'pro-nas/'}`}>{t('aboutUs')}</NavLink>
                <NavLink onClick={props.closeMobileMenu} to={`/${language === 'ru' ? 'ru/blog/':'blog/'}`}>{t('blog')}</NavLink>
                <a target="_blank" href="/loyalty.pdf">{t('loyalty')}</a>
                <div className={`${s.language} ${s.cities}`} onClick={() => props.openCities()}>
                    <button>{activeCity.name[language]}</button>
                </div>
                <div className={s.language} onClick={() => props.openLanguage()}>
                    <button>{language}</button>
                </div>
            </div>
        </div>
    )
}

export default MobileMenu
