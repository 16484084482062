import * as axios from "axios";

let testURL = 'http://localhost:8888/mono/';
let testUrl = 'https://api.e-admin.com.ua/iqpizza/';
let demoURL = 'http://pay.ithinkers.ru/api/';

const instance = axios.create({
    baseURL: testUrl,
    crossDomain: true
});

export const actions = {
    getMenu(){
        return instance.post('getMenuFast.php')
        .then (response => {
            if (response) {
                return response.data;
            } else {
                alert('Ошибка загрузки данных!');
            }
        })
    },
    addOrder(data){
        return instance.post(`add_order.php`, {...data})
        .then (response => {
            return response.data;
        })
    },
    getClient(data){
        return instance.post(`getClient.php`, {...data})
        .then (response => {
            return response.data;
        })
    },
    getCity(){
        return instance.get('getCityFast.php')
        .then (response => {
            if (response) {
                return response.data;
            } else {
                alert('Ошибка загрузки данных!');
            }
        })
    },
    sendOrder(data){
        return instance.post(`sendOrder.php`, {order: data})
        .then (response => {
            return response.data;
        })
    },
    saveOrder(data){
        return instance.post(`saveOrder.php`, {data: data})
        .then (response => {
            return response.data;
        })
    },
    getHash(data){
        return instance.post(`hash.php`, {data: data})
        .then (response => {
            return response.data;
        })
    },
    checkCreate(data) {
        return instance.post('checkCreate.php', {order: data})
            .then(response => {
                return response.data
            })
    },
    getDeliveryRestrictions() {
        return instance.post('getDeliveryRestrictions.php')
            .then(response => {
                return response.data
            })
    },
    getRedirects(data) {
        return instance.post('getRedirects.php', data)
            .then(response => {
                return response.data
            })
    }
};