import React from 'react';
import { useTranslation } from "react-i18next";
import { withRouter, Redirect } from "react-router";
import { useSelector } from 'react-redux';

import s from './footer.module.css';

import Logo from '../../../assets/logo_footer.png';
import IOS from '../../../assets/ios.png';
import Android from '../../../assets/android.png';
import instagram from '../../../assets/instagram.svg';
import facebook from '../../../assets/facebook.svg';
import tiktok from '../../../assets/tik-tok.svg';
import pay from '../../../assets/pay.png';



const Footer = (props) => {
    const { t, i18n } = useTranslation();
    const lng = useSelector(state => state.menu.languageMenu);
    return(
        <div className={s.footer}>
        <div className="row">
            <div className={s.footer_container}>
            <div className={s.footer_logo}>
                <img src={Logo} alt=""/>
            </div>
            <div className={s.fotter_info}>
                    <div className={`${s.footer_item} ${s.footer_item_first}`}>
                            <div className={s.title}>{t('company')}:</div>
                            <div onClick={() => props.history.push(lng === 'uk' ? '/vakansji/' : '/ru/vakansii/')} className={s.item_vacancies} target='_blank'>{t('vacancies')}</div>
                            <div onClick={() => props.history.push(lng === 'uk' ? '/polityka-konfidencijnosti/' : '/ru/politika-konfidencialnosti/')} className={s.item_vacancies} target='_blank'>{t('privacyPolicy')}</div>
                <div onClick={() => props.history.push(lng === 'uk' ? '/publichna-oferta/' : '/ru/publichnaja-oferta/')} className={s.item_vacancies} target='_blank'>{t('publicOffer')}</div>
                    </div>
                    <div className={s.footer_item}>
                            <div className={s.title}>{t('toOrder')}:</div>
                            <a href="tel:0681151010" className="binct-phone-number-1">068 115 10 10</a>
                            <a href="tel:0991151010" className="binct-phone-number-1">099 115 10 10</a>
                            {/* <a href="mailto:monopizzaukraine@gmail.com" className="mail">monopizzaukraine@gmail.com</a> */}
                    </div>
                    <div className={s.footer_item}>
                            <div className={s.title}>{t('soc')}:</div>
                            <div className={s.social}>
                                <a target="_blank" href="https://www.instagram.com/iq_pizza_ukraine/"><img src={instagram} alt="instagram" /></a>
                                <a target="_blank" href="https://www.facebook.com/iqpizza/"><img src={facebook} alt="facebook" /></a>
                                {/* <a target="_blank" href="https://vm.tiktok.com/ZMeYVnpqn/"><img src={tiktok}/></a> */}
                            </div>
                    </div>
                    <div className={s.footer_item}>
                            <div className={s.title}>{t('app')}:</div>
                            <a target="_blank" href="https://apps.apple.com/us/app/id1524484699">
                                <img src={IOS} alt="IOS" />
                            </a>
                            <a target="_blank" href="https://play.google.com/store/apps/details?id=com.ithinkers.iqpizza">
                                <img src={Android}  alt="Android" />
                            </a>
                    </div>
                    <div className={s.footer_item}>
                                <img className={s.payImg} src={pay} alt="payment methods" />
                                <div className={s.copyright}>Юр. Адреса: 69059, Запорізька обл., м. Запоріжжя, вул. Парамонова, буд. 4А, кв 120.
Поштова адреса: м. Дніпро, інд. 49051, а/с 3031 ФОП Рянічева Н.М.
Витяг з єдиного державного реєстру юридичних осіб та фізичних осіб підприємців від 15.02.2021 р.
ФОП Рянічева Н.М.</div>
                    </div>
            </div>
            </div>
            {/* <div className={s.docContainer}>
                <div onClick={() => props.history.push(lng === 'uk' ? '/politika-konfidencialnosti/' : '/ru/politika-konfidencialnosti/')} className={s.doc} target='_blank'>{t('privacyPolicy')}</div>
                <div onClick={() => props.history.push(lng === 'uk' ? '/publichna-oferta/' : '/ru/publichnaja-oferta/')} className={s.doc} target='_blank'>{t('publicOffer')}</div>
            </div> */}
            
        </div>
    </div>
    )
}

export default withRouter(Footer);