import React, { useState, useEffect } from 'react';
import { Helmet } from "react-helmet";
import {connect} from 'react-redux';
import { Field, reduxForm, formValueSelector, reset, arrayPush} from 'redux-form';
import {required, date, validateForm} from 'redux-form-validators';
import {Input, File, Textarea, Select, Time, Radio} from '../../components/ui/Input';
import s from './checkout.module.css';
import Button from '../../components/button/'
import { createNumberMask, createTextMask } from 'redux-form-input-masks';
import Autocomplete from '../../components/ui/Input/Autocomplete';
import AutocompleteCustom from '../../components/ui/Input/AutocompleteCustom';
import { useTranslation, withTranslation } from "react-i18next";
import moment from 'moment';

const phoneMask = createTextMask({
   pattern: '+38(999) 999-9999',
 });

 const timeMask = createTextMask({
   pattern: '99:99',
   stripMask: false,
 })

 const requiredField = (value, _, props) => value ? undefined : props.language === 'uk' ? 'Обов\'язкове поле' : 'Обязательное поле'

 const validPhone = value => {
    if(value.length < 10){
      return 'Введіть правильний номер телефону'
    }
 };

//  const sumOrder = (value, sum, pushType) => {
//    if(value === '1' && sum < 0){
//       return 'Мінімальна сума замовлення на доставку - 150₴';
//    }
//  };


//  const  getTimeFromMins = (mins) => {
//     var h = mins / 60 | 0,
//     m = mins % 60 | 0;
//    return moment.utc().hours(h).minutes(m).format("HH:mm");
// };

const validSelectTime = (value, _, props) => {
   // console.log('validSelectTime', props);
   
   if (!value || value == 1) {
      const city = props.cities.find(t => t.spot_id === props.city);

      if (city) {
         const { begin, end, orderTimeIntervalDelivery, orderTimeIntervalPickup } = city.workingHours[0];

         let time = moment(new Date());
         const interval = props.delivery == 2 ? orderTimeIntervalPickup : orderTimeIntervalDelivery;
         let minTime = moment(new Date(moment().format('YYYY/MM/DD')+` ${begin}:00`));
         let maxTime = moment(new Date(moment().format('YYYY/MM/DD')+` ${end}:00`));
         let minTimeOrder = moment(new Date(moment().format('YYYY/MM/DD')+` ${begin}:00`));
         minTimeOrder = minTimeOrder.add(interval, 'minutes');
         let timeInterval = moment().add(interval, 'minutes');

         if (time < minTime) {
            return `${'timeStartError'} ${moment(minTimeOrder).format('HH:mm')}`;
         } else if (time > maxTime) {
            return `${'timeFinishError'} ${moment(maxTime).format('HH:mm')}`;
         } else if (time < minTimeOrder && time < minTime) {
            return `${'minimalTime'} ${moment(minTimeOrder).format('HH:mm')}`;
         }
      }
   } 

   return null;
}

const validTime = (value, _,  props) => {
   let t = props.t;
   // console.log('--- validTime доставка ---', value);
   const city = props.cities.find(t => t.spot_id === props.city);
   
   if (city) {
      const { begin, end, orderTimeIntervalDelivery } = city.workingHours[0];

      let time = moment(new Date(moment().format('YYYY/MM/DD')+' ' + value + ':00'));
      let minTime = moment(new Date(moment().format('YYYY/MM/DD')+` ${begin}:00`));
      let maxTime = moment(new Date(moment().format('YYYY/MM/DD')+` ${end}:00`));
      let minTimeOrder = moment(new Date(moment().format('YYYY/MM/DD')+` ${begin}:00`));
      minTimeOrder = minTimeOrder.add(orderTimeIntervalDelivery, 'minutes');
      let timeInterval = moment().add(orderTimeIntervalDelivery, 'minutes');

      if (time < minTime) {
         return `${'timeStartError'} ${moment(minTime).format('HH:mm')}`;
      } else if (time > maxTime) {
         return `${'timeFinishError'} ${moment(maxTime).format('HH:mm')}`;
      } else if (time < minTimeOrder) {
         return `${'minimalTime'} ${moment(minTimeOrder).format('HH:mm')}`;
      } else if (timeInterval < maxTime && time < timeInterval) {
         return `${'timeStartError'} ${moment(timeInterval).format('HH:mm')}`;
      }
   }
}

const validTimePi = (value, _, props) => {
   // console.log('--- validTimePi самовывоз ---');

   const city = props.cities.find(t => t.spot_id === props.city);
   
   if (city) {
      const { begin, end, orderTimeIntervalPickup } = city.workingHours[0];

      let time = moment(new Date(moment().format('YYYY/MM/DD')+' ' + value + ':00'));
      let minTime = moment(new Date(moment().format('YYYY/MM/DD')+ ' ' + begin + ':00'));
      let maxTime = moment(new Date(moment().format('YYYY/MM/DD')+ ' ' + end + ':00'));
      let minTimeOrder = moment(new Date(moment().format('YYYY/MM/DD')+ ' ' + begin + ':00'));
      minTimeOrder = minTimeOrder.add(orderTimeIntervalPickup, 'minutes');
      let timeInterval = moment().add(orderTimeIntervalPickup, 'minutes');

      if (time < minTime) {
         return `${'timeStartError'} ${moment(minTimeOrder).format('HH:mm')}`;
      } else if (time > maxTime) {
         return `${'timeFinishError'} ${moment(maxTime).format('HH:mm')}`;
      } else if (time < minTimeOrder) {
         return `${'minimalTime'} ${moment(minTimeOrder).format('HH:mm')}`;
      } else if (timeInterval < maxTime && time < timeInterval) {
         return `${'timeStartError'} ${moment(timeInterval).format('HH:mm')}`;
      }
   }
}

let CheckoutForm = (props) => {
    let {handleSubmit, delivery, typePay} = props;
    const { t, i18n } = useTranslation();
    const [isDeliveryField, setIsDeliveryField] = useState(false);
    const [isValidDeliveryField, setIsValidDeliveryField] = useState(true);
    const [timeId, setTimeId] = useState(1);

    useEffect(() => {
      if (props.amount < 150 && props.delivery != 2) {
         setIsDeliveryField(true);
         setIsValidDeliveryField(false);
      } else {
         setIsValidDeliveryField(true);
      }
   }, [props.amount, props.delivery]);

   const selectDeliveryChange = (event) => {
      const value = event.target.value;
      const validSelector = value != '2' && props.amount < 150;
      // const validSelector = value != '2';

      if (value != '2') setIsDeliveryField(true);

      setIsValidDeliveryField(!validSelector);
   }

   const handleChange = (e) => {
      setTimeId(e.target.value);
   }

   const handleValidate = isDeliveryField ? isValidDeliveryField && handleSubmit : handleSubmit;
   // const handleValidate = isDeliveryField ? handleSubmit : handleSubmit;

   const selectTime = [
      {id: 1, name: t('optionFastSpeed')},
      {id: 2, name: t('forAWhile')},
   ];

   // let options = delivery == 2 ? props.typesPay.filter(t => t.name !== 'online'): props.typesPay.filter(t => t.name == 'online');
   let typePayDelivery = props.typePayDelivery.filter(t => t.name !== 'online');
   let typesPay = props.typesPay.filter(t => t.name !== 'online');

   // console.log('props', props);
   // console.log('typesPay', typesPay);
   // console.log('typesPay', typesPay);

   const formatTerminals = [];

   props.terminals.map(({ id, name, time }) => {
      formatTerminals.push({
         id,
         name: name[props.language],
         time
      });
   });

   const canonicalUrl = props.language === 'uk' ? 'https://iqpizza.com.ua/checkout/' : 'https://iqpizza.com.ua/ru/checkout/';
   
    return(
        <div className={'container'}>
           <Helmet>
                <meta charSet="utf-8" />
                <meta name="robots" content="noindex, nofollow"/>
                <title>{t('checkoutTitle')}</title>
                <meta name="description" content={''} />
                <link rel="canonical" href={canonicalUrl} />
            </Helmet>
            <div className={'row'}>
                <div className={s.formContainer}>
                <h3>{t('toOrder')}</h3>
                <form onSubmit={handleSubmit} action="https://secure.wayforpay.com/pay" autoComplete='off'>
                    <Field name={`name`}
                       component={Input} label={t('name')} 
                       validate={requiredField}
                       />
                     <Field name={`phone`}
                       component={Input} label={t('phone')} 
                       validate={[requiredField, validPhone]}
                       {...phoneMask}/>

                     {/* <Field name={`delivery`} option={props.typeDelivery}
                            component={Select} label={t('typeDelivery')} 
                           //  validate={(val) => sumOrder(val, props.amount, props.dispatch)}
                            validate={(val) => checkOrder(val, props.amount, 'delivery')}
                       /> */}

                     <div className={s.deliveryFieldWrapper}>
                        <Field
                           name={`delivery`}
                           className={s.deliveryField}
                           component="select"
                           onChange={selectDeliveryChange}
                           label={t('typeDelivery')}
                        >
                           {props.typeDelivery.map((item, index) => 
                              <option 
                                 key={index} 
                                 value={item.id}
                              >{t(item.name)}
                           </option>
                           )}
                        </Field>
                        {!isValidDeliveryField && <span className={s.messageError}>{t('minimalSum')}</span>}
                     </div>

                    {delivery == 1 ?
                        <div>
                            <Field name='streetname'
                                  component={AutocompleteCustom} 
                                  dispatch={props.dispatch} label={t('street')} 
                                  validate={requiredField}
                                  city={props.city}
                                  streets={props.streets}
                        />
                           <div className={s.deliveryContainer}>
                              <div className={s.item}>
                                 <Field name='house'
                                    component={Input} label={t('building')} 
                                    validate={requiredField}
                                 />
                              </div>
                              <div className={s.item}>
                                 <Field name='porch'
                                    component={Input} label={t('porch')} 
                                 />
                              </div>
                              <div className={s.item}>
                                 <Field name='floor'
                                    component={Input} label={ `${t('floor')}`} 
                                 />
                              </div>
                              <div className={s.item}>
                                 <Field name='housing'
                                    component={Input} label={ `${t('housing')}`} 
                                 />
                              </div>
                              <div className={s.item}>
                                 <Field name='appartment'
                                    component={Input} label={ `${t('appartment')}/${t('office')}`} 
                                 />
                              </div>
                           </div>
                        </div> 
                      :  <Field name={`terminals`} 
                                 option={formatTerminals}
                                 component={Select} 
                                 label={t('terminals')} 
                           />}
                     <Field 
                        name={`selectTime`} 
                        option={selectTime}
                        value={timeId}
                        onChange={handleChange}
                        component={Select}
                        label={t('selectTime')}
                        validate={[validSelectTime]}
                     />

                     {timeId == 2 && (
                        <Field name={`time`}
                           component={Time} 
                           label={t('time')} 
                           validate={[requiredField, delivery == 1 ? validTime : validTimePi]}
                           type={'time'}
                        />
                     )}

                     <Field name={`typePay`} option={ delivery == 1 ? typePayDelivery : typesPay}
                        component={Select} label={t('typePay')} 
                     />
                      <Field name={`comment`}
                       component={Textarea} label={t('comment')} 
                       />
                     
                     <div className={s.buttonContainer}>
                        <Button title={t('confirm')}  handleClick={handleValidate}/>
                     </div>
                </form> 
                
                </div>
            </div>
        </div>
    )
}

CheckoutForm = reduxForm({
   form: 'CheckoutForm',
 })(CheckoutForm);

const selector = formValueSelector('CheckoutForm');

CheckoutForm = connect((state, _) => {
   const {delivery, typePay}  = selector(state, 'delivery', 'typePay');
   return {
      delivery, 
      typePay,
      cities: state.firebase.data.settings && state.firebase.data.settings.City ? state.firebase.data.settings.City : [],
      language: state.menu.languageMenu,
    };
  })(CheckoutForm);


export default (CheckoutForm);