import React from 'react';
import './article.style.css';

const ArticleRu = () => {
    return (
        <div className="4myasa">
            <p>Пицца 4 мяса 30 см – пол килограмма сытного удовольствия для тех, кто жить не может без мяса. Пикантный соус и зелень сделают обед еще вкуснее. А где самая вкусная пицца? Конечно же в IQ Pizza! Мы предлагаем не только горячую и аппетитную выпечку, но и приятные цены, быструю курьерскую доставку по всему городу. Самое лучшее обслуживание только для наших клиентов. </p>
            <p>Состав блюда:</p>
            <ul>
                <li>томатный соус болоньезе;</li>
                <li>сыр "Моцарелла";</li>
                <li>мясо (курица);</li>
                <li>колбаски;</li>
                <li>бекон;</li>
                <li>лук маринованный;</li>
                <li>зелень.</li>
            </ul>
            <p>Хотите, чтобы ваша пицца стала еще более мясной? Не забудьте добавить в нее дополнительные ингредиенты. К такой выпечке отлично подойдет ветчина, салями или говядина. Но, только вам решать, каким будет ваш идеальный обед. Также не забудьте добавить сыр или сосиски в бортик, чтобы сделать вкус блюда еще насыщеннее. </p>
            <p>Хотите сделать себе вкусный итальянский перекус? Или планируете посиделки с близкими людьми? Тогда пицца 4 мяса 30 см – идеальный вариант! Размер и объем выпечки, 510 гр, достаточный для того, чтобы каждый в компании наелся. Количество кусочков – 8 штук.  Этого точно хватит, чтобы каждому досталось аппетитное лакомство. </p>
            <p>Хотите заказать еду? Доставка проводится каждый день, с 10:00 до 22:00. Заказать обед вы можете по номеру телефона нашей курьерской службы, на официальном сайте или в мобильном приложении. Ваша еда уже ждет встречи!</p>
        </div>
    );
}

export default ArticleRu;