import firebase from 'firebase';

var config = {
    apiKey: "AIzaSyDe2wjvIXFmEy-H-9B13ffo9ygr3qxiHoM",
    authDomain: "fasta-3b228.firebaseapp.com",
    databaseURL: "https://fasta-3b228.firebaseio.com",
    projectId: "fasta-3b228",
    storageBucket: "fasta-3b228.appspot.com",
    messagingSenderId: "764919781854",
    appId: "1:764919781854:web:a360806bb5c3e26922654f"
};

firebase.initializeApp(config);

firebase.database();

export default firebase;