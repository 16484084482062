import React from 'react';
import './article.style.css';

const ArticleUa = () => {
    return (
        <div className="about-us">
            <h1>ПІЦА М'ЯСНА BBQ</h1>
            <p>Піца М'ясна BBQ 30 см - обід, який ідеально підійде для фанатів всього м'ясного. Адже м'яса багато не буває, правда? Хочете, щоб смачну випічку з куркою приготували спеціально для вас, привезли за будь-якою зручною адресою і в потрібний час? IQ Pizza до ваших послуг! Ми приготуємо вам смачне, гаряче частування, у якого тонке тісто, і доставимо за адресою на той час, який зручний для вас!</p>
            <p>Інгредієнти, які включає в себе піца М'ясна BBQ 30 см:</p>
            <ul>
                <li>соус BBQ;</li>
                <li>сир "Моцарелла";</li>
                <li>курка;</li>
                <li>ковбаса копчена;</li>
                <li>бекон;</li>
                <li>цибуля маринована;</li>
                <li>кукурудза. </li>
            </ul>
            <p>Щоб Піца М'ясна BBQ 30 см стала ще смачнішою, можна додати до неї ароматну зелень. Також не забудьте про бортик: сирний або сосисковий. З повним списком додаткових начинок ви можете ознайомитися в нашому меню.</p>
            <p>Вага, яку має Піца М'ясна BBQ 30 см - 450 гр. Цього цілком вистачить для того, щоб не тільки добре поїсти самому, але й пригостити своїх близьких. Шматочка апетитної випічки вистачить кожному!</p>
            <p>Доставка їжі здійснюється щодня, з 10:00 до 22:00. Зробіть замовлення на свій ситний обід вже зараз!</p>
        </div>
    );
}

export default ArticleUa;