import React from 'react';
import { useSelector } from 'react-redux';

import s from './product.module.css';
import Categories from './categories/categories';
import Product from './productCard';
import { useTranslation } from "react-i18next";
import Preloader from '../../../components/preloader';

const Products = (props) => {
    let products = [...props.products];
    let preloader = true;
    let city = props.cities.find(it => it.id === props.activeCity.id);
    const lng = useSelector(state => state.menu.languageMenu);
    const isHomePage = useSelector(state => state.menu.isHomePage);
    let cityProducts = [];

    if(products.length > 0){
        preloader = false;
            let items = city.terminals;
            products = products.filter(item => {
            let hide = false;
            let prohibitedToSaleOn = item.prohibitedToSaleOn;

            for(let key in items){
                let search = prohibitedToSaleOn.find(it => it.terminalId === items[key].id);
                if(search){
                    hide = true;
                } else {
                    cityProducts.push(item);
                }
            };
            
            if(item.parentGroup === props.activeCategory && !hide){
                return true
            } else {
                return false
            }
        });
    }
    
    const { t, i18n } = useTranslation();
    return(
        <div className='container'>
            <div className='row' style={{position: 'relative'}}>
                {isHomePage ? <h1 style={{ marginTop: '-10px' }}>{t('menuHeader')}</h1> : <h1 style={{ marginTop: '-10px' }}>{t('menuHeader')} {city.name[lng]}</h1>}
                {preloader && <Preloader/>}
                <Categories languageMenu={props.languageMenu} products={cityProducts}  categories={props.categories} activeCategory={props.activeCategory} updateCategory={props.updateCategory}/>
                <div className={s.products_container}>
                    {products
                    .sort((a, b) => a.order-b.order)
                    .map((item, index) => {
                        return(
                                <Product languageMenu={props.languageMenu} item={item} updateProduct={props.updateProduct} key={index}/>
                        )  
                    })}
                </div>
            </div>
        </div>
    )
}

export default Products;