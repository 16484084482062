import React, {Component} from 'react';
import ReactPixel from 'react-facebook-pixel';
import axios from 'axios';
import moment from 'moment';

import {actions} from '../../API/';
import {connect} from 'react-redux';
import { withRouter } from "react-router";

import Checkout from './checkoutForm';
import Modal from '../../components/modalResult/';
import Preloader from '../../components/preloader';
import {removeProduct, updateStreets} from '../../redux/menu';
import { LiqPayPay, LiqPaySubscribe } from "react-liqpay";
import { setSubmitFailed, reset } from 'redux-form';

import ModalCheckInterval from '../../components/modalCheckInterval';

const payInfo = {
    amount: 999,
    currency: 'UAH',
    title: 'Оплатить'
  }

const ButtonComponent = (props) => (
    <button style={{
      backgroundColor: '#337ab7',
      color: '#fff',
      borderColor: '#2e6da4',
      border: '1px solid transparent',
      borderRadius: '4px',
      padding: '6px 12px',
      cursor: 'pointer',
      display: 'none'
    }}
    ref={props.btRef}
    >
      {`${payInfo.title} ${payInfo.amount} ${payInfo.currency}`}
    </button>
)


class CheckoutContainer extends Component{

    constructor(){
        super();
        this.state = {
            typeDelivery: [
                {
                    name: 'delivery',
                    id: 1
                },
                {
                    name: 'pickup',
                    id: 2
                }
            ],
            typePayDelivery: [
                {
                    name: 'cash',
                    id: '09322f46-578a-d210-add7-eec222a08871'
                },
                {
                    name: 'card',
                    id: '692b19c9-735e-0558-bc8e-7db97f1bed79'
                },
                // {
                //     name: 'online',
                //     id: '32c4fa2f-066d-4fbf-be6a-1ca1273cb631'
                // }
            ],
            typePay: [
                {
                    name: 'cash',
                    id: '09322f46-578a-d210-add7-eec222a08871'
                },
                // {
                //     name: 'online',
                //     id: '32c4fa2f-066d-4fbf-be6a-1ca1273cb631'
                // }
            ],
            status: null,
            showModal: false,
            showModalInterval: false,
            showModalMessage: '',
            preloader: false,
            productsArray: [],
            productsData: [],
            formData: {},
            price: null,
            productsMS: null,
            modalTimeInterval: null,
            isDeliveryDurationInMinutes: false,
            deliveryDurationInMinutes: 0,
        }

        this.btRef = React.createRef();
    }


    componentDidMount(){
        window.scrollTo(0, 0);

        let productsList = '';
        let count = '';
        let priceList = '';
        let amount = 0;

        let productsArray = [];
        
        let newProduct  = this.props.products.filter(item => item.count > 0);
        let productsMS = '';

        newProduct.map( item => {
            let modifiersProduct = [];
            let search = productsArray.find(i => i.id === item.id);

            let sum = 0;
            let price = item.price;
            let count = item.count;
            let modifierSum = 0;

            let modifireList = '';

            const {cities, activeCity} = this.props;
            const city = cities.find(t => t.id === activeCity.id);

            if (city && city.terminals) {
                item.differentPricesOn.map(t => {
                    city.terminals.map(terminal => {
                        if (terminal.id === t.terminalId) {
                            price = t.price;
                        }
                    })
                });
            }

            item.modifiers.map(it => {
                if(it.amount > 0){
                    modifierSum += it.price*it.amount;
                    modifiersProduct.push({
                        amount: it.amount,
                        id: it.modifierId,
                        name: it.name
                    });
                    modifireList += `${it.name} - ${it.amount}; `;
                }
            });

            let groupModifireList = '';

            item.groupModifiers.map(it => {
                let listChild = '';
                it.childModifiers.map(a => {
                    if(a.amount > 0){
                    listChild += `${a.name} - ${a.amount}; `
                    modifierSum += a.price*a.amount;
                    modifiersProduct.push({
                        amount: a.amount,
                        id: a.modifierId,
                        name: a.name,
                        groupId: it.modifierId,
                        groupName: it.name
                    });
                    }
                });

                if(listChild.length > 0){
                    groupModifireList += `${it.name} (${listChild}); `
                }
            })

            console.log('price, modifierSum', price, modifierSum);

            sum += Number(modifierSum) + Number(price); 

            if(!search){
                productsList += `${item['name']} ${groupModifireList.length > 0 ? `- ${groupModifireList}` : ''};`;
                count += `${item['count']};`;
                price += `${sum};`;
                priceList += `${sum};`;
                amount += Number(sum)*Number(item['count']);
                
                productsArray.push({
                    amount: item['count'],
                    id: item['id'],
                    name: `${item['name']} ${groupModifireList.length > 0 ? `- ${groupModifireList}` : ''}`,
                    modifiers: modifiersProduct,
                });
                productsMS += `${item['name']} ${groupModifireList.length > 0 ? `- ${groupModifireList}` : ''} - ${item['count']}; `
            }
        });
        
        if(productsArray.length < 1){
            this.props.history.push('/');
        }
   
        this.setState({
            productsList,
            count,
            price: priceList,
            amount,
            productsArray,
            productsMS
        })

        // const isTargetCity = this.props.activeCity.id === '6bf17b9e-3ecd-0491-0179-0e815f2c9df9'|| this.props.activeCity.id === '6bf17b9e-3ecd-0491-0179-0e815f2c8b26' || this.props.activeCity.id === 'fe74eb1c-055f-4767-b1e6-1a8d7df54070';
        const productsData = [];
        let productsPrice = 0;

        productsArray.map(it => {
            const product = this.props.products.find(t => t.id === it.id);

            productsData.push({
                value: product.price,
                currency: 'UAH'
            });

            productsPrice += product.price * it.amount;

            this.setState({ productsData });
        });

        
        const initialCheckout = {
            value: productsPrice,
            currency: 'UAH'
        }

        // console.log('productsArray', productsArray);
        console.log('InitiateCheckout', initialCheckout);
        ReactPixel.track('InitiateCheckout', initialCheckout);
    
    }

    modalSubmit = () => {
        console.log('modal Submit');
        this.setState({ showModalInterval: false });
        this.sendOrderData(this.state.deliveryDurationInMinutes);
    }

    modalCancel = () => {
        console.log('modal Cancel');
        this.setState({ showModalInterval: false });
    }

    sendOrderData = (interval) => {
        console.log('interval', interval);

        let self = this;
        let formData = this.state.formData;
        let time = formData.time;

        this.setState({ preloader: true });

        let terminals = this.props.cities.find(it => it.id === this.props.activeCity.id).terminals;

        this.priceSend = this.state.price;
        this.amountSend = this.state.amount;

        let productsMS = '';
        let products = [];

        this.state.productsArray.map( item =>  {
            const { code, name, price, order } = this.props.products.find(product => product.id === item.id);
            
            products.push({
                id: code,
                name,
                price,
                quantity: order
            });

            productsMS += `${item['name']} - ${item['amount']}`

        });

        productsMS = `${productsMS}`;
        const productsMSLiqpay = `${productsMS}; \n +38${formData.phone} (${formData.name}) \n ${formData.delivery == '1' 
            ? `Адрес доставки: г.${this.props.activeCity.name[this.props.language]}, улица: ${formData.streetname}, Дом: ${formData.house}` 
            :  'Адрес самовывоза: г. ' + this.props.activeCity.name[this.props.language] + ', ' + terminals.find(it => it.id === formData.terminals).name[this.props.language]}`

        this.setState({
            productsMS,
            productsMSLiqpay
        });

            let data = {};

            if (formData.typePay === '692b19c9-735e-0558-bc8e-7db97f1bed79') {
                formData.typePay = '09322f46-578a-d210-add7-eec222a08871';
                formData.typePayCourier = true;
            } else {
                formData.typePayCourier = false;
            }

            if (!time || time === undefined || formData.selectTime === '1') {
                time = moment().add(interval, 'minutes').format('HH:mm');
            }

            if(formData.delivery == '1'){
                 data = {
                    "customer": {
                        "name": formData.name,
                        "phone": '+38'+formData.phone
                      },
                      "order": {
                        'marketingSources':'ebad6f9d-3015-4995-b19b-bf1622f0c35e',
                        "comment": `Оплата: ${formData.typePay === '32c4fa2f-066d-4fbf-be6a-1ca1273cb631' ? 'Картой(успешно)' : !formData.typePayCourier ? 'Наличные' : 'Картой курьеру'}, Комментарий: ${formData.comment}`,
                        "date": `${moment().format('YYYY-MM-DD')} ${time}:00`,
                        "isSelfService": false,
                        "items": this.state.productsArray,
                        "address": {
                            "apartment": formData.appartment,
                            "city": this.props.activeCity.name[this.props.language],
                            "entrance": formData.porch,
                            "floor": formData.floor,
                            "home": formData.house,
                            "housing": formData.housing,
                            "street": formData.streetname
                          },
                          "paymentItems": [
                            {
                              "paymentType": {
                                "id": formData.typePay
                              },
                              "sum": this.state.amount < 350 ? this.state.amount + 35 : this.state.amount
                            }
                          ],
                        "personsCount": "2",
                        "phone": '+38'+formData.phone
                      },
                      productsMS,
                      typePay: !formData.typePayCourier ? 'Наличные' : 'Картой курьеру',
                      "city": this.props.activeCity.name[this.props.language],
                }
            } else {
                data = {
                    "customer": {
                        "name": formData.name,
                        "phone": '+38'+formData.phone
                      },
                      "deliveryTerminalId": formData.terminals,
                      "order": {
                        'marketingSources':'ebad6f9d-3015-4995-b19b-bf1622f0c35e',
                        "comment": formData.comment,
                        "date": `${moment().format('YYYY-MM-DD')} ${time}:00`,
                        "isSelfService": true,
                        "items": this.state.productsArray,
                        "paymentItems": [
                          {
                            "paymentType": {
                              "id": formData.typePay
                            },
                            "sum": this.state.amount
                          }
                        ],
                        "personsCount": "2",
                        "phone": '+38'+formData.phone
                      },
                      productsMS,
                      terminal: terminals.find(it => it.id === formData.terminals).name[this.props.language],
                      typePay: !formData.typePayCourier ? 'Наличные' : 'Картой курьеру',
                      "city": this.props.activeCity.name[this.props.language],
                }
            };
            const dataEcommerce = {
                'event': 'purchase',
                'ecommerce': {
                  'purchase': {
                    'actionField': {
                      'id': moment(data.order.date).format('DDMMYYYYHHMMSS'),                    
                      'affiliation': !data.order.isSelfService ? 'Самовывоз' : data.terminal,
                      'revenue': data.order.paymentItems[0].sum,                
                      'tax': 0,
                      'shipping': !data.order.isSelfService ? 350 : 0,
                    },
                    'products': products
                  }
                }
            };

            let bt = this.btRef;
            // data['getMarketingSources'] = 'ebad6f9d-3015-4995-b19b-bf1622f0c35e';

            if(formData.typePay === '32c4fa2f-066d-4fbf-be6a-1ca1273cb631'){
                let order = {
                    customer: {
                        ...data.customer
                    },
                    order: {
                        ...data.order
                    },
                    organization: '4f3e6a9b-f335-11ea-8217-06967b0fe362',
                };

                if(data.deliveryTerminalId){
                    order['deliveryTerminalId'] = data.deliveryTerminalId;
                }
                
               this.setState({
                    orderId: Math.floor(Date.now() / 1000),
                }, () => {   
                    console.log('--- Save Order ----', data);
                    actions.saveOrder({...data, payId: this.state.orderId})
                        .then(res => {
                            console.log('res --->>>', res, bt);
                            bt.current.click();
                            this.setState({
                                //preloader: false
                            });
                        })
                        .catch(res => {
                            this.setState({
                                //preloader: false
                            });
                        })
                })

                

            } else {
                data['order']['paymentItems'][0]['isProcessedExternally'] = false;

                console.log("--- Send order ---");
                console.log("--- data ---", data);

                actions.sendOrder(data)
                .then(res => {
                    console.log('res -->>>', res);
                    axios.get('https://api.privatbank.ua/p24api/pubinfo?json&exchange&coursid=5')
                        .then(response => {
                            // const exchangeUsd = response.data.find(it => it.ccy === "USD") && response.data.find(it => it.ccy === "USD").sale;
                            // const priceToUsd = (Number(this.state.amount) / Number(exchangeUsd)).toFixed(2);

                            window.dataLayer.push(dataEcommerce);

                            // self.setState({
                            //     status: true,
                            //     preloader: false
                            // });

                            let result = JSON.parse(res);

                            if (result) {
                                console.log('result ===>>>>', result);
                                // this.props.dispatch(reset('CheckoutForm'));
                                

                                if (result.httpStatusCode === 500) {
                                    this.setState({
                                        status: false,
                                        showModalMessage: result.message,
                                        showModal: true,
                                        preloader: false
                                    });
                                } else {
                                    self.props.removeProduct();
                                    // this.setState({
                                    //     status: true,
                                    //     showModalMessage: 'Ваш заказ принятий!',
                                    //     showModal: true,
                                    //     preloader: false
                                    // });
                                    let url = this.props.language === 'uk' ? `/success/${result.customer.phone}/${result.number}` : `/ru/success/${result.customer.phone}/${result.number}`;
                                    self.props.history.push(url);
                                }
                            }
                        })
                        .catch(error => console.log('error', error));
                })
                .catch(res => {
                    this.setState({
                        status: false,
                        showModal: true,
                        preloader: false
                    });
                })
            }
    }

    getTimeFromMinsPickup = (terminalId) => {
        this.setState({ preloader: true });
        const result = actions.getDeliveryRestrictions()
            .then(res => {
                console.log('res -->>>', res);
                // console.log('res -->>>', JSON.parse(res))
                let deliveryDurationInMinutes = 0;
                if (res) {
                    deliveryDurationInMinutes = res.restrictions.find(t => t.deliveryTerminalId == terminalId);
                    console.log('deliveryDurationInMinutes', res.restrictions.find(t => t.deliveryTerminalId == terminalId))
                }
                this.setState({ preloader: false });
                return deliveryDurationInMinutes.deliveryDurationInMinutes - 45;
            })
            .catch(error => {
                console.log(error);
                this.setState({ preloader: false });
                return null;
            });
        return result;
    }

    getTimeFromMinsDelivery = (formData) => {
        let checkData = {};
        let typePay = formData.typePay;
        let time = formData.time;

        if (!time || time === undefined || formData.selectTime === '1') {
            const city = this.props.firebaseCities.find(it => it.spot_id === this.props.activeCity.id);
            const { orderTimeIntervalDelivery, orderTimeIntervalPickup } = city.workingHours[0];

            if (formData.delivery == '1') {
                time = moment().add(orderTimeIntervalDelivery, 'minutes').format('HH:mm');
            } else {
                time = moment().add(orderTimeIntervalPickup, 'minutes').format('HH:mm');
            }
        }

        console.log('--- time ---', time);

        if (typePay === '692b19c9-735e-0558-bc8e-7db97f1bed79') {
            typePay = '09322f46-578a-d210-add7-eec222a08871';
        }

        if (formData.delivery == '1') {
            checkData = {
                "customer": {
                "name": formData.name,
                "phone": '+38'+formData.phone
                },
                "order": {
                  "comment": "!! ATTENTION, TEST ORDER !!",
                //   "date": "2021-05-07 15:45:00",
                  "date": `${moment().format('YYYY-MM-DD')} ${time}:00`,
                  "isSelfService": false,
                  "address": {
                    "apartment": formData.appartment,
                    "city": this.props.activeCity.name[this.props.language],
                    "entrance": formData.porch,
                    "floor": formData.floor,
                    "home": formData.house,
                    "housing": formData.housing,
                    "street": formData.streetname
                  },
                  "items": this.state.productsArray,
                  "paymentItems": [
                    {
                      "isProcessedExternally": false,
                      "paymentType": {
                        "id": typePay
                      },
                      "sum": this.state.amount
                    }
                  ],
                  "personsCount": "0",
                  "phone": '+38'+formData.phone
                },
                "organization": "a57a7731-4107-11ea-80de-d8d385655247"
            }
        } else {
            checkData = {
                "customer": {
                    "name": formData.name,
                    "phone": '+38'+formData.phone
                },
                "deliveryTerminalId": formData.terminals,
                "order": {
                  "comment": "!! ATTENTION, TEST ORDER !!",
                  "date": `${moment().format('YYYY-MM-DD')} ${time}:00`,
                  "isSelfService": false,
                  "items": this.state.productsArray,
                  "paymentItems": [
                    {
                      "isProcessedExternally": false,
                      "paymentType": {
                        "id": typePay
                      },
                      "sum": this.state.amount
                    }
                  ],
                  "personsCount": "0",
                  "phone": '+38'+formData.phone
                },
                "organization": "a57a7731-4107-11ea-80de-d8d385655247"
            }
        }

        console.log('checkData *** >>', checkData);

       this.setState({ preloader: true });
       const result =  actions.checkCreate(checkData)
            .then(res => {
                let deliveryDurationInMinutes = 0;
                if (res) {
                    const data = JSON.parse(res);
                    console.log('data JSON >>>', data)
                    if (data) {
                        let isKey = false;
                        for (let key in data.deliveryRestriction) {
                            if (key === 'deliveryDurationInMinutes') isKey = true;
                        }
                        if (isKey) deliveryDurationInMinutes = data.deliveryRestriction.deliveryDurationInMinutes;
                    } else {
                        deliveryDurationInMinutes =  0;
                    }
                }
                this.setState({ preloader: false });
                return deliveryDurationInMinutes;
            })
            .catch(error => {
                console.log(error);
                this.setState({ preloader: false });
                return null;
            });

        return result;
    };

    onSubmit = (formData) => {
        console.log('onSubmit -->>>', formData);
        this.setState({ formData });
        const result = formData.delivery == '1' ? 
            this.getTimeFromMinsDelivery(formData) : 
            this.getTimeFromMinsPickup(formData.terminals);

        result
            .then(deliveryDurationInMinutes => {
                const city = this.props.firebaseCities.find(it => it.spot_id === this.props.activeCity.id);
                const { orderTimeIntervalDelivery, orderTimeIntervalPickup } = city.workingHours[0];
                const interval = formData.delivery == 2 ? orderTimeIntervalPickup : orderTimeIntervalDelivery;
                const isDeliveryDurationInMinutes = interval && deliveryDurationInMinutes && deliveryDurationInMinutes > 0 && deliveryDurationInMinutes > interval;
                
                // console.log('deliveryDurationInMinutes ***>>>', deliveryDurationInMinutes);
                // console.log('interval ***>>>', deliveryDurationInMinutes, interval);

                let productsPrice = 0;

                this.state.productsData.map(it => {
                    productsPrice += it.value;
                });

                // const isTargetCity = this.props.activeCity.id === '6bf17b9e-3ecd-0491-0179-0e815f2c9df9'|| this.props.activeCity.id === '6bf17b9e-3ecd-0491-0179-0e815f2c8b26' || this.props.activeCity.id === 'fe74eb1c-055f-4767-b1e6-1a8d7df54070';
            
                const purchase = {
                    value: productsPrice,
                    currency: "UAH"
                }
                ReactPixel.track('Purchase', purchase);
                console.log('this.state.productsData -->>>', this.state.productsData);
                

                if (isDeliveryDurationInMinutes) {
                    let timeInterval = moment().add(deliveryDurationInMinutes, 'minutes');
                    const modalTimeInterval = moment(timeInterval).format('H:mm');
                    this.setState({deliveryDurationInMinutes, modalTimeInterval, showModalInterval: true });
                } else {
                    this.sendOrderData(interval);
                    console.log('--- работаем по старому интервалу ---');
                }
                
            })
            .catch(err => {
                console.log(err);
            });
    }

    render(){
        let city = this.props.cities.find(it => it.id === this.props.activeCity.id);
        let terminals = city.terminals;
        let streets = this.props.streets && this.props.streets.find(it => it.city.id === this.props.activeCity.id);
        // streets = streets ? streets.streets : [];

        console.log('streets --->>', streets);
        console.log('all streets --->>', this.props.streets);

        return(
            <>
            {this.state.showModalInterval && <ModalCheckInterval 
                time={this.state.modalTimeInterval}
                modalSubmit={this.modalSubmit}
                modalCancel={this.modalCancel} />}
            {this.state.showModal && <Modal status={this.state.status} showModalMessage={this.state.showModalMessage} />}
            {this.state.preloader && <Preloader full={true}/>}
            <Checkout typeDelivery={this.state.typeDelivery}
                      typesPay={city.typePay}
                      typePayDelivery={city.typePayDelivery}
                      onSubmit={this.onSubmit}
                      btRef={this.btRef}
                      formRef={this.formRef}
                      productsArray={this.state.productsArray}
                      amount={this.state.amount}
                      initialValues = {
                        {
                            delivery: 1,
                            typePay: '09322f46-578a-d210-add7-eec222a08871',
                            terminals: terminals[0].id
                        }
                      }
                      streets={streets}
                      terminals={terminals}
                      city={city.id}
                />
             {this.state.orderId && city.liqPay && this.state.amount && this.state.productsMSLiqpay && true && <LiqPayPay
                publicKey={city.liqPay.publicKey}
                privateKey={city.liqPay.privateKey}
                description={this.state.productsMSLiqpay}
                // amount={String(this.state.amount)}
                amount={String(1)}
                currency="UAH"
                orderId={this.state.orderId}
                result_url="https://demo.ithinkers.ru/iqpizza/payOrder.php"/*http://localhost:8888/mono/send_order.php"*/
                server_url= "https://demo.ithinkers.ru/iqpizza/payOrderServer.php"/*{"http://localhost:3000/success?"+this.stateorderId} */
                product_description={this.state.productsMSLiqpay}
                style={{ margin: "8px" }}
                extra={<ButtonComponent btRef={this.btRef} key="1" />}
        />}
            </>
        )
    }
}

const mapDispatchToProps = (dispatch, props) => {
    return {
        dispatch,
        removeProduct: () => dispatch({type: 'REMOVE-PRODUCT'}),
    }
  }

const mapStateToProps = (state, props) => {
    // console.log('state --->>', state);
    return {
        language: state.menu.languageMenu,
        products: state.menu.products,
        cities: state.menu.cities,
        activeCity: state.menu.activeCity,
        streets: state.menu.streets,
        firebaseCities: state.firebase.data.settings && state.firebase.data.settings.City ? state.firebase.data.settings.City : [],
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CheckoutContainer));