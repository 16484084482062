import React from 'react';
import './article.style.css';

const ArticleUa = () => {
    return (
        <div className="about-us">
            <h1>ПІЦА МАРГАРИТА</h1>
            <p>Піца Маргарита 30 см - класична італійська випічка, улюблена багатьма. Ситна, соковита, в міру пікантна. Ароматна зелень і томатний соус, сир. Інгредієнти доповнюють один одного і роблять «Маргариту» ідеальною! Правду кажуть: «Все найкраще називають жіночими іменами!»</p>
            <p>Ви плануєте італійський обід з сім'єю або друзями? А може захотіли зробити приємне собі коханому? Тоді піца Маргарита 30 см від IQ Pizza - ваш варіант! Ми приготуємо ваш обід в кращих італійських традиціях і доставимо його в зручне місце, час спеціально для вас!</p>
            <p>Інгредієнти, які викладаються на тісто:</p>
            <ul>
                <li>томатний соус;</li>
                <li>сир "Моцарела";</li>
                <li>помідори;</li>
                <li>зелень.</li>
            </ul>
            <p>Хочете, щоб ваша піца Маргарита 30 см стала ще смачнішою? Пропонуємо вам додати до неї додаткові інгредієнти. Підберіть начинку на свій смак з нашого меню, коли будете оформляти замовлення. І не забудьте про бортики! У нас є такі варіанти начинки бортика: сирний і сосисковий.</p>
            <p>Доставка оформляється трьома способами:</p>
            <ul>
                <li>зателефонуйте за номером телефону нашого кур'єрського сервісу: 068 115 1010;</li>
                <li>оформлюйте через сайт;</li>
                <li>замовляйте через особистий кабінет мобільного додатка.</li>
            </ul>
            <p>Наші кур'єри з радістю привезуть вам свіжу, гарячу, ситну їжу піца Маргарита 30 см  щодня, з 10:00 до 22:00. Вам же потрібно тільки її замовити!</p>
        </div>
    );
}

export default ArticleUa;