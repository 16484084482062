import React from 'react';
import s from './modal.module.css';
import { useTranslation } from "react-i18next";

import tick from '../../assets/tick.svg';
import error from '../../assets/error.svg';

import Button from '../../components/button';
import { withRouter } from "react-router";

const ModalResult = (props) => {
    const { t, i18n } = useTranslation();
    return(
        <div className={s.overlay}>
            <div className={s.modal}>
                <div className={s.modal_container}>
                    <h3>{t('yourLanguage')}</h3>
                    <div className={s.languages}>
                        <span onClick={() => props.selectLanguage('uk')} className={[props.language == 'uk' && s.active]}>Українська</span>
                        <span onClick={() => props.selectLanguage('ru')} className={[props.language == 'ru' && s.active]}>Русский</span>
                    </div>
                    <Button title={t('close')} 
                        handleClick={props.handleClick}/>
                </div>
            </div>
        </div>
    )
}

export default withRouter(ModalResult);