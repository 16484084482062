import React from 'react';

const DeliveryRu = ({ cityName, cityId }) => {
    const isZp = cityId === 'fe74eb1c-055f-4767-b1e6-1a8d7df54070';
    return (
        <div style={{
            // height: 400,
            // overflowY: 'scroll',
        }}>
            <h1>Доставка пиццы {cityName}</h1>
            <p>Хотите пообедать аппетитной сытной пиццей, но лень идти в пиццерию? Друзья, выход есть! Доставка пиццы в любое удобное место и время от IQ Pizza -  это то, что вам нужно! Сейчас мы расскажем вам, как работает этот супер - комфортный сервис!</p>
            <p>Лучшая доставка пиццы от наших ресторанов осуществляется каждый день, без выходных.  Мы работаем с 10:00 до 22:00. Заказы на еду мы принимаем до 21:00. </p>
            <p>Важная информация: время работы заведений, условия приема заказа и срок, за который производится доставка пиццы, может отличаться зависимо от графика работы заведения вашего города. Обращайте на это внимание, когда делаете свой заказ. </p>
            {isZp && (
                <>
                    <h2>Доставим за 45 минут или пицца в подарок</h2>
                    <ul>
                        <li>Доставим заказ за 45 минут или подарим пиццу Курица-Грибы 30см.</li>
                        <li>Подарок можно получить с последующим заказом в течение 3-х недель.</li>
                        <li>Счет времени начинается с момента получения СМС-подтверждения заказа.</li>
                        <li>Акция действует в зонах доставки.</li>
                    </ul>
                    <p>С зонами нашей доставки Вы можете ознакомиться на сайте <a style={{ color: '#ed2630' }} target='blank' href="https://zp.iqpizza.com.ua/ru/">https://zp.iqpizza.com.ua/ru/</a></p>
                </>
            )}
            <h2>Сколько стоит доставка пиццы</h2>        
            <p>Доставка пиццы от IQ Pizza – один самых быстрых и доступных курьерских сервисов. Минимальная стоимость поставки еды составляет 35 грн. Чтобы курьер привез вам свежую, горячую еду по адресу, выберите ближайшее к себе заведение IQ Pizza. </p>
            <p>Для того, чтобы оформить заказ, добавьте понравившуюся еду в корзину. Далее введите ваши контакты: </p>
            <ul>
                <li>номер телефона;</li>
                <li>укажите, где находится дом, куда нужно привезти заказ;</li>
                <li>выберите способ оплаты.</li>
            </ul>
            <p>Дальше ждите обратный звонок от нашего менеджера, который поступит к вам через несколько минут. Передайте работнику все свои пожелания, чтобы наши повара приготовили самую вкусную еду для вас!</p>
            <p>Важная информация: доставка пиццы по адресу, который находится в радиусе более 5 километров от пиццерии, может стоить дороже. Уточняйте стоимость и дополнительные нюансы у оператора при оформлении заказа. </p>
            <h2>Бесплатная доставка пиццы</h2>
            <p>Для любителей крупных пицца-пати в сети ресторанов IQ Pizzа подготовили отличную новость. При заказе еды на сумму более чем 350 грн доставка пиццы по городу будет для вас бесплатной! </p>
            <p>350 грн – это средняя цена, в которую вам обойдется ужин для всей семьи или стол для вечеринки в компании. Бесплатная доставка пиццы – приятный бонус, который мы хотим подарить вам, чтобы ваш вечер стал еще лучше!</p>
            <p>Чтобы оформить бесплатную отправку еды от IQ Pizzа, изучите наш ассортимент и выберите понравившиеся блюда на сумму более 350 грн. Добавили любимую еду в корзину, но до бесплатной поставки все равно немного не хватает? Закажите освежающие прохладные напитки из нашего меню, чтобы доставка пиццы была для вас бесплатной, а вечер идеальным! </p>
            <h2>Пицца самовывоз</h2>
            <p>Ресторан IQ Pizzа находится в удобных локациях во многих городах Украины. Мы хотим, чтобы как можно большее количество людей могло получить вкусную и свежую еду на дом или в офис. <br />
            В каких городах Украины можно недорого купить и забрать свою еду:</p>
            <ul>
                <li>Днепр</li>
                <li>Запорожье</li>
                <li>Киев</li>
                <li>Павлоград</li>
                <li>Новомосковск</li>
                <li>Энергодар</li>
                <li>Днепрорудное</li>
                <li>Вольнянск</li>
                <li>Чернигов</li>
                <li>Бердянск</li>
                <li>Мелитополь</li>
                <li>Пологи</li>
                <li>Токмак</li>
            </ul>
            <p>С ближайшими точками, где доступна самостоятельная доставка пиццы, вы можете ознакомиться на нашем сайте и в приложении IQ Pizzа.</p>
            <h2>Оплата доставки</h2>
            <p>Наша пиццерия стремится, чтобы доставка пиццы и ее оплата были максимально удобными для вас. Нашим клиентам доступны такие варианты оплаты еды:</p>
            <ul>
                <li>картой через терминал на точках, в которых вы выбрали самовывоз;</li>
                <li>наличными курьеру при получении по указанному адресу;</li>
                <li>через сервисы онлайн – оплаты, используя наше приложение</li>
            </ul>
            <p>Важная информация: при оформлении адресной доставки через наш сайт сообщите оператору, сдачу с какой суммы должен иметь при себе курьер. Служба приема заказов внесет необходимые данные в чек. </p>
            <h2>Быстрая доставка пиццы</h2>
            <p>Ваш заказ будет обработан операторами в короткий срок. Если доставка пиццы оформляется через сайт, в ближайшее время с вами свяжется наш менеджер для уточнения заказа, адреса и времени получения. </p>
            <p>Блюдо итальянской кухни готовится для вас в течение часа.  Как быстро приезжает курьер? Время ожидания составит около часа, исходя из вашего местонахождения. </p>
            <p>Важная информация: во время высокой загрузки пиццерии время обработки и отправки заказа может увеличиваться. Уточняйте этот момент у нашего менеджера при оформлении и подтверждении заказа. </p>
            <p>Не хотите долго ждать свою еду? Вам поможет доставка пиццы к определенному времени. Сделайте соответствующую пометку, используя наше приложение, или сообщите менеджеру желаемое время получения заказа на сегодня. </p>
            <h2>Номер доставки пиццы</h2>
            <p>Доставка пиццы от IQ Pizzа – это быстро и удобно! Для того, чтобы сделать заказа еды по телефону, наберите номер нашего официального контакт – центра:</p>
            <ul>
                <li>068 115 1010.</li>
            </ul>
            <p>Ваш адрес не входит в район работы курьерского сервиса? Не беда! В таких случаях наши операторы примут ваш заказ на приготовление еды по таким номерам телефона: </p>
            <ul>
                <li>380681151010;</li>
                <li>380991151010.</li>
            </ul>
            <p>Во время звонка озвучьте, какое блюдо вы желаете заказать, назовите дополнительные ингредиенты и наполнение бортика. Также не забудьте добавить адрес поставки и желаемое время получения заказа. Звонки по территории Украины осуществляются бесплатно!</p>
        </div>
    );
}

export default DeliveryRu;