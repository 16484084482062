import React from 'react';
import s from './preloader.module.css';

const PreloaderSmall = () => {
    return(
        <div className={s.preloaderContainer}>
            <div className={s.preloader}></div>
        </div>
    )
}

export default PreloaderSmall;