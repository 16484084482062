import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateWorkingHours } from '../../redux/firebase';
import i18n from "i18next";

import s from './basket.module.css';
import Button from '../../components/button';
import Product from './product/product';
import {NavLink, useHistory} from 'react-router-dom';
import { useTranslation } from "react-i18next";

const Basket = (props) => {
    let history = useHistory();
    const dispatch = useDispatch();
    const alertMessage = useSelector(state => state.firebase.data.settings);
    const cities = useSelector(state => state.menu.cities);
    const lng = useSelector(state => state.menu.languageMenu);
    const activeCity = useSelector(state => state.menu.activeCity);
    const city = cities.find(t => t.id === activeCity.id);
    let products = props.products;

    let result = [];
   
    if(products.length > 0){
        let newProduct  = products.filter(item => item.count > 0);
        newProduct.map( it => {
            let search = result.find(i => i.id === it.id);
            if(!search){
                result.push(it);
            }
        });
    }; 

    const getSum = () => {
        let sum = 0;

        for(let item in result){
            let price = result[item].price;
            let count = result[item].count;

            if (city && city.terminals) {
                result[item].differentPricesOn.map(t => {
                    city.terminals.map(terminal => {
                        if (terminal.id === t.terminalId) {
                            price = t.price;
                        }
                    })
                });
            }

            let modifierSum = 0;
            result[item].modifiers.map(a => modifierSum += a.price*a.amount);
            result[item].groupModifiers.map(a => a.childModifiers.map(b => {
                modifierSum = modifierSum +  (b.price * (b.amount * 10)) / 10
            }));
            sum += ((modifierSum*10+price*10)*count)/10;             
        }

        // if (sum < 350 && sum !== 0) {
        //     sum += 35;
        // }

        return sum;
    }

    let sum = result.reduce((a, b) => a+Number(b['price'])*b.count, 0);

    if (sum < 350 && sum !== 0) {
        sum += 35;
    }

    const checkOrderAvailable = (_, orderAvailable) => {
        let message = '';
        if (alertMessage && alertMessage.appSettings) {
            message = alertMessage.appSettings.closedSpotAlertDescription;
        }

        if(result.length > 0 && orderAvailable){
            history.push(lng === 'uk' ? '/checkout/' : '/ru/checkout/');
        } else if (!orderAvailable) {
            alert(message);
        } else {
            alert(t('addToBusket'));
        }
    }

    const openCheckout = () => {
        // console.log('openCheckout *** -->>>', openCheckout);
        props.getWorkingHours()
            .then(snapshot => {
                const workingHours = snapshot.val();
                // console.log('workingHours ***', workingHours);
                dispatch(updateWorkingHours(workingHours));
            })
            .catch(err => console.error(err));
            
            props.getFieldOrderAvailable()
            .then(snapshot => {
                const orderAvailable = snapshot.val();
                console.log('orderAvailable ***', orderAvailable);
                if (orderAvailable) {
                    checkOrderAvailable(props.result, orderAvailable);
                }
            })
            .catch(err => console.error(err));
    }

    // console.log(result, 'result');

    // const openCheckout = () => {
    //     if(result.length > 0){
    //         history.push('/checkout');
    //     } else {
    //         history.push('/');
    //     }
    // }

    const { t, i18n } = useTranslation();
    return(
        <div className='container'>
            <div className='row'>
                <div className={s.basketContainer}>
                    <h3>{t('basket')}</h3>
                    <div className={s.basket}>
                        {result.length === 0 ? <div className={s.warning}>{t('addProducts')}</div> : null}
                        <div className={s.productList}>
                         {result.map((item, index) => {
                             return <Product languageMenu={props.languageMenu} key={index} item={item} updateProduct={props.updateProduct} deleteProduct={props.deleteProduct}/>
                         })}
                            <div className={s.basketFooter}>
                                <div className={s.title}>{t('pay')}: <span>{getSum()}</span>₴</div>
                                {getSum() < 350 ? 
                                    <div className={[`${s.warning} ${s.warningSumm}`]}>
                                        <p>{t('freeDelivery')} -  350₴. {t('minimalSum')}</p>
                                        <p>Доставка 35₴ <Link className={s.link} to={lng === 'uk' ? '/dostavka/' : '/ru/dostavka/'}>по зонам доставки</Link></p>
                                        {/* <p>{t('taxZoneDelivery')}</p> */}
                                        <p> {t('deliveryWithoutZones')} - <a className={s.link} href="tel:380681151010">380681151010</a>  <a className={s.link} href="tel:380991151010">380991151010</a></p>
                                    </div> : null}
                                    <Button handleClick={openCheckout} title={t('next')}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Basket;