import React, { Component } from 'react';
import {connect} from 'react-redux';
import {getMenu, updateProduct} from '../../redux/menu';
import { withRouter } from "react-router";
import { getCategoryId } from '../Router/ohter';

import ReactPixel from 'react-facebook-pixel';
import TagManager from 'react-gtm-module';

import Main from './main';

import {
    scroller,
    scrollSpy
  } from "react-scroll";


class MainContainer extends Component{

    constructor(props){
        super(props);
        this.state = {
            activeCategory: null
        };
    }



      onRouteChanged() {

    }

  componentDidUpdate(){
       var url = null;
        if(url !== this.props.match.params.link){
            // this.openLink(this.props.match.params.link);
        }
        url = this.props.match.params.link;
        
    }

    componentDidMount(){
        window.scroll(0, 0);
        console.log('*** --- main --- ***');
        
        const isTargetCity = this.props.activeCity.id === '6bf17b9e-3ecd-0491-0179-0e815f2c9df9'|| this.props.activeCity.id === '6bf17b9e-3ecd-0491-0179-0e815f2c8b26' || this.props.activeCity.id === 'fe74eb1c-055f-4767-b1e6-1a8d7df54070';
        
        if (this.props.activeCity && this.props.products) {
            if (!this.props.isHomePage) {

                if (isTargetCity) {
                    ReactPixel.init('5129251417150258');
                    ReactPixel.pageView();
                }
                
                const tagManagerArgs = {
                    gtmId: this.props.activeCity.gtm
                };
                // TagManager.initialize(tagManagerArgs);
            }
        }

        this.setState({ activeCategory: this.props.categories[0]['id']});
    }


    openLink = (link) => {
        scroller.scrollTo(link, {
          duration: 0,
          delay: 0,
          smooth: "easeInOutQuart",
          offset: -650
        });
    }

componentWillUnmount() {
    // scrollSpy.unmount();
    // scroller.unmount();
  }

  updateCategory = (id) => {
    let path = this.props.location.pathname;
    let language = this.props.languageMenu;
    let urlPath = '';

    if (id === getCategoryId('napitki')) urlPath = language === 'uk' ? 'napoji' : 'napitki';
    if (id === getCategoryId('pizza30')) urlPath = language === 'uk' ? 'pica-30-sm/' : 'picca-30-sm/';
    if (id === getCategoryId('pizza45')) urlPath = language === 'uk' ? 'pica-45-sm/' : 'picca-45-sm/';
    if (id === getCategoryId('love')) urlPath = language === 'uk' ? 'love/' : 'love/';
    if (id === getCategoryId('hophey')) urlPath = language === 'uk' ? 'hophey/' : 'hophey/';
    if (id === getCategoryId('combo', this.props.activeCity.id)) urlPath = language === 'uk' ? 'combo/' : 'combo/';
    if (id === getCategoryId('helloween')) urlPath = language === 'uk' ? 'helloween/' : 'helloween/';
    if (id === getCategoryId('boxmenu')) urlPath = language === 'uk' ? 'box-menu/' : 'box-menu/';
    if (id === getCategoryId('sezonnemenu')) urlPath = language === 'uk' ? 'sezonnemenu/' : 'sezonnemenu/';
    if (id === getCategoryId('30sm')) urlPath = language === 'uk' ? '30sm/' : '30sm/';
    if (id === getCategoryId('45sm')) urlPath = language === 'uk' ? '45sm/' : '45sm/';

    const isRuLng = path.indexOf('/ru') !== -1;

    // console.log('isRuLng', isRuLng);

    const pushUrl = `${language === 'uk' ? `menu/${urlPath}` : isRuLng ? `menu/${urlPath}` : `ru/menu/${urlPath}`}`;

    if (path.indexOf('s/dostavka')) {
        path = path.replace('s/dostavka', '');
    }

    const url = path + pushUrl;

    this.setState({
        activeCategory: id
    })

    console.log('url', url);

    this.props.history.push(url);
    this.openLink('menu');
    }

    addProductBasket = (id) => {
        let index = this.props.products.findIndex(item => item['id'] === id);
        this.props.updateProduct('increment', id);
    }

    render(){
        return(
            <Main categories={this.props.categories}
                products={this.props.products}
                basket={this.props.basket}
                activeCategory={this.state.activeCategory ? this.state.activeCategory : this.props.categories.length > 0 ? this.props.categories[0]['id'] : 0}
                updateCategory={this.updateCategory}
                updateProduct={this.props.updateProduct}
                languageMenu={this.props.languageMenu}
                cities={this.props.cities}
                activeCity={this.props.activeCity}
                slider={this.props.data ? this.props.data.banners : []}
                hideProducts={this.props.data ? this.props.data.hiddenProducts : []}
            />
        )
    }

}

const matStateToProps = (state) => {
    return {
        products: state.menu.products,
        categories: state.menu.categories,
        basket: state.menu.basket,
        languageMenu: state.menu.languageMenu,
        cities: state.menu.cities,
        activeCity: state.menu.activeCity,
        isHomePage: state.menu.isHomePage,
        data: state.menu.data,
    }
}

 export default connect(matStateToProps, {getMenu, updateProduct})(withRouter(MainContainer));