import React from 'react';

const DeliveryUa = ({ cityName, cityId }) => {
    const isZp = cityId === 'fe74eb1c-055f-4767-b1e6-1a8d7df54070';

    return (
        <div style={{
            // height: 400,
            // overflowY: 'scroll',
        }}>
            <h1>Доставка піци {cityName}</h1>
            <p>Хочете пообідати апетитною ситною піцою, але ліньки йти в піцерію самотужки або організовувати самовивіз? Друзі, вихід є!</p>
            <p>Доставка піци в будь-яке зручне місце і час від IQ Pizza - це те, що вам потрібно! Зараз ми розповімо вам, як працює цей супер - комфортний сервіс!</p>
            <p>Краща доставка піци від наших ресторанів на дім здійснюється щодня, без вихідних. Ми працюємо з 10:00 до 22:00. Замовлення на їжу ми приймаємо до 21:00</p>
            <p>Важлива інформація: час роботи закладів, умови прийому замовлення і термін, за який проводиться доставка піци, може відрізнятися залежно від графіка роботи закладу вашого міста. Звертайте на це увагу, коли робите своє замовлення. Щоб побачити графік, згідно з яким ми зможемо відправити ваше замовлення, оберіть своє місто в переліку на нашому сайті.</p>
            {isZp && (
                <>
                    <h2>Доставимо за 45 хвилин або піца у подарунок</h2>
                    <ul>
                        <li>Доставимо замовлення за 45 хвилин або подаруємо піцу Курка-Гриби 30см</li>
                        <li>Подарунок можна отримати з наступним замовленням впродовж 3х тижнів.</li>
                        <li>Відлік часу починається з моменту отримання СМС-підтвердження замовлення.</li>
                        <li>Акція діє в зонах доставки.</li>
                    </ul>
                    <p>З зонами нашої доставки Ви можете ознайомитись на сайті <a style={{ color: '#ed2630' }} target='blank' href="https://zp.iqpizza.com.ua/ru/">https://zp.iqpizza.com.ua/ru/</a></p>
                </>
            )}
            <h2>Скільки коштує доставка піци</h2>
            <p>
                Доставка піци від IQ Pizza - один найшвидших і доступних кур'єрських сервісів. Мінімальна вартість поставки їжі для клієнтів становить 35 грн. Щоб кур'єр привіз вам свіжу гарячу їжу за адресою, виберете найближчим до себе заклад IQ Pizza. 
                Для того, щоб оформити замовлення, додайте вподобану їжу в кошик. </p>
            Далі введіть ваші контакти:
            <ul>
                <li>номер телефону</li>
                <li>адреса, куди необхідна доставка піци</li>
                <li>оберіть спосіб оплати</li>
            </ul>
            <p>
                Важлива інформація: доставка піци за адресою, який знаходиться за межами зони в 5 кілометрів від піцерії, може коштувати дорожче. Уточнюйте вартість і додаткові нюанси у оператора при оформленні замовлення. Наш персонал допоможе вибрати найближчу точку видачі для швидкої та недорогої доставки. 
            </p>
            <h2>Безкоштовна доставка піци</h2>
            <p>Для любителів великих піца-паті в мережі ресторанів IQ Pizzа підготували відмінну новину. При замовленні їжі на суму більш ніж 350 грн доставка піци по місту буде для вас безкоштовною!</p>
            <p>350 грн - це середня ціна, в яку вам обійдеться вечеря для всієї родини або стіл для вечірки в компанії. Безкоштовна доставка піци - приємний бонус, який ми хочемо подарувати вам, щоб ваш вечір став ще краще!</p>
            <p>Щоб оформити безкоштовну відправку їжі від IQ Pizzа, передивіться наш асортимент і оберіть страви, що вам до вподоби, на суму понад 350 грн. </p>
            <p>Додали улюблену їжу в кошик, але до безкоштовної поставки все одно трохи не вистачає? Замовте освіжаючі прохолодні напої з нашого меню, щоб доставка піци була для вас безкоштовною, а вечір ідеальним!</p>
            <h2>Піца самовивезення</h2>
            <p>Ресторан IQ Pizzа знаходяться в зручних локаціях в багатьох містах України. Ми хочемо, щоб якомога більша кількість людей могло отримати смачну і свіжу їжу додому або в офіс.</p>
            В яких містах України можна недорого купити і забрати свою їжу:
            <ul>
                <li>Дніпро</li>
                <li>Запоріжжя</li>
                <li>Київ</li>
                <li>Павлоград</li>
                <li>Новомосковськ</li>
                <li>Енергодар</li>
                <li>Дніпрорудне</li>
                <li>Вільнянськ</li>
                <li>Чернігів</li>
                <li>Бердянськ</li>
                <li>Мелітополь</li>
                <li>Пологи</li>
                <li>Токмак</li>
            </ul>
            <p>З найближчими точками, де доступна самостійна доставка піци, ви можете ознайомитися на нашому сайті і в додатку IQ Pizzа</p>
            <h2>Оплата доставки</h2>
            <p>Наша піцерія прагне, щоб доставка піци і її оплата були максимально зручними для вас. </p>
            Нашим клієнтам доступні такі варіанти оплати їжі:
            <ul>
                <li>картою через термінал на точках, в яких ви вибрали самовивезення;</li>
                <li>готівкою кур'єру при отриманні за вказаною адресою;</li>
                <li>можливість розрахуватися банківською карткою онлайн на сайті чи у додатку.</li>
            </ul>
            <p>Важлива інформація: при оформленні адресної доставки через наш сайт повідомте оператору, решту з якої суми повинен мати при собі кур'єр. Служба прийому замовлень попросить надіслати необхідні дані доставці та внести їх в чек.</p>
            <h2>Швидка доставка піци</h2>
            <p>Ми прагнемо, щоб у наших клієнтів було обслуговування найвищої якості.  Ваше замовлення буде оброблено операторами в короткий термін. Якщо доставка піци оформлюється через сайт, найближчим часом з вами зв'яжеться наш менеджер для уточнення замовлення, адреси та часу отримання.</p>
            <p>Страва італійської кухні готується для вас протягом години. Як швидко приїжджає кур'єр? Час очікування складе близько години, виходячи з вашого місцезнаходження.</p>
            <p>Важлива інформація: під час високого завантаження піцерії час обробки і відправки замовлення може збільшуватися. Уточнюйте цей момент у нашого менеджера під час повідомлення та підтвердження замовлення.</p>
            <p>Не хочете довго чекати, доки ваша смачна їжа готується для вас? Вам допоможе доставка піци на певний час. Ви можете залишити відповідну позначку, використовуючи наш додаток, або повідомте менеджеру бажаний час отримання замовлення на сьогодні. Найкраща вечеря буде доставлена до вас вчасно!</p>
            <h2>Номер доставки піци</h2>
            <p>Доставка піци від IQ Pizzа - це швидко і зручно! Для того, щоб зробити замовлення їжі, наберіть телефон нашого офіційного контакт - центру:</p>
            <ul>
                <li>068 115 1010</li>
            </ul>
            <p>Вашу адресу не занесено в район роботи кур'єрського сервісу? Не біда! У таких випадках наші оператори візьмуть ваше замовлення на приготування їжі за такими номерами телефону:</p>
            <ul>
                <li>380681151010</li>
                <li>380991151010</li>
            </ul>
            <p>Під час дзвінка озвучите, яку страву ви бажаєте замовити, назвіть додаткові інгредієнти, оберіть найсмачніші бортики з наповненням із ніжного крем-сиру або сосисок. Також не забудьте додати адресу поставки і бажаний час отримання замовлення. Найсмачніша їжа буде доставлена до вас з урахуванням усіх побажань.  Дзвінки по Україні здійснюються безкоштовно!</p>
        </div>
    );
}

export default DeliveryUa;