import React from 'react';
import './article.style.css';

const ArticleRu = () => {
    return (
        <div className="about-us">
            <h1>ПИЦЦА ЧИЗБУРГЕР</h1>
            <p>Пицца Чизбургер 30 см – то, что нужно гурманам, которые обожают бургеры и жить не могут без пиццы. Как говорится: «Зачем разрываться между двумя любимыми угощениями, если можно получить сразу оба?»</p>
            <p>Вкусная пицца Чизбургер 30 см от IQ Pizza – это угощение, которое никого не оставит равнодушным. Тонкое тесто, аппетитная, свежая начинка – в этой выпечке идеально все! </p>
            <p>Только представьте самый большой бургер, которым можно легко наесться самому и поделиться с близкими!</p>
            <p>Что внутри пицца Чизбургер 30 см:</p>
            <ul>
                <li>соус болоньезе BBQ; </li>
                <li>сыр моцарелла;</li>
                <li>курица; </li>
                <li>сыр "Чеддер"; </li>
                <li>огурцы соленые; </li>
                <li>лук маринованный. </li>
            </ul>
            <p>Также не забудьте добавить дополнительные ингредиенты, которые сделают любимое блюдо еще вкуснее! Доступны такие варианты начинки: бортик с сыром, бортик с сосисками, двойной сыр, мясо и многое другое! </p>
            <p>Нравится пицца Чизбургер 30 см, но не получается забрать ее из заведения лично? Не беда! Можно заказать курьера. Мы привезем вам горячую и свежую выпечку по любому удобному адресу, в нужное время! </p>
            <ul>
                <li>стоимость перевозки до 5 км от ближайшего заведения – 35 грн;</li>
                <li>стоимость перевозки на расстояние более 5 км от заведения – оговаривается отдельно. </li>
            </ul>
            <p>Решили закатить пир? Тогда воспользуйтесь нашей курьерской службой! При заказе на сумму более 350 грн доставка бесплатна! Подробнее про услуги сервиса читайте в пункте меню «Доставка» на нашем сайте. </p>
        </div>
    );
}

export default ArticleRu;