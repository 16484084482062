import React from 'react';
import './article.style.css';

const ArticleUa = () => {
    return (
        <div className="privacy-policy">
            <h1>ПОЛІТИКА КОНФІДЕНЦІЙНОСТІ</h1>
            <p>Справжня Політика конфіденційності (далі - «Політика конфіденційності») розроблена відповідно до вимог Законодавства України «Про захист персональних даних» та діє відносно всієї інформації, яку Інтернет-магазин «IQ PIZZA», розташований на доменному імені https: // iqpizza. com.ua/(далее - «Інтернет-магазин»), може отримати про Користувача під час використання сайту Інтернет-магазину.</p>
            <ol>
                <li>
                    <h2>Терміни та визначення</h2>
                    <p>У цій Політиці конфіденційності використовуються такі терміни:</p>
                        <ol>
                            <li>«Адміністрація сайту Інтернет-магазину« IQPIZZA »(далі - Адміністрація сайту) - уповноважені співробітники на управління сайтом, що діють від імені Продавця, які організовують і (або) здійснюють обробку персональних даних, а також визначають цілі обробки персональних даних, склад персональних даних, що підлягають обробці, дії (операції), що здійснюються з персональними даними.</li> 
                            <li>«Персональні дані» - будь-яка інформація, що стосується прямо або непрямо визначеної або тієї, що визначається, фізичної особи (суб'єкту персональних даних).</li> 
                            <li>«Обробка персональних даних» - будь-яка дія (операція) або сукупність дій (операцій), що здійснюються з використанням засобів автоматизації або без використання таких засобів з персональними даними, включаючи збір, запис, систематизацію, накопичення, зберігання, уточнення (оновлення, зміна), витяг, використання, передачу (поширення, надання, доступ), знеособлення, блокування, видалення, знищення персональних даних.</li> 
                            <li>«Конфіденційність персональних даних» - обов'язкова для дотримання Оператором або іншою, що отримала доступ до персональних даних, особою вимога не допускати їх поширення без згоди суб'єкта персональних даних або наявності іншої законної підстави.</li> 
                            <li>«Користувач сайту Інтернет-Магазину« IQ PIZZA »(далі - Користувач)» - особа, яка має доступ до Сайту, за допомогою мережі Інтернет і використовує Сайт інтернет-магазину.</li> 
                            <li>«IP-адреса» - унікальна мережева адреса вузла в комп'ютерній мережі, побудованої за протоколом IP.</li> 
                            <li>«Cookies» - невеликий фрагмент даних, відправлений веб-сервером і що зберігається на комп'ютері користувача, який веб-клієнт або веб-браузер щоразу пересилає веб-серверу в HTTP-запиті при спробі відкрити сторінку відповідного сайту.</li> 
                        </ol>
                </li>
                <li>
                    <h2>Загальні положення</h2>
                    <ol>
                        <li>Використання сайту Інтернет-магазину означає беззастережну згоду Користувача з цією Політикою і зазначеними в ній умовами обробки його персональної інформації, яку він дав у відповідності з цією Політикою в рамках відносин з Продавцем. У разі незгоди з цими умовами користувач повинен утриматися від використання сайту Інтернет-магазину.</li>
                        <li>
                            <span>Персональні дані, дозволені до обробки в рамках цієї Політики конфіденційності, надаються Користувачем самостійно при реєстрації (створенні облікового запису) або в процесі використання Сервісів шляхом заповнення реєстраційної форми на сайті Інтернет-магазину в розділі «ОФОРМЛЕННЯ ЗАМОВЛЕННЯ» і включають в себе наступну інформацію:</span>
                            <ol>
                                <li>ПІБ;</li>
                                <li>Адреса;</li>
                                <li>Населений пункт;</li>
                                <li>Телефон;</li>
                                <li>Засоби зв'язку;</li>
                                <li>Інша інформація, добровільно надана Користувачем під час відвідування сайту Інтернет-магазину.</li>
                            </ol>
                        </li>
                        <li>Дані, які автоматично передаються Сервісам в процесі їх використання за допомогою встановленого на пристрої Користувача програмного забезпечення, в тому числі IP-адреса, інформація з cookie, інформація про браузер Користувача (або іншої програми, за допомогою якої здійснюється доступ до Сервісів), час доступу , адреса запитуваної сторінки.</li>
                        <li>Справжня Політика конфіденційності застосовується лише до сайту Інтернет-магазину.</li>
                        <li>Адміністрація сайту не перевіряє достовірність персональних даних, що надаються Користувачем сайту Інтернет-магазину.</li>
                    </ol>
                </li>
                <li>
                    <h2>Цілі збору і обробки персональної інформації користувачів</h2>
                    <ol>
                        <li>Інтернет-магазин «IQ PIZZA» збирає та зберігає тільки ті персональні дані, які необхідні для надання Послуг (виконання оферти і / або інших договорів з Користувачем).</li>
                        <li>
                            <span>Персональну інформацію Користувача Інтернет-магазин «IQ PIZZA» може використовувати в наступних цілях:</span>
                            <ol>
                                <li>ідентифікація сторони в рамках оферти і договорів з Інтернет-магазином «IQ PIZZA»;</li>
                                <li>надання Користувачеві персоналізованих Сервісів;</li>
                                <li>надання Користувачеві з його згоди оновлень продукції, спеціальних пропозицій, інформації про ціни, розсилки новин та інших відомостей від імені Інтернет-магазину, в тому числі напрямок повідомлень, запитів та інформації, що стосуються використання Сервісів, а також обробка запитів і заявок від Користувача;</li>
                                <li>поліпшення якості Послуг, зручності їх використання, розробка нових Сервісів;</li>
                                <li>реклами своїх продуктів за згодою Користувача;</li>
                                <li>проведення статистичних та інших досліджень, на основі наданих даних.</li>
                            </ol>
                        </li>
                    </ol>
                </li>
                <li>
                    <h2>Умови обробки персональної інформації Користувача</h2>
                    <ol>
                        <li>Інтернет-магазин «IQ PIZZA» зберігає персональну інформацію користувачів у своїй базі персональних данних, забезпечуючи їх конфіденційність і захист від неправомірного або випадкового доступу до них третіх осіб.</li>
                        <li>
                            <span>Інтернет-магазин «IQ PIZZA» має право передати персональну інформацію Користувача третім особам в наступних випадках:</span>
                            <ol>
                                <li>передача необхідна в рамках використання Користувачем певного Сервісу;</li>
                                <li>така передача відбувається в рамках продажу або іншої передачі бізнесу (повністю або в частині), при цьому до набувача переходять всі зобов'язання по дотриманню умов цієї Політики стосовно отриманої їм персональної інформації;</li>
                                <li>з метою забезпечення можливості захисту прав і законних інтересів власника Інтернет-магазину або третіх осіб у випадках, коли Користувач порушує оферту з Інтернет-магазином «IQ PIZZA»;</li>
                            </ol>
                        </li>
                        <li>Користувач погоджується з тим, що Адміністрація сайту має право передавати персональні дані третім особам, зокрема, кур'єрським службам, організаціям поштового зв'язку, операторам електрозв'язку, з метою виконання замовлення Користувача, оформленого на сайті Інтернет-магазину, включаючи доставку замовленої продукції, а також з метою інформування та привернення уваги Користувача про умови надання послуг, включаючи рекламні акції нової продукції.</li>
                        <li>При втраті або розголошенні персональних даних Адміністрація сайту інформує Користувача про втрату або розголошення персональних даних.</li>
                        <li>Адміністрація сайту вживає необхідних організаційних і технічних заходів для захисту персональної інформації Користувача від неправомірного або випадкового доступу, знищення, перекручення, блокування, копіювання, поширення, а також від інших неправомірних дій третіх осіб.</li>
                        <li>Адміністрація сайту спільно з Користувачем вживає всіх необхідних заходів щодо запобігання збиткам або інших негативних наслідків, викликаних втратою або розголошенням персональних даних Користувача.</li>
                        <li>Персональні дані Користувача можуть бути передані уповноваженим органам державної влади України тільки на підставах та в порядку, встановленим законодавством України.</li>
                        <li>При обробці персональних даних користувачів Інтернет-магазином «IQ PIZZA» керується Законом України "Про захист персональних даних" від 01.06.2010 № 2297-VI.</li>
                    </ol>
                </li>
                <li>
                    <h2>Зобов'язання Сторін</h2>
                    <ol>
                        <li>
                            <span>Адміністрація сайту зобов'язується:</span>
                            <ol>
                                <li>Використовувати отриману інформацію виключно для цілей, зазначених у п. 3 цієї Політики конфіденційності.</li>
                                <li>Забезпечити зберігання конфіденційної інформації в таємниці, не розголошувати без попередньої письмової згоди Користувача, а також не здійснювати продаж, обмін, опублікування, або розголошення іншими можливими способами переданих персональних даних Користувача, за винятком п.п. 4.3 і 4.7 цієї Політики Конфіденційності.</li>
                                <li>Вживати заходів обережності для захисту конфіденційності персональних даних Користувача згідно з порядком, який зазвичай використовується для захисту такого роду інформації в існуючому діловому обороті.</li>
                                <li>Здійснити блокування персональних даних, що відносяться до відповідного Користувача, з моменту звернення або запиту Користувача або його законного представника або уповноваженого органу з захисту прав суб'єктів персональних даних на період перевірки, в разі виявлення недостовірних персональних даних або неправомірних дій.</li>
                            </ol>
                        </li>
                        <li>
                            <span>Користувач зобов'язується:</span>
                            <ol>
                                <li>Надати інформацію про персональні дані, необхідну для користування сайтом Інтернет-магазину.</li>
                                <li>Оновити, доповнити надану інформацію про персональні дані в разі зміни даної інформації.</li>
                            </ol>
                        </li>
                    </ol>
                </li>
                <li>
                    <h2>Додаткові умови.</h2>
                    <ol>
                        <li>Інтернет-магазин «IQ PIZZA» має право вносити зміни в справжню Політику без згоди Користувача. При внесенні змін до актуальної редакції вказується дата останнього оновлення. Користувач зобов'язується самостійно контролювати наявність змін в цій Політиці.</li>
                        <li>Нова редакція Політики вступає в силу з моменту її розміщення на Сайті інтернет - магазину, якщо інше не передбачено новою редакцією Політики.</li>
                        <li>Діюча Політика конфіденційності розміщена на сторінці за адресою: https://iqpizza.com.ua/polityka-konfidencijnosti/</li>
                    </ol>
                </li>
            </ol>
        </div>
    );
}

export default ArticleUa;