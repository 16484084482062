import React from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";

import ArticleRu from './article/ru';
import ArticleUk from './article/uk';

import s from './publichna-oferta.module.css';

const Blog = () => {
    const { t, i18n } = useTranslation(); 
    const lng = useSelector(state => state.menu.languageMenu);
    const baseUrl = window.origin;
    const isHomePage = useSelector(state => state.menu.isHomePage);
    const host = !isHomePage ? window.location.host.split('.')[0] + '.': '';
    const canonicalUrl = lng === 'uk' ? `https://${host}iqpizza.com.ua/polityka-konfidencijnosti/` : `https://${host}iqpizza.com.ua/ru/politika-konfidencialnosti/`;
    // let url = lng === 'uk' ? `${baseUrl}/polityka-konfidencijnosti/` : `${baseUrl}/ru/politika-konfidencialnosti/`;
    return (
        <div className={s.aboutUs}>
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="robots" content="index, follow"/>
                <title>{t('privacyPolicyTitle')}</title>
                <meta name="description" content={''} />
                <link rel="canonical" href={canonicalUrl} />
                <link rel="alternate" hreflang="x-default" href={`https://${host}iqpizza.com.ua/polityka-konfidencijnosti/`} />
                <link rel="alternate" hreflang="ru" href={`https://${host}iqpizza.com.ua/ru/politika-konfidencialnosti/`} />
            </Helmet>
            <div className="container">
                <div className="row">
                    {lng === 'uk' ? <ArticleUk /> : <ArticleRu />}
                </div>
            </div>
        </div>
    )
}

export default Blog;