import { transliterate as tr, slugify } from 'transliteration';

import {actions} from '../API/';
import firebase from '../API/firebase/firebase';
import { getFirebase } from '../redux/firebase';

const UPDATE_ISHOME_PAGE = 'UPDATE_ISHOME_PAGE';
const UPDATE_MENU = 'UPDATE-MENU';
const UPDATE_PRODUCT = 'UPDATE-PRODUCT';
const DELETE_PRODUCT = 'DELETE-PRODUCT';
const UPDATE_LANGUAGE = 'UPDATE-LANGUAGE';
const REMOVE_PRODUCT = 'REMOVE-PRODUCT';
const UPDATE_ACTIVE_CITY = 'UPDATE-ACTIVE-CITY';
const UPDATE_DATA = 'UPDATE-DATA';
const UPDATE_STR = 'UPDATE_STR';
const UPDATE_MODIFIRE = 'UPDATE_MODIFIRE';
const UPDATE_GROUP_MODIFIRE = 'UPDATE-GROUP-MODIFIRE';
const REMOVE_MODIFIRE = 'REMOVE-MODIFIRE';
const REMOVE_GROUP_MODIFIRE = 'REMOVE-GROUP-MODIFIRE';
const REMOVE_MODIFIERS_ON_PRODUCT = 'REMOVE-MODIFIERS-ON-PRODUCT';

let initialState = {
    isHomePage: false,
    basket: [],
    basketCount: 0,
    products: [],
    categories: [],
    languageMenu: 'uk',
    cities: [
        {
            name: {uk: 'Дніпро', ru: 'Днепр'},
            id: '6bf17b9e-3ecd-0491-0179-0e815f2c8b26',
            location: {lat: 48.470991, lng: 35.030586},
            url: 'https://dnipro.iqpizza.com.ua/',
            terminals: [
                {
                    id: '5f15a30f-9045-8630-016f-3782d0ab0e7e',
                    name: {uk: 'пр. Героїв, 3 (біля ТЦ Кодак)', ru: 'пр. Героев, 3 (возле ТЦ Кодак)'},
                    time: '10:00 - 22:00'
                },
                {
                    id: 'dc818615-28d7-520f-0170-2e15cd4e180a',
                    name: {uk: 'вул. Липинського (Ширшова), 5', ru: 'ул. Липинского (Ширшова), 5'},
                    time: '09:00 - 21:00'
                },
                // {
                //     id: '1c4585bd-9e6e-80e5-016f-cc85edbe24c7',
                //     name: {uk: 'вул. Нижньодніпровська, 17', ru: 'ул. Нижнеднепровская, 17'},
                //     time: '10:00 - 22:00'
                // },
                {
                    id: 'b57a9e94-6a9c-242f-0177-2953563dc0b9',
                    name: {uk: 'вул. Богдана Хмельницького 135', ru: 'ул. Богдана Хмельницкого 135'},
                    time: '10:00 - 22:00'
                },
                {
                    id: '7fdb7bc5-126e-3cbc-0177-c9d2762d26e4',
                    name: {uk: 'вул. Казакевича, 5', ru: 'ул. Казакевича, 5'},
                    time: '10:00 - 22:00'
                },
                {
                    id: '24ccbe08-9595-07a8-0178-266a2791e822',
                    name: {uk: 'Набережна Заводська 99', ru: 'Набережная Заводская 99'},
                    time: '10:00 - 22:00'
                },
                // {
                //     id: '6bf17b9e-3ecd-0491-0178-88c1d1d76951',
                //     name: {uk: 'пр.Героїв-23', ru: 'пр.Героев-23'},
                //     time: '10:00 - 22:00'
                // },
                {
                    id: '6bf17b9e-3ecd-0491-0178-ada63bfbd66a',
                    name: {uk: 'вул. Робоча 77', ru: 'ул. Робоча 77'},
                    time: '10:00 - 22:00'
                },
                {
                    id: '7fdb7bc5-126e-3cbc-0177-aa97b854fb25',
                    name: {uk: 'пр. Слобожанський 16Б', ru: 'пр. Слобожанський 16Б'},
                    time: '10:00 - 22:00'
                },
                {
                    id: 'b57a9e94-6a9c-242f-0176-fb11d04f7630',
                    name: {uk: 'пр-т Ивана Мазепы 58', ru: 'пр-т Івана Мазепи 58'},
                    time: '10:00 - 22:00'
                },
                // {
                //     id: '6bf17b9e-3ecd-0491-0178-daa0bcef9867',
                //     name: {uk: 'бул. Платонова, 3/а', ru: 'дом. Платонова, 3/а'},
                //     time: '10:00 - 22:00'
                // },
                {
                    id: '6d051202-f3e7-c1cf-017a-9b47944a7690',
                    name: {uk: 'Сухомлинського 44', ru: 'Сухомлинського 44'},
                    time: '10:00 - 22:00'
                },
                // {
                //     id: '6d051202-f3e7-c1cf-017a-c90633be77ac',
                //     name: {uk: 'вул. Тітова 27', ru: 'ул. Титова, 27'},
                //     time: '10:00 - 22:00'
                // },
                {
                    id: '1281c67b-89d5-2f0d-017b-7c2664efb42e',
                    name: {uk: 'просп. Миру 55', ru: 'просп. Мира 55'},
                    time: '10:00 - 22:00'
                },
                {
                    id: '1281c67b-89d5-2f0d-017b-303f9ec8ab8f',
                    name: {uk: 'вул. Сєвєродонецька 1А', ru: 'ул. Сєвєродонецька 1А'},
                    time: '10:00 - 22:00'
                },
                {
                    id: '5cb711a9-2f65-281d-017c-7391f2b79c94',
                    name: {uk: 'вул. Донецьке шосе, 121', ru: 'ул.Донецкое шоссе, 121'},
                    time: '10:00 - 22:00'
                },
                {
                    id: '6e51abab-7486-8981-017c-dac01c7b75f4',
                    name: {uk: 'вул. Академіка Образцова, 2Б', ru: 'ул. Академика Образцова, 2Б'},
                    time: '10:00 - 22:00'
                },
                {
                    id: 'c18ec5c8-265a-b7f8-017d-a0af9c86e507',
                    name: {uk: 'Днепр, пр. Слобожанский 73', ru: 'Днепр, пр. Слобожанский 73'},
                    time: '10:00 - 22:00'
                },
                // {
                //     id: '1281c67b-89d5-2f0d-017b-303f9ec8ab8f',
                //     name: {uk: 'вул. Романовського, 73', ru: 'ул. Романовського, 73'},
                //     time: '10:00 - 22:00'
                // }
            ],
            map: 'https://www.google.com/maps/d/embed?mid=1hxRhf1cFp6lHA_vXgP7ZRQzYbZbo3v0E',
            typePayDelivery: [
                {
                    name: 'cash',
                    id: '09322f46-578a-d210-add7-eec222a08871'
                },
                {
                    name: 'card',
                    id: '692b19c9-735e-0558-bc8e-7db97f1bed79'
                },
                {
                    name: 'online',
                    id: '32c4fa2f-066d-4fbf-be6a-1ca1273cb631'
                }
            ],
            typePay: [
                {
                    name: 'cash',
                    id: '09322f46-578a-d210-add7-eec222a08871'
                },
                {
                    name: 'online',
                    id: '32c4fa2f-066d-4fbf-be6a-1ca1273cb631'
                }
            ],
            liqPay: {
                publicKey: 'i33316511358',
                privateKey: 'LBGyn2TzQHSEXVtXi6ocxHVNKELqUPQtkqCqiw0M'
            }
        },
        {
            name: {uk: 'Запоріжжя', ru: 'Запорожье'},
            id: 'fe74eb1c-055f-4767-b1e6-1a8d7df54070',
            url: 'https://zp.iqpizza.com.ua/',
            location: {lat: 46.479298, lng: 30.737393},
            terminals: [
                {
                    id: '40462116-2546-e586-016f-39b82a6f18c5',
                    name: {uk: 'Ентузіастів, 2', ru: 'Энтузиастов, 2'},
                    time: '10:00 - 22:00'
                },
                {
                    id: '394ea9b3-d070-b419-0174-ff4345b60de6',
                    name: {uk: 'вул. Космічна, 124', ru: 'ул. Космическая, 124'},
                    time: '10:00 - 22:00'
                },
                {
                    id: '1c4585bd-9e6e-80e5-016f-ec212b35178a',
                    name: {uk: 'вул. Новокузнецька, 12', ru: 'ул. Новокузнецкая, 12'},
                    time: '10:00 - 22:00'
                },
                {
                    id: '1c4585bd-9e6e-80e5-016f-ec212b3544b4',
                    name: {uk: 'просп. Соборний, 53', ru: 'просп. Соборный, 53'},
                    time: '08:00 - 22:00'
                },
                {
                    id: '1757727d-b3a1-3908-0170-7c6a0a0a5c2d',
                    name: {uk: 'бульв. Будівельників, 4', ru: 'бульв. Строителей, 4'},
                    time: '10:00 - 22:00'
                },
                {
                    id: '1bca9676-4b1d-ef6c-017e-e8254877bdc8',
                    name: {uk: 'вул. Чарівна 68', ru: 'ул. Чаривная 68'},
                    time: '10:00 - 22:00'
                },
                {
                    id: 'bbd19b78-31fa-0e0a-0171-7d5be9e0d494',
                    name: {uk: 'вул. Руставі, 1Д', ru: 'ул. Рустави, 1Д'},
                    time: '10:00 - 22:00'
                },
                {
                    id: 'f4235620-cc01-0d8a-0173-53a7e7c7aeda',
                    name: {uk: 'вул. Патріотична 68', ru: 'ул. Патриотическая 68'},
                    time: '10:00 - 22:00'
                },
                {
                    id: '61516728-bc72-890a-0173-e18282b07bc6',
                    name: {uk: 'вул. Рекордна, 6А', ru: 'ул. Рекордная, 6А'},
                    time: '10:00 - 22:00'
                },
                {
                    id: 'ddee3274-6cc1-040a-0171-55d3dbb56c00',
                    name: {uk: 'вул. Іванова, 2', ru: 'ул. Иванова, 2'},
                    time: '10:00 - 22:00'
                },
                {
                    id: '6d051202-f3e7-c1cf-017a-c4cc3998c01f',
                    name: {uk: 'ул. Деповська 126', ru: 'ул. Деповская 126'},
                    time: '08:00 - 22:00'
                },
                {
                    id: '48f0c6bb-57da-6be2-0176-28fdf12f2bf3',
                    name: {uk: 'вул. Чуйкова 29А', ru: 'ул. Чуйкова 29А'},
                    time: '10:00 - 22:00'
                },
                {
                    id: 'b57a9e94-6a9c-242f-0176-cd95872435c0',
                    name: {uk: 'вул. Українська 2Ж', ru: 'ул. Украинская 2Ж'},
                    time: '10:00 - 22:00'
                },
                {
                    id: 'bf170895-d1d5-2927-0178-03cbca6bb9f6',
                    name: {uk: 'вул. Чарівна 129', ru: 'ул. Чаривна, 129'},
                    time: '10:00 - 22:00'
                },
                {
                    id: 'b57a9e94-6a9c-242f-0176-e266f724611b',
                    name: {uk: 'вул. Ладожська 18В', ru: 'вул. Ладожська 18В'},
                    time: '10:00 - 22:00'
                },
                {
                    id: '3e1a87e2-fadb-8ca8-0179-eb6cc2633e72',
                    name: {uk: 'вул. Чумаченка 25В', ru: 'вул. Чумаченка 25В'},
                    time: '10:00 - 22:00'
                },
                {
                    id: '6e51abab-7486-8981-017c-cc2e57bd8c21',
                    name: {uk: 'вул. Спартака Маковского 3', ru: 'ул. Спартака Маковского 3'},
                    time: '10:00 - 22:00'
                },
                {
                    id: '1fec3b26-0318-45f8-017e-2ecef5985441',
                    name: {uk: 'вул. Сталеварів, 32', ru: 'ул. Сталеварів, 32'},
                    time: '10:00 - 22:00'
                },
                {
                    id: '1fec3b26-0318-45f8-017e-4b07cd729b7a',
                    name: {uk: 'вул. Північно-Кільцева 15', ru: 'ул. Северо-Кольцевая 15'},
                    time: '10:00 - 22:00'
                }
            ],
            map: 'https://www.google.com/maps/d/embed?mid=1wzd0Ulrq4WDly2h3wjrOS-l2FYbnFUhv',
            typePayDelivery: [
                {
                    name: 'cash',
                    id: '09322f46-578a-d210-add7-eec222a08871'
                },
                {
                    name: 'card',
                    id: '692b19c9-735e-0558-bc8e-7db97f1bed79'
                }
            ],
            typePay: [
                {
                    name: 'cash',
                    id: '09322f46-578a-d210-add7-eec222a08871'
                }
            ],
            liqPay: {
                publicKey: 'i33316511358',
                privateKey: 'LBGyn2TzQHSEXVtXi6ocxHVNKELqUPQtkqCqiw0M'
            }
        },
        {
            name: {uk: 'Київ', ru: 'Киев'},
            id: '6bf17b9e-3ecd-0491-0179-0e815f2c9df9',
            url: 'http://kyiv.iqpizza.com.ua/',
            location: {lat: 50.4708, lng: 30.520296},
            terminals: [
                {
                    id: '4bacfccf-6fff-ae65-016f-6b3345220a97',
                    name: {uk: 'вул. Радунська, 40', ru: 'вул. Радунська, 40'},
                    time: '10:00 - 22:00'
                },
                {
                    id: 'a6c8f81a-82de-44df-0171-1c5f576de425',
                    name: {uk: 'пр. Леся Курбаса, 6-Г (Борщагівка)', ru: 'пр. Леся Курбаса, 6-Г (Борщаговка)'},
                    time: '10:00 - 22:00'
                },
                {
                    id: '3e1a87e2-fadb-8ca8-0179-cd0ed00c2c88',
                    name: {uk: 'просп. Маяковського, 89 (Троєщина)', ru: 'просп. Маяковского, 89 (Троещина)'},
                    time: '10:00 - 22:00'
                },
                {
                    id: '6d051202-f3e7-c1cf-017a-c35515bde202',
                    name: {uk: 'вул. Ревуцького, 12', ru: 'ул. Ревуцкого, 12'},
                    time: '10:00 - 22:00'
                },
                {
                    id: '916f1a24-d8ef-4699-0172-c141a3ebb080',
                    name: {uk: 'вул. Каштанова 7', ru: 'ул. Каштановая, 7'},
                    time: '10:00 - 22:00'
                },
                {
                    id: '5a7d96cc-c896-d0f5-0170-b0a07f1d9deb',
                    name: {uk: 'вул. Бальзака, 66 (Троєщина)', ru: 'ул. Бальзака, 66  (Троещина)'},
                    time: '10:00 - 22:00'
                },
                {
                    id: '6d051202-f3e7-c1cf-017a-a9bc9c053d46',
                    name: {uk: 'вул. Доброхотова 11', ru: 'ул. Академика Доброхотова, 11'},
                    time: '10:00 - 22:00'
                },
                {
                    id: 'dfe338b7-94b7-f381-0175-5be6e8103ffa',
                    name: {uk: 'вул. Драйзера 8', ru: 'ул.Т.Драйзера, 8'},
                    time: '10:00 - 22:00'
                },
                {
                    id: '1bca9676-4b1d-ef6c-017e-f8df299db0e7',
                    name: {uk: 'пр.Минский, 4', ru: 'пр.Минский, 4'},
                    time: '10:00 - 22:00'
                },
                {
                    id: '1bca9676-4b1d-ef6c-017e-e577b6e96b6c',
                    name: {uk: 'вул. Григоренко, 24', ru: 'вул. Григоренко, 24'},
                    time: '10:00 - 22:00'
                },
                // {
                //     id: 'dfe338b7-94b7-f381-0175-5be6e8103ffa',
                //     name: {uk: 'просп. Маяковського, 75 (Троєщина)', ru: 'просп. Маяковского, 75 (Троещина)'},
                //     time: '10:00 - 22:00'
                // }
            ],
            map: 'https://www.google.com/maps/d/embed?mid=1Uv6g6toabJm_L8-9Ft14yjaCj33dV3_O',
            typePayDelivery: [
                {
                    name: 'cash',
                    id: '09322f46-578a-d210-add7-eec222a08871'
                },
                {
                    name: 'card',
                    id: '692b19c9-735e-0558-bc8e-7db97f1bed79'
                },
                {
                    name: 'online',
                    id: '32c4fa2f-066d-4fbf-be6a-1ca1273cb631'
                }
            ],
            typePay: [
                {
                    name: 'cash',
                    id: '09322f46-578a-d210-add7-eec222a08871'
                },
                {
                    name: 'online',
                    id: '32c4fa2f-066d-4fbf-be6a-1ca1273cb631'
                }
            ],
            liqPay: {
                publicKey: 'i33316511358',
                privateKey: 'LBGyn2TzQHSEXVtXi6ocxHVNKELqUPQtkqCqiw0M'
            }
        },
        {
            name: {uk: 'Бердянськ', ru: 'Бердянск'},
            id: '6bf17b9e-3ecd-0491-0179-0e815f2c8027',
            url: 'http://berdyansk.iqpizza.com.ua/',
            location: {lat: 46.774014, lng: 36.792992},
            terminals: [
                {
                    id: 'd1f424c1-8c87-dc8b-017d-3749fd5fa204',
                    name: {uk: 'просп. Праці 31Е', ru: 'просп. Труда 31Е'},
                    time: '10:00 - 22:00'
                },
                {
                    id: '6d051202-f3e7-c1cf-017a-9ff3be147a93',
                    name: {uk: 'шосе Мелітопольське, 114-в', ru: 'шоссе  Мелитопольское, 114-в'},
                    time: '10:00 - 22:00'
                },
                {
                    id: 'a39f97e0-bcd6-12c3-017d-ce257e9c2191',
                    name: {uk: 'вул. В. Довганюка, 64/26', ru: 'ул. В. Довганюка, 64/26'},
                    time: '10:00 - 22:00'
                },
            ],
            map: 'https://www.google.com/maps/d/embed?mid=1Tn3m0LlFb6rVUaSaJ6EGI53wl4C6V-4L',
            typePayDelivery: [
                {
                    name: 'cash',
                    id: '09322f46-578a-d210-add7-eec222a08871'
                },
                {
                    name: 'card',
                    id: '692b19c9-735e-0558-bc8e-7db97f1bed79'
                },
                {
                    name: 'online',
                    id: '32c4fa2f-066d-4fbf-be6a-1ca1273cb631'
                }
            ],
            typePay: [
                {
                    name: 'cash',
                    id: '09322f46-578a-d210-add7-eec222a08871'
                },
                {
                    name: 'online',
                    id: '32c4fa2f-066d-4fbf-be6a-1ca1273cb631'
                }
            ],
            liqPay: {
                publicKey: 'i33316511358',
                privateKey: 'LBGyn2TzQHSEXVtXi6ocxHVNKELqUPQtkqCqiw0M'
            }
        },
        {
            name: {uk: 'Новомосковськ', ru: 'Новомосковск'},
            id: '6bf17b9e-3ecd-0491-0179-0e815f2cc7c8',
            url: 'http://novomoskovsk.iqpizza.com.ua/',
            location: {lat: 48.634567, lng: 35.223404},
            terminals: [
                {
                    id: '1c4585bd-9e6e-80e5-016f-cc85edbe9285',
                    name: {uk: 'вул. Гетьманська, 32А', ru: 'вул. Гетьманська, 32А'},
                    time: '10:00 - 22:00'
                },
            ],
            map: 'https://www.google.com/maps/d/embed?mid=1GTY9ZHYEqIEouf-lVPaaiO8F5WHhw5OM',
            typePayDelivery: [
                {
                    name: 'cash',
                    id: '09322f46-578a-d210-add7-eec222a08871'
                },
                {
                    name: 'card',
                    id: '692b19c9-735e-0558-bc8e-7db97f1bed79'
                },
                {
                    name: 'online',
                    id: '32c4fa2f-066d-4fbf-be6a-1ca1273cb631'
                }
            ],
            typePay: [
                {
                    name: 'cash',
                    id: '09322f46-578a-d210-add7-eec222a08871'
                },
                {
                    name: 'online',
                    id: '32c4fa2f-066d-4fbf-be6a-1ca1273cb631'
                }
            ],
            liqPay: {
                publicKey: 'i33316511358',
                privateKey: 'LBGyn2TzQHSEXVtXi6ocxHVNKELqUPQtkqCqiw0M'
            }
        },
        {
            name: {uk: 'Мелітополь', ru: 'Мелитополь'},
            id: '6bf17b9e-3ecd-0491-0179-0e815f2cc139',
            url: 'http://melitopol.iqpizza.com.ua/',
            location: {lat: 48.634567, lng: 35.223404},
            terminals: [
                {
                    id: 'dfe338b7-94b7-f381-0175-113e56b7ab36',
                    name: {uk: 'б-р 30-річчя Перемоги, 1 А', ru: 'б-р 30-летия Победы, 1 А'},
                    time: '09:00 - 22:00'
                },
                {
                    id: '5a7d96cc-c896-d0f5-0170-a70eae87e470',
                    name: {uk: 'вул. Університетська, 6/1', ru: 'ул. Университетская, 6/1'},
                    time: '09:00 - 21:00'
                },
                {
                    id: '3e1a87e2-fadb-8ca8-0179-c78893e463fe',
                    name: {uk: 'пр. Героїв України 48', ru: 'пр. Героїв України 48'},
                    time: '09:00 - 22:00'
                },
            ],
            map: 'https://www.google.com/maps/d/embed?mid=1Zx-eiKNIzD1x-ye3okyooL0TISrj47jB',
            typePayDelivery: [
                {
                    name: 'cash',
                    id: '09322f46-578a-d210-add7-eec222a08871'
                },
                {
                    name: 'card',
                    id: '692b19c9-735e-0558-bc8e-7db97f1bed79'
                },
                {
                    name: 'online',
                    id: '32c4fa2f-066d-4fbf-be6a-1ca1273cb631'
                }
            ],
            typePay: [
                {
                    name: 'cash',
                    id: '09322f46-578a-d210-add7-eec222a08871'
                },
                {
                    name: 'online',
                    id: '32c4fa2f-066d-4fbf-be6a-1ca1273cb631'
                }
            ],
            liqPay: {
                publicKey: 'i33316511358',
                privateKey: 'LBGyn2TzQHSEXVtXi6ocxHVNKELqUPQtkqCqiw0M'
            }
        },
        {
            name: {uk: 'Енергодар', ru: 'Энергодар'},
            id: '50cf0215-f3a4-4f8c-8905-676ff787cb69',
            url: 'http://energodar.iqpizza.com.ua/',
            location: {lat: 47.501604, lng: 34.633021},
            terminals: [
                {
                    id: '394ea9b3-d070-b419-0174-ff4345b60e1e',
                    name: {uk: 'Енергодар вул. Козацька, 18В', ru: 'Энергодар вул. Козацкая, 18В'},
                    time: '10:00 - 22:00'
                },
            ],
            map: 'https://www.google.com/maps/d/embed?mid=1u4WFQcNz05-KUTMhMCXf8_zuy2bLS_l9',
            typePayDelivery: [
                {
                    name: 'cash',
                    id: '09322f46-578a-d210-add7-eec222a08871'
                },
                {
                    name: 'card',
                    id: '692b19c9-735e-0558-bc8e-7db97f1bed79'
                },
                {
                    name: 'online',
                    id: '32c4fa2f-066d-4fbf-be6a-1ca1273cb631'
                }
            ],
            typePay: [
                {
                    name: 'cash',
                    id: '09322f46-578a-d210-add7-eec222a08871'
                },
                {
                    name: 'online',
                    id: '32c4fa2f-066d-4fbf-be6a-1ca1273cb631'
                }
            ],
            liqPay: {
                publicKey: 'i33316511358',
                privateKey: 'LBGyn2TzQHSEXVtXi6ocxHVNKELqUPQtkqCqiw0M'
            }
        },
        {
            name: {uk: 'Павлоград', ru: 'Павлоград'},
            id: '417177e7-cbf6-4039-ac09-9e94d8fea689',
            url: 'http://pavlograd.iqpizza.com.ua/',
            location: {lat: 48.531794, lng: 35.882366},
            terminals: [
                {
                    id: '1281c67b-89d5-2f0d-017a-f65b77059e87',
                    name: {uk: 'вул. Шевченка, 132А', ru: 'ул. Шевченка, 132А'},
                    time: '09:00 - 21:00'
                },
                {
                    id: '0fcd97e9-e317-2cd8-017d-0ead6601e830',
                    name: {uk: 'вул Соборна 97', ru: 'ул Соборная 97'},
                    time: '09:00 - 21:00'
                },
                {
                    id: '1fec3b26-0318-45f8-017e-10ef5258bd6d',
                    name: {uk: 'вул. Гагаріна, 12', ru: 'ул. Гагаріна, 12'},
                    time: '09:00 - 21:00'
                },
                {
                    id: '10d5590d-a790-411a-017e-c60b3a66d869',
                    name: {uk: 'вул. Челюскінців, 14а', ru: 'ул. Челюскинцов, 14а'},
                    time: '09:00 - 21:00'
                },
            ],
            map: 'https://www.google.com/maps/d/embed?mid=157YB8Osc9KdkwxC4NvsqUJ7rCw4w32F_',
            typePayDelivery: [
                {
                    name: 'cash',
                    id: '09322f46-578a-d210-add7-eec222a08871'
                },
                {
                    name: 'card',
                    id: '692b19c9-735e-0558-bc8e-7db97f1bed79'
                },
                {
                    name: 'online',
                    id: '32c4fa2f-066d-4fbf-be6a-1ca1273cb631'
                }
            ],
            typePay: [
                {
                    name: 'cash',
                    id: '09322f46-578a-d210-add7-eec222a08871'
                },
                {
                    name: 'online',
                    id: '32c4fa2f-066d-4fbf-be6a-1ca1273cb631'
                }
            ],
            liqPay: {
                publicKey: 'i33316511358',
                privateKey: 'LBGyn2TzQHSEXVtXi6ocxHVNKELqUPQtkqCqiw0M'
            }
        },
        {
            name: {uk: 'Токмак', ru: 'Токмак'},
            id: '26980b21-5caf-4298-b933-ce094cc10ac0',
            url: 'http://tokmak.iqpizza.com.ua/',
            location: {lat: 47.253606, lng: 35.708273},
            terminals: [
                {
                    id: 'dfe338b7-94b7-f381-0175-21cb151ab977',
                    name: {uk: 'вул. Центральна 42', ru: 'ул. Центральная'},
                    time: '09:00 - 21:00'
                },
            ],
            map: 'https://www.google.com/maps/d/embed?mid=1-O3e-LrqRBF8grH1gyMtVS352GRL0atR',
            typePayDelivery: [
                {
                    name: 'cash',
                    id: '09322f46-578a-d210-add7-eec222a08871'
                },
                {
                    name: 'card',
                    id: '692b19c9-735e-0558-bc8e-7db97f1bed79'
                },
                {
                    name: 'online',
                    id: '32c4fa2f-066d-4fbf-be6a-1ca1273cb631'
                }
            ],
            typePay: [
                {
                    name: 'cash',
                    id: '09322f46-578a-d210-add7-eec222a08871'
                },
                {
                    name: 'online',
                    id: '32c4fa2f-066d-4fbf-be6a-1ca1273cb631'
                }
            ],
            liqPay: {
                publicKey: 'i33316511358',
                privateKey: 'LBGyn2TzQHSEXVtXi6ocxHVNKELqUPQtkqCqiw0M'
            }
        },
        {
            name: {uk: 'Вільнянськ', ru: 'Вольнянск'},
            id: '924a64d7-6405-4847-a07b-7ec3d077e9be',
            url: 'http://vilnyansk.iqpizza.com.ua/',
            location: {lat: 47.94417049244426, lng: 35.43362511782855},
            terminals: [
                {
                    id: 'dfe338b7-94b7-f381-0175-702c43a5f401',
                    name: {uk: 'пер. Торгівельний 11', ru: 'пер. Торговый, 11'},
                    time: '08:00 - 22:00'
                },
            ],
            map: 'https://www.google.com/maps/d/embed?mid=18SKwAv1zSTaNa5DCb5qhuRJPmTcrAwqs',
            typePayDelivery: [
                {
                    name: 'cash',
                    id: '09322f46-578a-d210-add7-eec222a08871'
                },
                {
                    name: 'card',
                    id: '692b19c9-735e-0558-bc8e-7db97f1bed79'
                },
                {
                    name: 'online',
                    id: '32c4fa2f-066d-4fbf-be6a-1ca1273cb631'
                }
            ],
            typePay: [
                {
                    name: 'cash',
                    id: '09322f46-578a-d210-add7-eec222a08871'
                },
                {
                    name: 'online',
                    id: '32c4fa2f-066d-4fbf-be6a-1ca1273cb631'
                }
            ],
            liqPay: {
                publicKey: 'i33316511358',
                privateKey: 'LBGyn2TzQHSEXVtXi6ocxHVNKELqUPQtkqCqiw0M'
            }
        },
        {
            name: {uk: 'Дніпрорудне', ru: 'Днепрорудное'},
            id: '716229c9-6a8f-4cf9-8a9e-99de17ae4c18',
            url: 'http://dniprorydne.iqpizza.com.ua/',
            location: {lat: 47.38974411299344, lng: 35.00401988270544},
            terminals: [
                {
                    id: '2d4fb3ea-6aba-6260-0176-6c766ed418c0',
                    name: {uk: 'Дніпрорудне вул. Ентузіастів 24', ru: 'Днепрорудное вул. Ентузіастів 24'},
                    time: '10:00 - 22:00'
                },
            ],
            map: 'https://www.google.com/maps/d/embed?mid=1y_X-mr-_DhTB3NWAkvyXsSVniN9qV_BE',
            typePayDelivery: [
                {
                    name: 'cash',
                    id: '09322f46-578a-d210-add7-eec222a08871'
                },
                {
                    name: 'card',
                    id: '692b19c9-735e-0558-bc8e-7db97f1bed79'
                },
                {
                    name: 'online',
                    id: '32c4fa2f-066d-4fbf-be6a-1ca1273cb631'
                }
            ],
            typePay: [
                {
                    name: 'cash',
                    id: '09322f46-578a-d210-add7-eec222a08871'
                },
                {
                    name: 'online',
                    id: '32c4fa2f-066d-4fbf-be6a-1ca1273cb631'
                }
            ],
            liqPay: {
                publicKey: 'i33316511358',
                privateKey: 'LBGyn2TzQHSEXVtXi6ocxHVNKELqUPQtkqCqiw0M'
            }
        },
        {
            name: {uk: 'Пологи', ru: 'Пологи'},
            id: '3f8bb8cf-f815-493d-a0ac-67f64f2329bd',
            url: 'http://pology.iqpizza.com.ua/',
            location: {lat: 47.38974411299344, lng: 35.00401988270544},
            terminals: [
                {
                    id: 'bf170895-d1d5-2927-0178-03e10aaaf747',
                    name: {uk: 'Пологи, вул. Єдності 19', ru: 'Роды, ул. единства 19'},
                    time: '10:00 - 22:00'
                },
            ],
            map: 'https://www.google.com/maps/d/embed?mid=1mGqsbSJwVnf0VTjSOgNdPohW3p6FLK73',
            typePayDelivery: [
                {
                    name: 'cash',
                    id: '09322f46-578a-d210-add7-eec222a08871'
                },
                {
                    name: 'card',
                    id: '692b19c9-735e-0558-bc8e-7db97f1bed79'
                },
                {
                    name: 'online',
                    id: '32c4fa2f-066d-4fbf-be6a-1ca1273cb631'
                }
            ],
            typePay: [
                {
                    name: 'cash',
                    id: '09322f46-578a-d210-add7-eec222a08871'
                },
                {
                    name: 'online',
                    id: '32c4fa2f-066d-4fbf-be6a-1ca1273cb631'
                }
            ],
            liqPay: {
                publicKey: 'i33316511358',
                privateKey: 'LBGyn2TzQHSEXVtXi6ocxHVNKELqUPQtkqCqiw0M'
            }
        },
        {
            name: {uk: 'Гуляйполе', ru: 'Гуляйполе'},
            id: '5da9c4d0-aaf7-4b1c-85d0-8d8ee6cf7d46',
            url: 'http://huliaipole.iqpizza.com.ua/',
            location: {lat: 47.66114747635007, lng: 36.27039139893607},
            terminals: [
                {
                    id: '3e1a87e2-fadb-8ca8-0179-c83e26505041',
                    name: {uk: 'Базарная, д. 1', ru: 'Базарная, д. 1'},
                    time: '10:00 - 22:00'
                },
            ],
            map: 'https://www.google.com/maps/d/embed?mid=1sH6OGSPi2qpU1dGVYWLXUMqF3Rj7KWn_',
            typePayDelivery: [
                {
                    name: 'cash',
                    id: '09322f46-578a-d210-add7-eec222a08871'
                },
                {
                    name: 'card',
                    id: '692b19c9-735e-0558-bc8e-7db97f1bed79'
                },
                {
                    name: 'online',
                    id: '32c4fa2f-066d-4fbf-be6a-1ca1273cb631'
                }
            ],
            typePay: [
                {
                    name: 'cash',
                    id: '09322f46-578a-d210-add7-eec222a08871'
                },
                {
                    name: 'online',
                    id: '32c4fa2f-066d-4fbf-be6a-1ca1273cb631'
                }
            ],
            liqPay: {
                publicKey: 'i33316511358',
                privateKey: 'LBGyn2TzQHSEXVtXi6ocxHVNKELqUPQtkqCqiw0M'
            }
        },
        {
            name: {uk: 'Чернігів', ru: 'Чернигов'},
            id: '6bf17b9e-3ecd-0491-0179-0e815f2cf1aa',
            url: 'http://chernihyv.iqpizza.com.ua/',
            location: {lat: 51.5199576556136, lng: 31.230624850072193},
            terminals: [
                {
                    id: '48f0c6bb-57da-6be2-0175-f9d946d7741e',
                    name: {uk: 'вул. Незалежності 42/9', ru: 'ул.Независимости 42/9'},
                    time: '10:00 - 22:00'
                },
            ],
            map: 'https://www.google.com/maps/d/embed?mid=1FT-rYhePBXpBPLh-QYdGhr1omHy3G3HH',
            typePayDelivery: [
                {
                    name: 'cash',
                    id: '09322f46-578a-d210-add7-eec222a08871'
                },
                {
                    name: 'card',
                    id: '692b19c9-735e-0558-bc8e-7db97f1bed79'
                },
                {
                    name: 'online',
                    id: '32c4fa2f-066d-4fbf-be6a-1ca1273cb631'
                }
            ],
            typePay: [
                {
                    name: 'cash',
                    id: '09322f46-578a-d210-add7-eec222a08871'
                },
                {
                    name: 'online',
                    id: '32c4fa2f-066d-4fbf-be6a-1ca1273cb631'
                }
            ],
            liqPay: {
                publicKey: 'i33316511358',
                privateKey: 'LBGyn2TzQHSEXVtXi6ocxHVNKELqUPQtkqCqiw0M'
            }
        },
        {
            name: {uk: 'Першотравенск', ru: 'Першотравенск'},
            id: 'a8ec38d3-a1bd-4226-a527-2786bb44634b',
            url: 'http://pershotravensk.iqpizza.com.ua/',
            location: {lat: 51.5199576556136, lng: 31.230624850072193},
            terminals: [
                {
                    id: '1281c67b-89d5-2f0d-017b-102b7006fc80',
                    name: {uk: 'вул. Горького, 4В', ru: 'ул. Горького, 4'},
                    time: '10:00 - 22:00'
                },
            ],
            map: 'https://www.google.com/maps/d/embed?mid=1VsyOqnObHuI-lvk0R3CW5LIwEj1GtZgc',
            typePayDelivery: [
                {
                    name: 'cash',
                    id: '09322f46-578a-d210-add7-eec222a08871'
                },
                {
                    name: 'card',
                    id: '692b19c9-735e-0558-bc8e-7db97f1bed79'
                },
                {
                    name: 'online',
                    id: '32c4fa2f-066d-4fbf-be6a-1ca1273cb631'
                }
            ],
            typePay: [
                {
                    name: 'cash',
                    id: '09322f46-578a-d210-add7-eec222a08871'
                },
                {
                    name: 'online',
                    id: '32c4fa2f-066d-4fbf-be6a-1ca1273cb631'
                }
            ],
            liqPay: {
                publicKey: 'i33316511358',
                privateKey: 'LBGyn2TzQHSEXVtXi6ocxHVNKELqUPQtkqCqiw0M'
            }
        },
        {
            name: {uk: 'Кам\'янка-Дніпровська', ru: 'Каменка-Днепровская'},
            id: '6c4e0035-0ca0-4965-8e18-bbadb05e9775',
            url: 'http://kamenka.iqpizza.com.ua/',
            location: {lat: 51.5199576556136, lng: 31.230624850072193},
            terminals: [
                {
                    id: '1281c67b-89d5-2f0d-017b-59139d193f49',
                    name: {uk: 'вул. Центральная, 2', ru: 'ул. Центральная, 2'},
                    time: '10:00 - 22:00'
                },
            ],
            map: 'https://www.google.com/maps/d/embed?mid=1SVpwg027QU4JX_S8bfYjAmk0MoJyrK28',
            typePayDelivery: [
                {
                    name: 'cash',
                    id: '09322f46-578a-d210-add7-eec222a08871'
                },
                {
                    name: 'card',
                    id: '692b19c9-735e-0558-bc8e-7db97f1bed79'
                },
                {
                    name: 'online',
                    id: '32c4fa2f-066d-4fbf-be6a-1ca1273cb631'
                }
            ],
            typePay: [
                {
                    name: 'cash',
                    id: '09322f46-578a-d210-add7-eec222a08871'
                },
                {
                    name: 'online',
                    id: '32c4fa2f-066d-4fbf-be6a-1ca1273cb631'
                }
            ],
            liqPay: {
                publicKey: 'i33316511358',
                privateKey: 'LBGyn2TzQHSEXVtXi6ocxHVNKELqUPQtkqCqiw0M'
            }
        },
        {
            name: {uk: 'Кам\'янське', ru: 'Каменское'},
            id: '6bf17b9e-3ecd-0491-0179-0e815f2c9c1e',
            url: 'http://kamyanske.iqpizza.com.ua/',
            location: {lat: 51.5199576556136, lng: 31.230624850072193},
            terminals: [
                {
                    id: 'c3bad68f-0345-dad3-017b-e499702a85b1',
                    name: {uk: 'Кам\'янське пр. Свободи 2Л', ru: 'Каменское пр. Свободы 2Л'},
                    time: '10:00 - 22:00'
                },
            ],
            map: 'https://www.google.com/maps/d/embed?mid=1J48WkvP1UMJONvAwHTM9_7ehnReiTjOb',
            typePayDelivery: [
                {
                    name: 'cash',
                    id: '09322f46-578a-d210-add7-eec222a08871'
                },
                {
                    name: 'card',
                    id: '692b19c9-735e-0558-bc8e-7db97f1bed79'
                },
                {
                    name: 'online',
                    id: '32c4fa2f-066d-4fbf-be6a-1ca1273cb631'
                }
            ],
            typePay: [
                {
                    name: 'cash',
                    id: '09322f46-578a-d210-add7-eec222a08871'
                },
                {
                    name: 'online',
                    id: '32c4fa2f-066d-4fbf-be6a-1ca1273cb631'
                }
            ],
            liqPay: null
        },
        {
            name: {uk: 'Покровське', ru: 'Покровское'},
            id: '49328221-67fc-4e63-a74a-4afd996a36a4',
            url: 'http://pokrovsk.iqpizza.com.ua/',
            location: {lat: 51.5199576556136, lng: 31.230624850072193},
            terminals: [
                {
                    id: '6e51abab-7486-8981-017c-cce580880f11',
                    name: {uk: ' вул.Центральна,44', ru: ' ул.Центральная,44'},
                    time: '10:00 - 22:00'
                },
            ],
            map: 'https://www.google.com/maps/d/embed?mid=1oSbS11jbbNr6mku4_OSHN6QuAm40zmqK',
            typePayDelivery: [
                {
                    name: 'cash',
                    id: '09322f46-578a-d210-add7-eec222a08871'
                },
                {
                    name: 'card',
                    id: '692b19c9-735e-0558-bc8e-7db97f1bed79'
                },
                {
                    name: 'online',
                    id: '32c4fa2f-066d-4fbf-be6a-1ca1273cb631'
                }
            ],
            typePay: [
                {
                    name: 'cash',
                    id: '09322f46-578a-d210-add7-eec222a08871'
                },
                {
                    name: 'online',
                    id: '32c4fa2f-066d-4fbf-be6a-1ca1273cb631'
                }
            ],
            liqPay: null
        },
        {
            name: {uk: 'Марганець', ru: 'Марганец'},
            id: 'd1f424c1-8c87-dc8b-017d-7bd590589350',
            url: 'http://marganec.iqpizza.com.ua/',
            location: {lat: 51.5199576556136, lng: 31.230624850072193},
            terminals: [
                {
                    id: 'd1f424c1-8c87-dc8b-017d-70a6c77c9057',
                    name: {uk: 'вул. Бульварна 1', ru: 'ул. Бульварная 1'},
                    time: '10:00 - 22:00'
                },
            ],
            map: 'https://www.google.com/maps/d/embed?mid=1zSiK3Aup7fFuTXfTmmdIA6BUCJKGHSWy&ehbc=2E312F',
            typePayDelivery: [
                {
                    name: 'cash',
                    id: '09322f46-578a-d210-add7-eec222a08871'
                },
                {
                    name: 'card',
                    id: '692b19c9-735e-0558-bc8e-7db97f1bed79'
                },
                {
                    name: 'online',
                    id: '32c4fa2f-066d-4fbf-be6a-1ca1273cb631'
                }
            ],
            typePay: [
                {
                    name: 'cash',
                    id: '09322f46-578a-d210-add7-eec222a08871'
                },
                {
                    name: 'online',
                    id: '32c4fa2f-066d-4fbf-be6a-1ca1273cb631'
                }
            ],
            liqPay: null
        },
        {
            name: {uk: 'Нікополь', ru: 'Никополь'},
            id: '0c8397ce-f3b6-b0e6-017e-4e62ecc739dd',
            url: 'http://nikopol.iqpizza.com.ua/',
            location: {lat: 51.5199576556136, lng: 31.230624850072193},
            terminals: [
                {
                    id: 'a39f97e0-bcd6-12c3-017d-c7adcc4bf80f',
                    name: {uk: 'вул. Шевченко, 188', ru: 'ул. Шевченко, 188'},
                    time: '10:00 - 22:00'
                },
            ],
            map: 'https://www.google.com/maps/d/embed?mid=1H3JLRveFiMNpQ_dTVT2AiFxjyQwk64S6&hl=ru&ehbc=2E312F',
            typePayDelivery: [
                {
                    name: 'cash',
                    id: '09322f46-578a-d210-add7-eec222a08871'
                },
                {
                    name: 'card',
                    id: '692b19c9-735e-0558-bc8e-7db97f1bed79'
                },
                {
                    name: 'online',
                    id: '32c4fa2f-066d-4fbf-be6a-1ca1273cb631'
                }
            ],
            typePay: [
                {
                    name: 'cash',
                    id: '09322f46-578a-d210-add7-eec222a08871'
                },
                {
                    name: 'online',
                    id: '32c4fa2f-066d-4fbf-be6a-1ca1273cb631'
                }
            ],
            liqPay: null
        },
        {
            name: {uk: 'Михайлівка', ru: 'Михайловка'},
            id: 'ace87627-d363-404d-93c4-7ceab2ed7d97',
            url: 'http://mykhailivka.iqpizza.com.ua/',
            location: {lat: 51.5199576556136, lng: 31.230624850072193},
            terminals: [
                {
                    id: '1bca9676-4b1d-ef6c-017e-e8524c9ce59e',
                    name: {uk: 'вул. Святопокровська, 1А', ru: 'ул. Святопокровская, 1А'},
                    time: '10:00 - 22:00'
                },
            ],
            map: 'https://www.google.com/maps/d/embed?mid=10K7sVN5i3lUeU9xsurGbSxg6swJAIKxU&ehbc=2E312F',
            typePayDelivery: [
                {
                    name: 'cash',
                    id: '09322f46-578a-d210-add7-eec222a08871'
                },
                {
                    name: 'card',
                    id: '692b19c9-735e-0558-bc8e-7db97f1bed79'
                },
                {
                    name: 'online',
                    id: '32c4fa2f-066d-4fbf-be6a-1ca1273cb631'
                }
            ],
            typePay: [
                {
                    name: 'cash',
                    id: '09322f46-578a-d210-add7-eec222a08871'
                },
                {
                    name: 'online',
                    id: '32c4fa2f-066d-4fbf-be6a-1ca1273cb631'
                }
            ],
            liqPay: null
        },
    ],
    activeCity: null,
    data: null,
    streets: []
}

const Menu = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_MENU: {
            return {
                ...state,
                products: action.data.products.filter(element => element !== null),
                categories: action.data.categories
            }
        }
        case UPDATE_DATA: {
            return {
                ...state,
                data: action.data
            }
        }
        case UPDATE_LANGUAGE: {
            return {
                ...state,
                languageMenu: action.language
            }
        }
        case UPDATE_STR: {
            return {
                ...state,
                streets: action.data
            }
        }
        case UPDATE_PRODUCT:
            return {
                ...state,
                products: state.products.map(n => n['id'] === action.id
                ? 
                {
                    ...n,
                    count: Math.max(0, n.count === undefined || n.count === 0 ? 1 : n.count + (({
                    increment:  1,
                    decrement: -1,
                })[action.action]) || 0),
                }
                : n
                ),
                basketCount:  Math.max(0, state.basketCount + (({
                    increment:  state.products.find(n => n['id'] === action.id).count === undefined ||
                                state.products.find(n => n['id'] === action.id).count === 0 ||
                                state.products.find(n => n['id'] === action.id).count ===  null ? 1 : 0,
                    decrement: state.products.find(n => n['id'] === action.id).count === 1 ? -1 : 0,
                })[action.action]) || 0),
            }
            case UPDATE_MODIFIRE: {
                return {
                    ...state,
                products: state.products.map(n => n['id'] === action.id
                ? 
                {
                    ...n,
                    modifiers: n.modifiers.map(a => a['modifierId'] === action.modifierId
                    ? 
                    {
                        ...a,
                        amount: Math.max(0, a.amount === undefined || a.amount === 0 ? 1 : a.amount + (({
                        increment:  1,
                        decrement: -1,
                    })[action.action]) || 0),
                    }
                    : a
                    )
                }
                : n
                )
                }
            }
            case UPDATE_GROUP_MODIFIRE: {
                return {
                    ...state,
                products: state.products.map(n => n['id'] === action.id
                ? 
                {
                    ...n,
                    groupModifiers: n.groupModifiers.map(g => g['modifierId'] === action.groupModifiers
                    ? 
                    {
                        ...g,
                        childModifiers: g.childModifiers.map(c => c['modifierId'] === action.childModifiers
                        ? 
                        {
                            ...c,
                            amount: Math.max(0, c.amount === undefined || c.amount === 0 ? 1 : c.amount + (({
                            increment:  1,
                            decrement: -1,
                        })[action.action]) || 0),
                        }
                        : c
                        )
                    } : g)
                }
                : n
                )
                }
            }
            case DELETE_PRODUCT: {
                return {
                    ...state,
                    products: state.products.map(n => n['id'] === action.id ? 
                        {
                            ...n,
                            count: null,
                            modifiers: n.modifiers.map(m => {
                                return {
                                    ...m,
                                    amount: 0
                                }
                            }),
                            groupModifiers: n.groupModifiers.map(g => {
                                return {
                                    ...n,
                                    childModifiers: g.childModifiers.map(c => {
                                        return {
                                            ...c,
                                            amount: 0
                                        }
                                    })
                                }
                            })
                        }
                            : n),
                            basketCount: state.basketCount - 1}
            }
            case REMOVE_PRODUCT: {
                return {
                    ...state,
                    products: state.products.map(n => n['count'] > 0 ? 
                        {
                            ...n,
                            count: n.edit !== false ? null : 1,
                            modifiers: n.modifiers.map(m => {
                                return {
                                    ...m,
                                    amount: 0
                                }
                            }),
                            groupModifiers: n.groupModifiers.map(g => {
                                return {
                                    ...n,
                                    childModifiers: g.childModifiers.map(c => {
                                        return {
                                            ...c,
                                            amount: 0
                                        }
                                    })
                                }
                            })
                        }
                            : n),
                            basketCount: 0
                    }
            }
            
            case REMOVE_MODIFIRE: {
                return {
                    ...state,
                    products: state.products.map(n => n['id'] === action.id ? 
                        {
                            ...n,
                            modifiers: n.modifiers.map(a => a['modifierId'] === action.modifierId
                            ? 
                            {
                                ...a,
                                amount: 0
                            }
                            : a
                            )
                        }
                            : n),
                    }
            }
            case REMOVE_GROUP_MODIFIRE: {
                return {
                    ...state,
                    products: state.products.map(n => n['id'] === action.id ? 
                        {
                            ...n,
                            groupModifiers: n.groupModifiers.map(a => a['modifierId'] === action.groupModifiers
                            ? 
                            {
                                ...a,
                                childModifiers: a.childModifiers.map(c => c['modifierId'] === action.childModifiers
                                ? 
                                {
                                    ...c,
                                    amount: 0
                                } : c
                                )
                            }
                            : a
                            )
                        }
                            : n),
                    }
            }
            case UPDATE_ACTIVE_CITY: {
                return {
                    ...state,
                    activeCity: action.data
                }
            }
            case UPDATE_ISHOME_PAGE: {
                return {
                    ...state,
                    isHomePage: action.value
                }
            }
        default:
            return state
    }
}

export const updateIsHomePage = (value) => ({ type: UPDATE_ISHOME_PAGE, value });
export const updateMenu = (data) => ({type: UPDATE_MENU, data});
export const updateLanguage = (language) => ({type: UPDATE_LANGUAGE, language});

export const updateProduct = (action, id) => ({type: UPDATE_PRODUCT, action, id});
export const deleteProduct = (id) => ({type: DELETE_PRODUCT, id});
export const removeProduct = () => ({type: REMOVE_PRODUCT});
export const updateActiveCity = (data) => ({type: UPDATE_ACTIVE_CITY, data});
export const updateData = (data) => ({type: UPDATE_DATA, data});
export const updateStreets = (data) => ({type: UPDATE_STR, data});

export const updateModifire = (action, id, modifierId) => ({type: UPDATE_MODIFIRE, action, id, modifierId});
export const updateGroupModifire = (action, id, groupModifiers, childModifiers) => ({type: UPDATE_GROUP_MODIFIRE, action, id, groupModifiers, childModifiers});
export const removeModifire = (id, modifierId) => ({type: REMOVE_MODIFIRE, id, modifierId});
export const removeGroupModifire = (id, groupModifiers, childModifiers) => ({type: REMOVE_GROUP_MODIFIRE, id, groupModifiers, childModifiers});
export const removeModifiresOnProduct = (id) => ({type: REMOVE_MODIFIERS_ON_PRODUCT, id});




export const getMenu = (city) => {
    return async (dispatch) => {

        actions.getCity()
            .then(res => {
                if (res) {
                    // console.log('res', res);
                    dispatch(updateStreets(JSON.parse(res)));
                }
            })
            .catch(error => {
                console.error(error);
            })

        const database = firebase.database().ref('/iqpizza/');
       
        database.on('value', (snapshot) => {
            let items = snapshot.val();

            if (items) {
                dispatch(updateData(items));
                dispatch(getFirebase(items));
            }

            return true;
        });

       let status = actions.getMenu()
            .then(res => {
                // console.log('res -->>>', res);
                if (!res) {
                    throw new Error('Проблемы с сетью!');
                }
                if (res) {
                    const result = JSON.parse(res);
                    let items = result.products;
                    let groups = result.groups;

                    // console.log('result -->>>', result);

                    let newListProducts = [];
    
                    for(let item in items){

                        let groupModifiers = [];
                        let modifiers = [];
    
                        if(items[item].modifiers.length > 0){
                            let modifiersList = items[item].modifiers;
                            for(let it in modifiersList){
                                modifiers.push({
                                    name:  items.find(x => x.id === modifiersList[it].modifierId).name,
                                    modifierId: modifiersList[it].modifierId,
                                    price: items.find(x => x.id === modifiersList[it].modifierId).price,
                                    defaultAmount: modifiersList[it].modifierId,
                                    maxAmount: modifiersList[it].maxAmount,
                                    minAmount: modifiersList[it].minAmount,
                                    amount: modifiersList[it].minAmount ? modifiersList[it].minAmount : 0
                                })
                            }
                        }
    
                        if(items[item].groupModifiers.length > 0){
                            let groupModifiersList = items[item].groupModifiers;                    
    
                            for(let it in groupModifiersList){
    
                                let сhildModifiersList = groupModifiersList[it].childModifiers;
    
                                // console.log(groupModifiersList[it].childModifiers, 'groupModifiersList[it]');
    
                                let newChildModifiers = [];  
    
                                for(let it in сhildModifiersList){
                                    newChildModifiers.push({
                                        name:  items.find(x => x.id === сhildModifiersList[it].modifierId).name,
                                        modifierId: сhildModifiersList[it].modifierId,
                                        price: items.find(x => x.id === сhildModifiersList[it].modifierId).price,
                                        defaultAmount: сhildModifiersList[it].modifierId,
                                        maxAmount: сhildModifiersList[it].maxAmount,
                                        minAmount: сhildModifiersList[it].minAmount,
                                        amount: сhildModifiersList[it].minAmount ? сhildModifiersList[it].minAmount : 0
                                    });
                                }
    
                                let mod = groups.find(x => x.id === groupModifiersList[it].modifierId);
    
                                groupModifiers.push({
                                    name: mod ? mod.name : '',
                                    modifierId: groupModifiersList[it].modifierId,
                                    maxAmount: groupModifiersList[it].maxAmount,
                                    minAmount: groupModifiersList[it].minAmount,
                                    childModifiers: newChildModifiers
                                })
                                
                            }
                        }

                        let product = {
                            ...items[item],
                            groupModifiers,
                            modifiers,
                            url: slugify(items[item].name),
                        };

                        newListProducts.push(product);
                    };
    
                    let categories = result ? result.groups : [];
                    const listProducts = [...newListProducts];
                    
                    categories.forEach(category => {
                        const cityProducts = [];

                        listProducts.forEach(product => {
                            if (product.parentGroup === category.id) {
                                const prohibitedToSaleOn = product.prohibitedToSaleOn;
                                let hide = false;

                                city.terminals.forEach(terminal => {
                                    const search = prohibitedToSaleOn.find(t => t.terminalId === terminal.id);
                                    if (search) hide = true;
                                });

                                if (!hide) {
                                    cityProducts.push(product);
                                }
                            }
                        });

                        if (cityProducts.length === 0) {
                            categories = categories.filter(t => t.id !== category.id);
                        }
                        // console.log('cityProducts', cityProducts);
                    });

                    const filtredCategories = categories.filter(t => t.id !== 'd1537420-4fd6-4ec4-90be-626b37edbec3' && t.id !== '03bb81c4-d745-4de2-990a-1d47f2761321');
                    console.log('filtredCategories', filtredCategories);
                    let products = {
                        categories, 
                        products: newListProducts,
                    };

                    console.log('products -->>>', products);
    
                    /*let products = res.products.map(item => item ? item['Code'] === '100038567' || item['Code'] === '100038586' ? 
                        {...item, count: 1, edit: false}  : item : null);*/

                    // console.log('products *** -->>>', cities);

                    dispatch(updateMenu(products));
    
                    return true;
                }
            });
        return status;
    }
}

export default Menu;