import React from 'react';
import { useTranslation } from "react-i18next";

const Pica45sm = () => {
	 const { t, i18n } = useTranslation(); 
    return (
        <div style={{
            height: 400,
            overflowY: 'scroll',
        }}>
            <h1>{t('pizza45')}</h1>
            <p>Что может быть лучше и вкуснее пиццы? Только большая, аппетитная пицца 45 см. А где лучше всего заказывать вкусную, горячую выпечку на тонком тесте, у которой доступная цена и шустрая доставка? Конечно же в IQ Pizza! </p>
            <p>Друзья, сегодня мы расскажем вам, чем так хороша еда у нас и почему нужно сделать заказа в нашем заведении. </p>
            <h2>Сколько весит пицца 45 см</h2>
            <p>Пицца 45 см в нашем заведении имеет разный вес несмотря на то, что они с одинаковый диаметром коржа. Вес выпечки напрямую зависит от начинки, дополнительных ингредиентов, которые вы захотите добавить к блюду, и от наполнения бортика (сырный или сосисочный). Состав блюда клиент можете изучить перейде к карточке товара. </p>
            <p>Давайте разберем на примере, рассмотрим минимальный и максимальный вес: </p>
            <ul>
                <li>«Грибная» - 720 грамм;</li>
                <li>«Курица BBQ» - 820 грамм;</li>
                <li>«Гавайская» - 940 грамм;</li>
                <li>«Чизбургер» - 1090 грамм;</li>
            </ul>
            <p>С полным ассортиментом нашего заведения, возможными дополнительными ингредиентами и наполнителями для бортика, вы можете ознакомиться в меню, перейдя в раздел «Пицца 45 см», на официальном сайте или мобильном приложении от IQ Pizza. Там же описаны условия доставки.</p>
            <h2>На сколько человек рассчитана пицца 45 см</h2>
            <p>Каждая вкусная пицца 45 см от нашего заведения режется на 8 одинаковых кусочков. «На сколько человек рассчитана выпечка»: спросите вы. Вы сами решаете это! Вы можете съесть аппетитное и горячее угощение одному, а можете разделить его с близкими.</p>
            <p>Пример: в компании, состоящей из 4 человек каждому достанется по 2 кусочка аппетитной выпечки, у которой тонкое тесто. Если вы планируете пообедать вдвоем, то каждый сможет полакомиться четырьмя кусочками аппетитной еды.</p>
            <p>Пицца 45 см – идеальный перекус для каждого. В нашем заведении точно найдете свое любимое угощение. Скорее выбирайте, делайте заказ. Устройте себе прекрасный обед. </p>
        </div>
    );
}

export default Pica45sm;