import React from 'react';
import { useSelector } from 'react-redux';
import s from './text.module.css';

const TextRU = () => (
    <div className={s.row}>
        <p>В наши дни пицца – это не просто блюдо. Это вкусный семейный обед, отличный перекус утром, блюдо, с которым можно вкусно посидеть с друзьями, прекрасное угощение в офис, романтический ужин для любителей оригинальных вечеров.</p> 
        <p>Ищете вкусную еду по доступной цене, с быстрой недорогой доставкой? Добро пожаловать в IQ Pizza!</p> 
        <p>Мы предлагаем заказать пиццу на любой вкус и кошелек. Здесь вы точно найдете ту самую выпечку, которую захотите заказать еще не раз!</p> 
        <h2>Какую пиццу лучше заказать</h2>
        <p>Какую пиццу вы любите? Вам нравится классическая или блюдо с необычной начинкой? Вы точно найдете его в IQ Pizza! Нашим клиентам мы предлагаем вкуснейшую выпечку многих видов на тонком тесте с разнообразной начинкой, соусами.</p>
        <p>Здесь вы сможете заказать пиццу из самых лучших продуктов: овощи, мясо, оливки, грибы, ананасы, колбаски, морепродукты, маринованные огурчики, многие другие ингредиенты. Не забудьте добавить сыр или сосиски в бортик, чтобы еда стала еще вкуснее! Томатный и белый соус делают блюдо сочным, мягким.</p>
        <p>Мы не зря сказали, что в IQ Pizza есть лучшая пицца для каждого! Вы вегетарианец, но жить не можете без этого аппетитного и горячего блюда? В нашем меню вы найдете лакомство без мяса! Ваша овощная пицца станет еще вкуснее, если добавить в нее качественные ингредиенты, состав которых не содержит продукты животного происхождения.</p>
        <p>Наше заведение предлагает своим клиентам не только пиццу. В меню вы также найдете освежающие напитки: сладкую газировку, соки, холодное пиво.</p>
        <p>Ассортимент нашего заведения постоянно обновляется новыми вкусными новинками. Следите за обновлениями меню на нашем сайте и в приложении. Новинка от IQ Pizza ждет вас. Закажите ее уже сегодня!</p>
        <h2>Сколько стоит заказать пиццу</h2>
        <p>Многих наших клиентов интересует вопрос, сколько стоит заказать пиццу у нас. Финальный чек зависит от моментов, о которых мы вам сейчас расскажем.</p>
        <p>Конечная стоимость заказа зависит от:</p>
        <ul>
            <li>размер: маленькая (тесто 30 см) или большая (45 см);</li>
            <li>дополнительные ингредиенты начинки: маслины, двойное мясо, ананасы, сыры, грибы и прочее;</li>
            <li>наполнение бортика: сырный или сосисочный бортик.</li>
        </ul>
        <p>Давайте разберем на примере: вы хотите заказать пиццу, наш хит «Гавайская», для вашей семьи. Чтобы порции достались всем, рекомендуем брать большую, 45 см, стоимость которой 146 грн. Хотите сделать ваш ужин еще вкуснее? Добавьте сырный или сосисочный бортик! Теперь мясная пицца точно идеальна. Оформляем доставку. Сумма вашего заказа, с учетом всех дополнительных ингредиентов, доставки, составит от 239 грн. Всего 239 грн за вкуснейший ужин в кругу семьи! Еда приедет к вам свежая и горячая в короткий срок.</p>
        <p>Зачем платить больше, если можно сэкономить? Согласны? У нас это возможно! Пользуйтесь промокодами, следите за акционными предложениями и скидками на нашем сайте и в приложении, чтобы самая лучшая еда стала еще доступней!</p>
        <h2>Где заказать пиццу</h2>
        <p>IQ Pizza есть во многих крупных городах Украины.</p>
        <p>Среди них:</p>
        <ul>
            <li>Днепр</li>
            <li>Запорожье</li>
            <li>Киев</li>
            <li>Павлоград</li>
            <li>Новомосковск</li>
            <li>Энергодар</li>
            <li>Днепрорудное</li>
            <li>Вольнянск</li>
            <li>Чернигов</li>
            <li>Бердянск</li>
            <li>Мелитополь</li>
            <li>Пологи</li>
            <li>Токмак</li>
        </ul>
        <p>Адреса заведений рядом с вами, в которых доступен самовывоз, вы можете посмотреть в разделе «Доставка» на нашем сайте или в приложении.</p>
        <p>Лень идти за едой самому? Вы можете заказать пиццу с доставкой на дом! </p>
        <p>Города с возможной бесплатной доставкой:</p>
        <ul>
            <li>Днепр</li>
            <li>Киев</li>
            <li>Запорожье</li>
            <li>Павлоград</li>
            <li>Новомосковск</li>
            <li>Бердянск</li>
            <li>Энергодар</li>
            <li>Мелитополь</li>
            <li>Днепрорудное</li>
            <li>Чернигов</li>
        </ul>
        <p>Доставка осуществляется по всему городу, в радиусе 5 километров от заведения. Цена – 35 грн. При заказе пиццы на сумму от 350 грн еда будет доставлена вам бесплатно!</p>
        <h2>До скольких можно заказать пиццу</h2>
        <p>IQ Pizza открыта для вас каждый день, без выходных, с 10:00 до 22:00. Заказы на доставку мы принимаем по телефону, указанному на сайте, или через наше приложение, с момента открытия и до 21:40.</p>
        <p>Важная информация: время работы заведения и приема заказов может отличаться в зависимости от вашего города. Вся необходимая информация о графике работы IQ Pizza указана на сайте и в приложении.</p>
        <h2>Заказать пиццу на сайте, по телефону или через приложение</h2>
        <p>Хотите заказать пиццу? Это очень быстро и просто. Наши клиенты могут заказать пиццу тремя удобными способами:</p>
        <ul>
            <li>по телефону;</li>
            <li>через интернет, оформив заказ на сайте IQ Pizza;</li>
            <li>через наше приложение</li>
        </ul>
        <h3>Как заказать пиццу по телефону:</h3>
        <ol>
            <li>Вам нужно выбрать понравившуюся еду на сайте.</li>
            <li>Позвонить по номеру телефона, который указан на сайте.</li>
            <li>Озвучить оператору ваш заказ, дополнительные ингредиенты, адрес и время доставки.</li>
            <li>Подождать, пока курьер привезет вам вкусную и горячую пиццу.</li>
        </ol>
        <h3>Как заказать пиццу через официальный сайт:</h3>
        <ol>
            <li>Выберите понравившееся блюдо и дополнительные ингредиенты.</li>
            <li>Нажмите на пункт «Оформить заказ».</li>
            <li>Введите ваши контакты: имя, контактный номер телефона и адрес доставки.</li>
            <li>Выберите точку, из которой вы хотите забрать заказ или получить доставку.</li>
            <li>Подтвердите свой заказ.</li>
            <li>Ожидайте звонок оператора для уточнения и подтверждения заказа.</li>
            <li>Ждите, пока курьер привезет вам вкусную и горячую пиццу.</li>
        </ol>
        <p>Условия заказа пиццы по городу через сайт: при онлайн заказе через сайт оплата проводится наличными. Заранее предупредите оператора, сдачу с какой суммы должен иметь при себе курьер.</p>
        <h3>Как заказать пиццу через приложение:</h3>
        <ol>
            <li>Скачайте приложение, зарегистрируйтесь: укажите свой город и номер телефона. Указанная информация сохранится в вашем профиле. Это значительно облегчит и ускорит оформление следующих заказов.</li>
            <li>Выберите понравившееся блюдо и качественные дополнительные ингредиенты.</li>
            <li>Перейдите в раздел «Корзина». Выберите адрес и время доставки.</li>
            <li>Оплатите заказ.</li>
            <li>Ожидайте обратный звонок от курьера о том, что ваша вкусная и горячая еда уже доставлена.</li>
        </ol>
        <p>Сделать заказ у нас просто! Если во время оформления заказа или регистрации у вас возникли трудности, вы можете позвонить по номеру, который указан на сайте. Наши менеджеры дадут вам полный и быстрый ответ на любой ваш вопрос!</p>
    </div>
);

const TextUA = () => (
    <div className={s.row}>
        <p>В наші дні піца - це не просто страва. Це смачний сімейний обід, блюдо, з яким можна смачно посидіти в компанії друзів, романтична вечеря для любителів оригінальних вечорів.</p>
        <p>Шукаєте смачні гарячі піци за доступною ціною, з швидкою недорогою доставкою? Ласкаво просимо в IQ Pizza!</p>
        <p>Ми пропонуємо замовити безліч улюблених піц на будь-який смак і гаманець. Тут ви точно знайдете ту саму випічку, яку захочете купити ще не раз!</p>
        <h2>Яку піцу краще замовити</h2>
        <p>Яку піцу ви любите? Вам подобається класична або страва з незвичайною начинкою? Ви точно знайдете її в IQ Pizza! Для наших клієнтів ми готуємо смачну випічку багатьох видів на тонкому тесті з різноманітною начинкою, соусами.</p>
        <p>Тут ви зможете замовити піцу з найкращих продуктів: овочі, м'ясо, оливки, гриби, ананаси, ковбаски, морепродукти, мариновані огірочки, інші інгредієнти. Не забудьте додати сир або сосиски в бортик, щоб стало ще смачніше! Томатний і білий соус роблять блюдо соковитим, м'яким.</p>
        <p>Ми не дарма сказали, що в IQ Pizza є найкраща піца для кожного! Ви вегетаріанець, але жити не можете без цієї апетитної і гарячої страви? У нашому меню ви знайдете ласощі без м'яса! Оберіть якісні інгредієнти, склад яких не містить продукти тваринного походження, щоб ваша овочева піца стала ще смачнішою.</p>
        <p>Наш заклад пропонує своїм клієнтам не тільки піцу. У меню ви знайдете освіжаючі напої: солодку газовану воду, соки, холодне пиво.</p>
        <p>Асортимент нашого закладу постійно оновлюється новими смачними новинками. Слідкуйте за оновленнями меню на нашому сайті і в додатку. Новинка від IQ Pizza чекає вас. Замовте її вже сьогодні!</p>
        <h2>Скільки коштує замовити піцу</h2>
        <p>Багатьох наших клієнтів цікавить, скільки коштує замовити піцу у нас. Фінальний чек залежить від моментів, про які ми вам зараз розповімо.</p>
        <p>Кінцева вартість замовлення залежить від:</p>
        <ul>
            <li>розмір: маленька (тісто 30 см) або велика (45 см);</li>
            <li>додаткові інгредієнти: маслини, подвійне м'ясо, ананаси, сири, гриби та інше;</li>
            <li>аповнення бортика: сирний або сосисковий бортик.</li>
        </ul>
        <p>Давайте розберемо на прикладі: ви хочете замовити піцу, сімейна «Гавайська», для вашої родини. Щоб шматочок ароматної піци дістався кожному, рекомендуємо брати велику, 45 см, вартість якої 146 грн. Хочете зробити вашу вечерю ще смачнішою? Додайте сирні або сосискові бортики! Тепер м'ясна піца точно ідеальна.</p>
        <p>Оформляємо заявку і передаємо замовлення кур'єру. Сума замовлення, з урахуванням всіх додаткових інгредієнтів, доставки, складе від 239 грн. Всього 239 грн за смачну вечерю в родинному колі! Їжа приїде до вас за адресою свіжа і гаряча в короткий термін</p>
        <p>Навіщо платити більше, якщо можна заощадити? Згодні? У нас це можливо! Користуйтеся промокодом, стежте за акційними пропозиціями і знижками на нашому сайті і в додатку, щоб найкраща їжа стала ще доступнішою!</p>
        <h2>Де замовити піцу</h2>
        <p>IQ Pizza є в багатьох великих містах України.</p>
        <p>Серед них:</p>
        <ul>
            <li>Дніпро</li>
            <li>Запоріжжя</li>
            <li>Київ</li>
            <li>Павлоград</li>
            <li>Новомосковськ</li>
            <li>Енергодар</li>
            <li>Дніпрорудне</li>
            <li>Вільнянськ</li>
            <li>Чернігів</li>
            <li>Бердянськ</li>
            <li>Мелітополь</li>
            <li>Пологи</li>
            <li>Токмак</li>
        </ul>
        <p>Ресторани, де можна взяти піцу з собою, ви можете подивитися в розділі «Доставка» на нашому сайті або в додатку.</p>
        <p>Лінь йти за їжею самому? Ви можете замовити піцу з доставкою додому!</p>
        <p>Міста, де можлива безкоштовна доставка:</p>
        <ul>
            <li>Дніпро</li>
            <li>Київ</li>
            <li>Запоріжжя</li>
            <li>Павлоград</li>
            <li>Новомосковськ</li>
            <li>Бердянськ</li>
            <li>Енергодар</li>
            <li>Мелітополь</li>
            <li>Дніпрорудне</li>
            <li>Чернігів</li>
        </ul>
        <p>Доставка здійснюється по всьому місту, в радіусі 5 кілометрів від закладу. Сума доставки - 35 грн. При замовленні піци, ціна якої  від 350 грн, їжа буде доставлена вам безкоштовно!</p>
        <h2>До скількох можна замовити піцу</h2>
        <p>Ми працюємо для вас щодня, без вихідних, з 10:00 до 22:00. Замовлення ми приймаємо за телефоном, вказаним на сайті, або через наш додаток, з моменту відкриття і до 21:40.</p>
        <p>Важлива інформація: час роботи закладу та прийому замовлень може відрізнятися в залежності від вашого міста. Вся необхідна інформація про графік роботи IQ Pizza вказана на сайті і в додатку.</p>
        <h2>Замовити піцу на сайті, по телефону або через додаток</h2>
        <p>Хочете замовити піцу? Це дуже швидко і просто. Наші клієнти можуть замовити піцу трьома зручними способами:</p>
        <ul>
            <li>по телефону;</li>
            <li>через інтернет, на сайті IQ Pizza;</li>
            <li>через наш мобільний додаток.</li>
        </ul>
        <h3>Як замовити піцу по телефону:</h3>
        <ol>
            <li>Вам потрібно вибрати вподобану їжу на сайті.</li>
            <li>Зателефонувати за номером телефону, який вказаний на сайті.</li>
            <li>Озвучити оператору ваше замовлення, додаткові інгредієнти, адресу і час доставки. Наш працівник уточнить замовлення та скаже, скільки чекати на їжу.</li>
            <li>Почекати, поки працівник сервісу доставки привезе вам смачну і гарячу піцу. З кур’єром ви можете розрахуватися готівкою.</li>
        </ol>
        <h3>Як замовити піцу через офіційний сайт:</h3>
        <ol>
            <li>Виберіть кількість страв і додаткові інгредієнти.</li>
            <li>Натисніть на пункт «Оформити замовлення».</li>
            <li>Введіть ваші контакти: ім'я, номер телефону та адресу доставки.</li>
            <li>Виберіть точку, з якої ви хочете забрати замовлення або отримати їжу</li>
            <li>Підтвердіть своє замовлення.</li>
            <li>Чекайте на дзвінок оператора для уточнення і підтвердження замовлення. Наш працівник зв’яжеться з вами за декілька хвилин, назве час приготування та доставки їжі.</li>
            <li>Чекайте, поки служба доставки привезе вам смачну і гарячу піцу.</li>
        </ol>
        <p>Важлива інформація: при онлайн замовленні через сайт оплата проводиться готівкою. Заздалегідь попередьте оператора, решту з якої суми повинен мати при собі кур'єр.</p>
        <h3>Як замовити піцу через додаток:</h3>
        <ol>
            <li>Скачайте додаток, зареєструйтесь: вкажіть своє місто і номер телефону. Зазначена інформація збережеться в вашому профілі. Це значно полегшить і прискорить оформлення наступних замовлень через особистий кабінет.</li>
            <li>Виберіть вподобану страву і якісні додаткові інгредієнти.</li>
            <li>Перейдіть в розділ «Кошик». Виберіть адресу і час доставки.</li>
            <li>Сплатіть замовлення. У додатку можна розрахуватися картою.</li>
            <li>Чекайте на зворотній дзвінок від кур'єра про те, що ваша смачна і гаряча їжа вже доставлена.</li>
        </ol>
        <p>Зробити замовлення у нас просто! Якщо під час оформлення заявки у вас виникли труднощі, або реєстрація не проходить,  подзвоніть за номером, який вказаний на сайті. Наші менеджери дадуть вам повну і швидку відповідь на будь-яке ваше питання!</p>
    </div>
)

const TextMessage = () => {
    const languageMenu = useSelector(state => state.menu.languageMenu);
    const isDefaultLanguage = languageMenu === 'uk';
    return (
        <div className={s.textMessage}>
            {isDefaultLanguage ? <TextUA /> : <TextRU />}
        </div>
    )
}

export default TextMessage;