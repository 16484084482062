import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {NavLink} from 'react-router-dom';

import ModalCities from '../../../components/modalCities';
import { getCookie, getActiveCity } from '../../../components/modalCities/cookie';
import { updateActiveCity } from '../../../redux/menu';

import { useTranslation } from "react-i18next";

import s from './header.module.css';
import logo from '../../../assets/logo.png';
import menu from '../../../assets/burger.svg';
import close from '../../../assets/close.svg';


const Header = (props) => {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const isHomePage = useSelector(state => state.menu.isHomePage);
    const activeCity = useSelector(state => state.menu.activeCity);
    const cities = useSelector(state => state.menu.cities);
    const language = useSelector(state => state.menu.languageMenu);
    const city = cities.find(t => t.id === activeCity.id);

    const [isActiveModalCities, setIsActiveModalCities] = useState(false);

    useEffect(() => {
        const cityCookie = getCookie('city');
        // const host = window.location.host === "localhost:3000";
        const host = window.location.host === "iqpizza.com.ua";

        if (host) {
            if (cityCookie) {
                const activeCity = getActiveCity(cityCookie);
                dispatch(updateActiveCity(activeCity));
                setIsActiveModalCities(false);
            }
    
            if (!cityCookie) {
                setTimeout(() => setIsActiveModalCities(true), 3000);
                // setIsActiveModalCities(true);
            }
        }
    }, []);

    return(
        <header className={s.header}>
            {isActiveModalCities && <ModalCities />}
            <div className="row">
                <div className={s.header_nav}>
                        <div className={s.logo}>
                            <span><img src={logo} alt="IQpizza logotype" /></span>
                           {!props.mobileMenu && <div onClick={props.onMobileMenu} className={s.burgerMenu}><img src={menu} /></div>}
                            {props.mobileMenu && <div onClick={props.closeMobileMenu} className={s.burgerMenuClose}><img src={close} /></div>}
                        </div>
                        <div className={s.nav}>
                            <NavLink to={`/${language === 'ru' ? 'ru/menu/' : 'menu/'}`}>{t('menu')}</NavLink>
                            <NavLink to={`/${language === 'ru' ? 'ru/dostavka/' : 'dostavka/'}`}>{t('delivery')}</NavLink>
                            <NavLink to={`/${language === 'ru' ? 'ru/o-nas/':'pro-nas/'}`}>{t('aboutUs')}</NavLink>
                            <NavLink to={`/${language === 'ru' ? 'ru/blog/':'blog/'}`}>{t('blog')}</NavLink>
                            {/* <NavLink to={`/${language === 'ru' ? 'ru/loyalty/':'loyalty/'}`}>{t('loyalty')}</NavLink> */}
                            <a target="_blank" href="/loyalty.pdf">{t('loyalty')}</a>
                        </div>
                        <div className={s.header_info}>
                            <div className={`${s.language} ${s.cities}`} onClick={() => props.openCities()}>
                                <button>{isHomePage ? '' : props.activeCity.name[language]}</button>
                            </div>
                            <div className={s.language} onClick={() => props.openLanguage()}>
                                <button>{props.language}</button>
                            </div>
                            <div className={s.info_container}>
                                <a href="tel:0681151010" className={`binct-phone-number-2 ${s.phone} ${s.header_icon}`}></a>
                                <div className={s.info}>
                                    <a href="tel:0681151010" className="binct-phone-number-1">068 115 1010</a>
                                    <span className={s.time}>10:00 - 22:00</span>
                                </div>
                            </div>
                            <NavLink to={`/${language === 'ru' ? 'ru/basket' : 'basket'}`} className={s.basket}>
                                <span>{props.basketCount}</span>
                            </NavLink>
                        </div>
                    </div>
            </div>
        </header>
    )
}

export default Header;