import React from 'react';
import s from './modal.module.css';
import { useTranslation } from "react-i18next";

import tick from '../../assets/tick.svg';
import error from '../../assets/error.svg';

import Button from '../../components/button';
import { withRouter } from "react-router";

const ModalResult = (props) => {
    const { t, i18n } = useTranslation();

    const main = () => {props.history.push('/')};
    const basket = () => {props.history.push('/basket')};
    return(
        <div className={s.overlay}>
            <div className={s.modal}>
                <div className={s.modal_container}>
                    <img src={props.status ? tick : error}/>
                    <h3>{t(props.status ? 'accepted' : !props.showModalMessage ? 'errorSend' : 'errorSendMessage')}</h3>
                    <Button title={t(props.status ? 'main' : 'cart')} 
                        handleClick={props.status ? main : basket}/>
                </div>
            </div>
        </div>
    )
}

export default withRouter(ModalResult);