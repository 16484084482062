import React, {useState} from 'react';
import { useSelector } from 'react-redux';
import logo from '../../../assets/logo.png';

import s from './product.module.css';

const Product = (props) => {
    const cities = useSelector(state => state.menu.cities);
    const activeCity = useSelector(state => state.menu.activeCity);
    const city = cities.find(t => t.id === activeCity.id);

    const [price, setPrice] = useState(0);

    const getListGroupModifire = (items) => {
        let sum = 0;
        let groupModifireList = '';
        for(let item in items){
            let modifire = items[item].name;
            let childModifiers = items[item].childModifiers;
            let listChild = '';
            for (let it in childModifiers){
                if(childModifiers[it].amount > 0){
                    listChild += `${childModifiers[it].name} - ${childModifiers[it].amount}; `
                }
            };
            if(listChild.length > 0){
                groupModifireList += `${modifire} (${listChild}); `
            }
        };
        return groupModifireList;
    };

    const getSum = () => {
        let sum = 0;
            let price = props.item.price;
            let modifierSum = 0;

            if (city && city.terminals) {
                props.item.differentPricesOn.map(t => {
                    city.terminals.map(terminal => {
                        if (terminal.id === t.terminalId) {
                            price = t.price;
                        }
                    })
                });
            }

            props.item.modifiers.map(a => {
                modifierSum += ((a.price*10)*a.amount)/10
            });
            props.item.groupModifiers.map(a => a.childModifiers.map(b => {
                modifierSum = modifierSum +  (b.price * (b.amount * 10)) / 10
            }));
            sum = modifierSum+price;             
        
            return sum;
    }

    return(
        <div className={s.basket_product}>
            <div className={s.img_product} 
             style={{backgroundImage: props.item.images !== [] && props.item.images.length > 0 ? `url(${props.item.images[props.item.images.length-1].imageUrl})` : 
             `url(${logo})`
            }}
        />
                <div className={s.title_container}>
                <div className={s.title_product}>{props.item.name}</div>
                <div className={s.modifire}>
                    <span>{getListGroupModifire(props.item.groupModifiers)}</span>
                    {props.item.modifiers.map((item, key) => {
                        return(
                            item.amount > 0 && <span key={key}>{item.name} - {item.amount}; </span>
                        )
                    })}
                </div>
                </div>
                    <div className={s.price_product}>
                        <span>{Number((getSum())*10*props.item.count)/10}</span> ₴
                    </div>
                   <div className={`${s.count_product} ${s.count}`}>
                   <div className={s.minus} onClick={() => 
                  props.item.count > 1   ? 
                        props.updateProduct('decrement', props.item['id']) : props.deleteProduct(props.item['id'])}
                        />
                        <div className={s.result}>{props.item.count}</div>
                    <div className={s.plus} onClick={() => props.updateProduct('increment', props.item['id'])}/>
                </div>
                {props.item.edit !== false  ?  
                        <div onClick={() => props.deleteProduct(props.item['id'])} className={s.delete}></div> : null}
           
        </div>
    )
}

export default Product;