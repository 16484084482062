import React from 'react';
import './article.style.css';

const ArticleRu = () => {
    return (
        <div className="4siri">
            <p>Пицца Гавайская 30 см – пикантная выпечка, имеющая насыщенный вкус. Это блюдо с курицей, ананасами никого не оставит равнодушным! Особенно летом, когда так хочется в теплые края. Подарите себе кусочек волшебного курорта в океане вместе IQ Pizza! </p>
            <p>Ингредиенты из начинки, которые выкладываются на тесто: </p>
            <ul>
                <li>сливочный соус;</li>
                <li>сыр "Моцарелла";</li>
                <li>курица;</li>
                <li>ананас;</li>
                <li>лук маринованный;</li>
                <li>зелень.</li>
            </ul>
            <p>Хотите, чтобы пицца Гавайская 30 см стала еще сытнее и аппетитнее? Легко! Добавьте к выпечке дополнительные ингредиенты из нашего меню. Ваше блюдо отлично дополнят маслины, кукуруза и двойной сыр. Также не забудьте добавить наполнение бортика: крем или сулугуни. С дополнительной начинкой пицца станет еще вкуснее и пикантнее! </p>
            <p>Вы можете заказать обед по номеру телефона 068 115 1010, на сайте или в нашем мобильном приложении. Заказы принимаются каждый день, с 10:00 до 22:00. Скорее оформляйте доставку на свой гавайский обед от IQ Pizza. Насыщенный вкус и удовольствие в каждом кусочке гарантировано!</p>
        </div>
    );
}

export default ArticleRu;