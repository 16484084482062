import React, {Component} from 'react';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import firebase from 'firebase';

import Basket from './basket';
import {connect} from 'react-redux';
import {updateProduct, deleteProduct} from '../../redux/menu';


class BasketContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            keyShop: null,
        }
    }

    componentDidMount(){
        window.scroll(0, 0);
        this.getKeyShop();
    }

    getKeyShop = () => {
        const {activeCity, cities} = this.props;

        if (activeCity && cities) {
            const city = cities.find(t => t.spot_id === activeCity.id);
            if (city) {
                this.setState({ keyShop: city.key });
            }
        }
    }

    getFieldOrderAvailable = () => {
        return firebase.database().ref(`iqpizza/settings/City/${this.state.keyShop}/orderAvailable`).once('value')
    }

    getWorkingHours = () => {
        return firebase.database().ref(`iqpizza/settings/City/${this.state.keyShop}/workingHours`).once('value')
    }

    render(){
            const canonicalUrl = this.props.languageMenu === 'uk' ? 'https://iqpizza.com.ua/basket/' : 'https://iqpizza.com.ua/ru/basket/';
            const title = this.props.languageMenu === 'uk' ? 'Кошик | IQ Pizza – розумна піца' : 'Корзина | IQ Pizza - умная пицца';
        return(
            
            <>
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="robots" content="noindex, nofollow"/>
                <title>{title}</title>
                <meta name="description" content={''} />
                <link rel="canonical" href={canonicalUrl} />
            </Helmet>
                <Basket products={this.props.products} 
                    updateProduct={this.props.updateProduct} 
                    getFieldOrderAvailable={this.getFieldOrderAvailable} 
                    getWorkingHours={this.getWorkingHours} 
                    deleteProduct={this.props.deleteProduct}
                    languageMenu={this.props.languageMenu} />
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return{
        products: state.menu.products,
        languageMenu: state.menu.languageMenu,
        activeCity: state.menu.activeCity,
        cities: state.firebase.data.settings && state.firebase.data.settings.City ? state.firebase.data.settings.City : [],
    }
}

export default connect(mapStateToProps, {updateProduct, deleteProduct})(withRouter(BasketContainer));