import React from 'react';
import {connect} from 'react-redux';
import {getMenu, updateProduct, updateModifire} from '../../redux/menu';
import { withRouter } from "react-router";
import Products from './products';
import { getCategoryId } from '../Router/ohter';

import {
    scroller,
  } from "react-scroll";
  
class Menu extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            activeCategory: '100000052'
        };
    }

    componentDidMount(){
        window.scroll(0, 0);

        const path = this.props.match.path;
        const dLng = this.props.languageMenu === 'uk';

        let categoryId = this.props.categories[0].id;
        const napitki = path.indexOf(dLng ? 'napoji' : 'napitki') !== -1;
        const isPizza30 = path.indexOf(dLng ? 'pica-30-sm' : 'picca-30-sm') !== -1;
        const isPizza45 = path.indexOf(dLng ? 'pica-45-sm' : 'picca-45-sm') !== -1;
        const isHophey = path.indexOf(dLng ? 'hophey' : 'hophey') !== -1;
        const isCombo = path.indexOf(dLng ? 'combo' : 'combo') !== -1;
        const isHeloyin = path.indexOf(dLng ? 'helloween' : 'helloween') !== -1;
        const isBoxMenu = path.indexOf(dLng ? 'box-menu' : 'box-menu') !== -1;
        const isSezonnoeMenu = path.indexOf(dLng ? 'sezonnemenu' : 'sezonnemenu') !== -1;
        const is30sm = path.indexOf(dLng ? '30sm' : '30sm') !== -1;
        const is45sm = path.indexOf(dLng ? '45sm' : '45sm') !== -1;
        const isLove = path.indexOf(dLng ? 'love' : 'love') !== -1;

        if (napitki) categoryId = getCategoryId('napitki');
        if (isPizza30) categoryId = getCategoryId('pizza30');
        if (isPizza45) categoryId = getCategoryId('pizza45');
        if (isLove) categoryId = getCategoryId('love');
        if (isHophey) categoryId = getCategoryId('hophey');
        if (isCombo) categoryId = getCategoryId('combo', this.props.activeCity.id);
        if (isHeloyin) categoryId = getCategoryId('helloween');
        if (isBoxMenu) categoryId = getCategoryId('boxmenu');
        if (isSezonnoeMenu) categoryId = getCategoryId('sezonnemenu');
        if (is30sm) categoryId = getCategoryId('30sm');
        if (is45sm) categoryId = getCategoryId('45sm');

        this.updateCategory(categoryId, true);
        console.log('--- menu ----');
    }

    updateCategory = (id, noPush) => {
        let language = this.props.languageMenu;
        let url = '';

        if (id === getCategoryId('napitki')) url = language === 'uk' ? 'napoji' : 'napitki';
        if (id === getCategoryId('pizza30')) url = language === 'uk' ? 'pica-30-sm/' : 'picca-30-sm/';
        if (id === getCategoryId('pizza45')) url = language === 'uk' ? 'pica-45-sm/' : 'picca-45-sm/';
        if (id === getCategoryId('love')) url = language === 'uk' ? 'love/' : 'love/';
        if (id === getCategoryId('hophey')) url = language === 'uk' ? 'hophey/' : 'hophey/';
        if (id === getCategoryId('combo', this.props.activeCity.id)) url = language === 'uk' ? 'combo/' : 'combo/';
        if (id === getCategoryId('helloween')) url = language === 'uk' ? 'helloween/' : 'helloween/';
        if (id === getCategoryId('boxmenu')) url = language === 'uk' ? 'box-menu/' : 'box-menu/';
        if (id === getCategoryId('sezonnemenu')) url = language === 'uk' ? 'sezonnemenu/' : 'sezonnemenu/';
        if (id === getCategoryId('30sm')) url = language === 'uk' ? '30sm/' : '30sm/';
        if (id === getCategoryId('45sm')) url = language === 'uk' ? '45sm/' : '45sm/';

        this.setState({ activeCategory: id });
        
        if (!noPush) {
            this.props.history.push(`${language === 'uk' ? `/menu/${url}` : `/ru/menu/${url}`}`);
        }

        // this.openLink('menu');
    }

    openLink = (link) => {
        scroller.scrollTo(link, {
          duration: 800,
          delay: 0,
          smooth: "easeInOutQuart",
          offset: -50
        });
    }

// componentWillUnmount() {
//     scrollSpy.unmount();
//     scroller.unmount();
//   }


    render(){
        return(
                <Products categories={this.props.categories}
                          languageMenu={this.props.languageMenu}
                          products={this.props.products}
                          activeCategory={this.state.activeCategory}
                          updateCategory={this.updateCategory}
                          updateProduct={this.props.updateProduct}
                          updateModifire={this.props.updateModifire}
                          activeCity={this.props.activeCity}
                          cities={this.props.cities}
                          hideProducts={this.props.data ? this.props.data.hiddenProducts ? this.props.data.hiddenProducts : [] : []}
                          />
        )
    }
}


const matStateToProps = (state) => {
    return {
        products: state.menu.products,
        categories: state.menu.categories,
        basket: state.menu.basket,
        languageMenu: state.menu.languageMenu,
        activeCity: state.menu.activeCity,
        cities: state.menu.cities,
        data: state.menu.data
    }
}

export default connect(matStateToProps, {getMenu, updateProduct, updateModifire})(withRouter(Menu));
