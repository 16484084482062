import React from 'react';
import { useTranslation } from "react-i18next";

const Napitki = () => {
	 const { t, i18n } = useTranslation(); 
    return (
        <div style={{
            height: 400,
            overflowY: 'scroll',
        }}>
            <h1>Напитки к пицце</h1>
            <p>Что может сделать вкусный, сытный обед еще лучше? Конечно же напитки к пицце! С прохладной газировкой обед едят с еще большим удовольствием.</p>
            <p>Лень или нет времени идти в магазин за водой, пока ждете доставку еды? Закажите алкогольные, безалкогольные напитки к пицце от IQ Pizza! Мы привезем вашу любимую содовую, на дом или на работу, вместе с выпечкой.  Доставка производится быстро, на любое удобное время и адрес. С нами вы сможете подать к столу аппетитное угощение вовремя, досыта накормить любую компанию.</p>
            <h2>Какие напитки подходят к пицце</h2>
            <p>Наша пиццерия предлагает огромный ассортимент вод, которые идеально сочетаются с сытной, горячей выпечкой и подчеркивают ее насыщенный вкус. В нашем меню клиенты найдут и смогут заказать свои любимые напитки к пицце. </p>
            <p>Мы предлагаем большой выбор вод, которыми вы сможете запивать любимое блюдо:</p>
            <ul>
                <li>алкогольные – холодное пиво, эль от нашего партнера Hop Hey Beer Shop;</li>
                <li>безалкогольные – вкусный хлебный квас, лимонад, сок, сладкие содовые (7 UP, Меринда, Пепси).</li>
            </ul>
            <p>У нас вы сможете выбрать и купить лучшие классические газировки для себя и для друзей. Воды из нашего меню идеально сочетаются с любимой выпечкой. Следите за своим гидробалансом? Закажите полезную минеральную воду на обед. Неважно, какие газировки вам по душе. Вы точно найдете их у нас. </p>
            <p>Увидеть полный ассортимент и узнать стоимость газировки, которая идеально сочетается с горячим угощеньем, вы сможете на нашем официальном сайте или мобильном приложении, в пункте меню «Напитки». </p>
            <p>Вкусная, горячая выпечка и напитки к пицце от IQ Pizza уже ждут вас. Скорее делайте заказ и наслаждайтесь! </p>
        </div>
    );
}

export default Napitki;