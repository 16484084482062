import React from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import Slider from '../../components/slider/slider';
import Delivery from './delivery/delivery';
import Products from './products/products';

import Scroll from 'react-scroll';
import img from '../../assets/logo.png';

// import { isCityName, changeCity } from '../../functions';

var Element = Scroll.Element;

const Main =(props) => {
    const { t, i18n } = useTranslation();
    const activeCity = useSelector(state => state.menu.activeCity);
    const isHomePage = useSelector(state => state.menu.isHomePage);
    const lng = useSelector(state => state.menu.languageMenu);
    const cityName = activeCity.name[lng];

    const ldJson = {
        "@context": "https://schema.org",
        "@type": "Organization",
        "name": "IQ Pizza",
        "url": "https://iqpizza.com.ua/",
        "logo": img,
        "contactPoint": [{
            "@type": "ContactPoint",
            "telephone": "+38-068-115-10-10",
            "contactType": "customer service",
            "areaServed": "UA",
            "availableLanguage": ["Russian","Ukrainian"]
          },{
            "@type": "ContactPoint",
            "telephone": "+38-099-115-10-10",
            "contactType": "customer service",
            "areaServed": "UA",
            "availableLanguage": ["Russian","Ukrainian"]
          }],
          "sameAs": [
            "https://www.facebook.com/iqpizza/",
            "https://www.instagram.com/iq_pizza_ukraine/"
          ]
    }
    const firstTitleMessage = lng === 'uk' ? '🍕 Замовити піцу ' : '🍕 Заказать пиццу ';
    const title = isHomePage ? firstTitleMessage + ' ' + t('mainTitle') : firstTitleMessage + cityName + ' ' + t('mainTitle');
    const firstDescriptionMessage = lng === 'uk' ? 'Найкраща, смачна, свіжа, гаряча піца на тонкому тісті на дім ' : 'Самая лучшая, вкусная, свежая, горячая пицца на тонком тесте на дом ';
    const description = isHomePage ? firstDescriptionMessage + ' ' + t('mainDescription') : firstDescriptionMessage + cityName + ' ' + t('mainDescription');
    const canonicalUrl = window.origin + props.match.path;
    // const isCategory = props.match.path === '/' || props.match.path === '/ru/';
    let path = window.location.host.split('.')[0];

    return(
        <>
        {true && (
          <Helmet>
            <meta charSet="utf-8" />
            <title>{title}</title>
            <meta name="robots" content="index, follow"/>
            <meta name="description" content={description} />
            <link rel="canonical" href={canonicalUrl} />
            <link rel="alternate" hreflang="ru" href={`https://${path ? path + '.' : ''}iqpizza.com.ua/ru/`} />
            <link rel="alternate" hreflang="x-default" href={`https://${path ? path + '.' : ''}iqpizza.com.ua/`} />
            <script type="application/ld+json">{JSON.stringify(ldJson)}</script>
        </Helmet>
        )}
        {props.slider && props.slider.length > 1 && <Slider activeCity={props.activeCity}
                slider={props.slider ? props.slider : []}/>}
        <Element name="menu">
        <Products categories={props.categories}
                  languageMenu={props.languageMenu}
                  products={props.products}
                  activeCategory={props.activeCategory}
                  updateCategory={props.updateCategory}
                  updateProduct={props.updateProduct}
                  cities={props.cities}
                  activeCity={props.activeCity}
                  hideProducts={props.hideProducts}
                  isDefaultPage={path === 'iqpizza'}
                  />
         </Element>
        <Element name="delivery">
        <Delivery cities={props.cities}
                  activeCity={props.activeCity}
                />
        </Element>
        </>
    )
}

export default withRouter(Main);