import React from 'react';
import { useTranslation } from "react-i18next";

const Pica30sm = () => {
	 const { t, i18n } = useTranslation(); 
    return (
        <div style={{
            height: 400,
            overflowY: 'scroll',
        }}>
            <h1>{t('pizza30')}</h1>
			<p>Пицца 30 см – это и сытный обед, и вкусный перекус, и отличный ужин для всей семьи. Где заказать вкусную горячую выпечку с аппетитной начинкой, с быстрой доставкой, у которой доступная цена? Конечно же в IQ Pizza! Сейчас мы расскажем вам про выпечку 30 см от нашего заведения. </p>
			<h2>Сколько весит пицца 30 см</h2>
			<p>Пицца 30 см – это минимальный размер выпечки в нашем заведении. У нас в  меню представлена пицца 30 см, которая имеет разный вес. Все зависит от типа начинки, которую вы захотите добавить к выпечке, а также от наполнения бортика.  </p>
			<p>Вот примерный вес пиццы диаметром 30 см:</p>
			<ul>
				<li>«2 сыра» – 320 грамм;</li>
				<li>«4 мяса» - 510 грамм;</li>
				<li>«4 мяса» DELUX – 600 грамм;</li>
				<li>«Альфредо» – 425 грамм;</li>
				<li>«Гавайская» - 465 грамм;</li>
				<li>«Карбонара» – 450 грамм. </li>
			</ul>
			<p>Ознакомиться с полным ассортиментом, весом, а также изучить состав нашей выпечки на тонком тесте вы можете, перейдя к пункту «Меню», на нашем официальном сайте в разделе «Пицца 30 см». Также все блюда с подробным описанием начинки и веса представлены в нашем мобильном приложении. Там же вы можете ознакомиться с условиями доставки от нашего заведения. </p>
			<h2>На сколько человек рассчитана пицца 30 см</h2>
			<p>Мы хотим, чтобы все наши клиенты могли насладиться вкусным и аппетитным угощением.  Каждая вкусная выпечка от нашего заведения режется на 8 одинаковых кусочков. Она идеально подойдет как для посиделок в шумной компании, так и для домашнего ужина на двоих.</p>
			<p>Если вы заказываете выпечку для вечеринки в компании из 4 человек, то каждый получит по 2 кусочка вкусного угощения, у которого вкусное, тонкое тесто. Если вы решили поужинать в паре, то каждый сможет насладиться целыми четырьмя кусочками аппетитного угощения, у которого много свежей и сытной начинки. </p>
			<p>Пицца 30 см от IQ Pizza – это вкусный, быстрый перекус для каждого. Выбирайте выпечку себе по душе, скорее заказывайте сытное и ароматное угощение! </p>
		</div>
    );
}

export default Pica30sm;