import React, {Component} from 'react';
import s from './slider.module.css';
import { Link } from 'react-router-dom';

import Slider from "react-slick";

import "../../../node_modules/slick-carousel/slick/slick.css"; 
import "../../../node_modules/slick-carousel/slick/slick-theme.css";
import "./slider.module.css"; 

const slide = [
  {
    img: require('../../assets/b_1.jpg'),
    show: {
      'b090de0b-8550-6e17-70b2-bbba152bcbd3': true,
      '8b5637dd-2372-4f8e-9b4d-b360f44d633d': true
    }
  },
  {
    img: require('../../assets/b_2.jpg'),
    show: {
      'b090de0b-8550-6e17-70b2-bbba152bcbd3': true,
      '8b5637dd-2372-4f8e-9b4d-b360f44d633d': false
    }
  },
  {
    img: require('../../assets/b_8.jpg'),
    show: {
      'b090de0b-8550-6e17-70b2-bbba152bcbd3': true,
      '8b5637dd-2372-4f8e-9b4d-b360f44d633d': false
    }
  },
  {
    img: require('../../assets/b_9.jpg'),
    show: {
      'b090de0b-8550-6e17-70b2-bbba152bcbd3': true,
      '8b5637dd-2372-4f8e-9b4d-b360f44d633d': true
    }
  },
  {
    img: require('../../assets/b_10.jpg'),
    show: {
      'b090de0b-8550-6e17-70b2-bbba152bcbd3': true,
      '8b5637dd-2372-4f8e-9b4d-b360f44d633d': true
    }
  }
]


function Arrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={s.arrow}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
    />
  );
}

class SliderContainer extends Component{

  constructor(){
    super();

    this.state = {
      width: null,
      currentSlide: 0
    }

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

  }

  componentDidMount(){
    //this.updateWindowDimensions();
   // window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth});
  }

  render(){
    let settings = {
      dots: true,
      dotsClass: s.button__bar,
      infinite: true,
      lazyLoad: true,
      autoplay: true,
      autoplaySpeed: 3000,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: <Arrow/>,
      prevArrow: <Arrow/>,
      responsive: [
        {
          breakpoint: 600,
          settings: {
            arrows: false
          }
        }
      ],
      beforeChange: (prev, next) => {
        this.setState({ currentSlide: next });
      },
      appendDots: dots => {
        return (
            <ul>
              {dots.map((item, index) => {
                return (
                  <li key={index}>{item.props.children}</li>
                );
              })}
            </ul>
        )
      },
      customPaging: index => {
        return (
          <button className={index === this.state.currentSlide ? s.slick_active : null}>
            {index + 1}
          </button>
        )
      }
    };

  const WrapSlider = ({ productId, children, key }) => {
    if (productId) {
      return <Link key={key} to={`/menu/product/${productId}`}>{children}</Link>
    } else {
      return <div key={key}>{children}</div>
    }
  }

  const banners = this.props.slider
    .filter(it => this.props.activeCity.id === it.spotId)
    .sort((a, b) => Number(a.order) - Number(b.order));

  console.log('banners', this.props.slider);

  return(
      <div className={s.sliderContainer}>
        {banners.length === 1 && 
          banners.map((banner, index) => (
            <div key={index} className={s.slideContainer}>
              <div className={s.slide}>
                <img className={s.image} src={banner.photo} alt="banner" /> 
              </div>
            </div>
          ))
        }
        {banners.length > 1 && (
          <Slider {...settings} className={s.slider}>
            {banners.map((item, index) => {
                return(
                  <WrapSlider productId={item.productId} key={index}>
                    <div className={s.slideContainer}>
                      <div className={s.slide}>
                        <img src={item.photo} alt="banner" /> 
                      </div>
                    </div>
                  </WrapSlider>
                )
            })}
          </Slider>
        )}
      </div>
  )
  }
}

export default SliderContainer;