import React, {Component} from 'react';
import ReactPixel from 'react-facebook-pixel';
// import TagManager from 'react-gtm-module';

import {connect} from 'react-redux';
import {getMenu, updateActiveCity, updateIsHomePage} from './redux/menu';
import {getActiveCity, getCookie} from './components/modalCities/cookie';
import {changeCity, isCityName} from './functions';
import Preload from './components/preloader/preloaderFull';

import Router from './scenes/Router/routerContainer';

class App extends Component{

  constructor(){
    super();
    this.state = {
      preloader: true
    }
  }

  componentDidMount(){
    // let city = localStorage.getItem('city');
    let cityCookieId = getCookie('city');
    let city = this.props.cities.find(it => it.id === cityCookieId);
    let isHomePage = false;

    let host = window.location.host;
    let parts = host.split(".");
    let path = window.location.pathname;
    let title = window.location.pathname.split('/')[2];
    title = title ? title : document.title;

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      originalLocation: document.location.protocol + '//' +
                        document.location.hostname +
                        document.location.pathname +
                        document.location.search
    });

    window.dataLayer.push({
      event: 'pageview',
      page: { path, title }
    });

    if(isCityName(parts[0])){
      this.props.updateActiveCity(changeCity(parts[0]));
      this.props.updateIsHomePage(false);
    } else {
    if(city){
      this.props.updateActiveCity(getActiveCity(city));
      this.props.updateIsHomePage(false);

    } else {
        let city = {
          name: {uk: 'Дніпро', ru: 'Днепр'},
          id: '6bf17b9e-3ecd-0491-0179-0e815f2c8b26',
          // name: {uk: 'Київ', ru: 'Киев'},
          // id: '6bf17b9e-3ecd-0491-0179-0e815f2c9df9',
          // gtm: 'GTM-TMWF9DP'
        };
        this.props.updateActiveCity(city);
        this.props.updateIsHomePage(true);
        isHomePage = true;
    };
  }

    const activeCity = this.props.cities.find(t => {
      let cityId = '';

      if (isCityName(parts[0])) {
        cityId = changeCity(parts[0]).id;
      } else if (city) {
        cityId = getActiveCity(city).id;
      } else {
        cityId = '6bf17b9e-3ecd-0491-0179-0e815f2c9df9';
      }

      return t.id === cityId;
    });
    
    // console.log('activeCity --->>>', getActiveCity(activeCity.id));

    if (getActiveCity(activeCity.id)) {
          if (isHomePage) {
            ReactPixel.init('215221547289690');
            ReactPixel.pageView();
          }

          if (!isHomePage) {
              const isTargetCity = activeCity.id === '6bf17b9e-3ecd-0491-0179-0e815f2c9df9'|| activeCity.id === '6bf17b9e-3ecd-0491-0179-0e815f2c8b26' || activeCity.id === 'fe74eb1c-055f-4767-b1e6-1a8d7df54070';

              console.log('activeCity.id -->>>', activeCity.id);
              
              if (isTargetCity) {
                  ReactPixel.init('5129251417150258');
                  ReactPixel.pageView();
              } else {
                  ReactPixel.init('215221547289690');
                  ReactPixel.pageView();
              }
          }
      }

    this.props.getMenu(activeCity)
      .then(res => {
       if(res === true){
         this.setState({
          preloader: false
         })
       }
      });

  }

  render(){
    return (
      <>
      {this.state.preloader ? <Preload/> : <Router/>}
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    cities: state.menu.cities,
  }
}

export default connect(mapStateToProps, {getMenu, updateActiveCity, updateIsHomePage})(App);
