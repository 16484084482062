import React from 'react';
import './article.style.css';

const ArticleRu = () => {
    return (
        <div className="mislivska">
            <p>Пицца Охотничья 30 см – лучшая «добыча» для любителей итальянской кухни. Также эта выпечка придется по вкусу каждому, кто любит пикантную еду. А где самая вкусная, аппетитная пицца Охотничья 30 см? Конечно же в IQ Pizza! Мы приготовим вам вкуснейший острый перекус из нашего меню и доставим его горячим на удобное время и адрес! </p>
            <p>Что входит в состав: </p>
            <ul>
                <li>соус томатный острый;</li>
                <li>сыр "Моцарелла";</li>
                <li>лук маринованный;</li>
                <li>колбаски;</li>
                <li>шампиньоны;</li>
                <li>халапеньо;</li>
                <li>чесночное масло.</li>
            </ul>
            <p>Пицца станет еще лучше, если добавите на тесто дополнительные ингредиенты. К такому блюду отлично подойдут маринованные огурцы, маслины и начинка с грибами. Вы можете дополнить свой обед так, как вашей душе угодно! Также не забудьте про наполнители для бортиков: сырный или сосисочный бортик. </p>
            <p>Хотите заказать охотничий обед? Это просто! Мы принимаем заказ на доставку по номеру телефона 068 115 1010, через сайт, приложение для смартфонов. Сделать заявку можете каждый день, с 10:00 до 22:00. Ваша пицца с аппетитной начинкой уже ждет встречи с вами! </p>
        </div>
    );
}

export default ArticleRu;