import React, {useState} from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { useTranslation, withTranslation } from "react-i18next";

// If you want to use the provided css
import 'react-google-places-autocomplete/dist/index.min.css';
import s from './formControls.module.css';


 const Component = ({input, meta, ...props}) => {
  const { t, i18n } = useTranslation();
  var value = input.value;

  let [list, setList] = useState([]);
  let [text, setText] = useState('');

  const search = (name) => {
    var results;
    name = name.toUpperCase();
    results = props.streets.streets.filter(function(entry) {
        return entry.name.toUpperCase().indexOf(name) !== -1;
    });
    setList(results);
  }

      return(
        <div className={s.autocompleteContainer}>
          <div className={s.inputWrapper}>
              <input
                {...props}
                onChange={(e) => {
                  setText(e.target.value);
                  if(e.target.value.length > 2){
                    search(e.target.value);
                  } else if (e.target.value.length < 2 && e.target.value.length > 0) {
                    setList([]);
                  }
                }/*input.onChange(e.target.value)*/}
                value={text}
                placeholder={t('searchStreet')}
              />
              {meta.touched && (meta.error && <span>{meta.error}</span>)}
          </div>
         {list.length > 0 && <div className={s.suggestionsContainer}>
              {
                list.map((suggestion, index) => (
                  <div
                    key={index}
                    className={s.suggestion}
                    onClick={(event) => {/*onSelectSuggestion(suggestion.name, event);*/
                                          setList([]);
                                          setText(suggestion.name);
                                          input.onChange(suggestion.name)}}
                                          
                  >
                    {suggestion.name}
                  </div>
                ))
              }
            </div>} 
       {/** <GooglePlacesAutocomplete
          //onSelect={(res) => props.input.onChange(res.description)}
          autocompletionRequest = {{
              location: props.city.location,
              radius: 15000,
          }
          }
          renderInput={(props) => (
            <div className={s.inputWrapper}>
              <input
                {...props}
                //onChange={(e) => input.onChange(e.target.value)}
                //value={value}
              />
              {meta.touched && (meta.error && <span>{meta.error}</span>)}
            </div>
          )}
          suggestionsClassNames={{container: 'custom-container-classname'}}
          placeholder={props.label}
          renderSuggestions={(active, suggestions, onSelectSuggestion) => (
            <div className={s.suggestionsContainer}>
              {
                suggestions.map((suggestion, index) => (
                  <div
                    key={index}
                    className={s.suggestion}
                    onClick={(event) => {onSelectSuggestion(suggestion, event);
                                          input.onChange(suggestion.structured_formatting.main_text)}}
                  >
                    {suggestion.description}
                  </div>
                ))
              }
            </div>
          )}
        /> */}
      </div>
      )
    };
  
  export default Component;