import React, {Component} from 'react';
import {connect} from 'react-redux';
import { withRouter, Redirect } from "react-router";
import ReactPixel from 'react-facebook-pixel';
import {updateProduct, updateModifire, removeModifire, updateGroupModifire,
    removeGroupModifire} from '../../redux/menu';

import Product from './product';

class ProductContainer extends Component{

    componentDidMount() {
        window.scroll(0, 0);
    }

    updateProductByFacebookPixel = (type, id, ...rest) => {
        // const activeCity = this.props.activeCity;
        // const isTargetCity = activeCity.id === '6bf17b9e-3ecd-0491-0179-0e815f2c9df9'|| activeCity.id === '6bf17b9e-3ecd-0491-0179-0e815f2c8b26' || activeCity.id === 'fe74eb1c-055f-4767-b1e6-1a8d7df54070';
        const product = this.props.products.find(item => item['id'] === id);

        const reactPixelData = {
            value: product.price,
            currency: 'UAH',
            content_ids: product.id,
            content_type: 'product'
        };
        
        console.log('AddToCart ==>>', reactPixelData);
        console.log('type ==>>', type);
        
        if (type === 'increment') {
            ReactPixel.track('AddToCart', reactPixelData);
        }

        return this.props.updateProduct(type, id, ...rest);
    }

    render(){
        let product = this.props.products.find(item => item['url'] === this.props.match.params.id);

        return( 
           product ?  <Product item={product} languageMenu={this.props.languageMenu} 
            updateProduct={this.updateProductByFacebookPixel} updateModifire={this.props.updateModifire} 
            removeModifire={this.props.removeModifire} updateGroupModifire={this.props.updateGroupModifire}
            removeGroupModifire={this.props.removeGroupModifire}/> :
                <Redirect to='/'/> 
        )
    }
}

const matStateToProps = (state) => {
    return {
        products: state.menu.products,
        categories: state.menu.categories,
        basket: state.menu.basket,
        languageMenu: state.menu.languageMenu,
        activeCity: state.menu.activeCity,
    }
}

export default connect(matStateToProps, {updateProduct, updateModifire, 
    removeModifire, updateGroupModifire, removeGroupModifire})(withRouter(ProductContainer));