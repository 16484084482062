import React from 'react';
import { useTranslation } from "react-i18next";

const Napitki = () => {
	 const { t, i18n } = useTranslation(); 
    return (
        <div style={{
            height: 400,
            overflowY: 'scroll',
        }}>
            <h1>Напої до піци</h1>
            <p>Що може доповнити смачний, ситний обід, щоб він став краще? Звичайно ж напої до піци! З прохолодною газованою водою обід їдять з ще більшим задоволенням.</p>
            <p>Лінь або немає часу йти в магазин за водою, поки чекаєте доставку їжі? Замовте алкогольні, безалкогольні напої до піци від IQ Pizza! Ми привеземо вашу улюблену содову, на дім або на роботу, разом з випічкою. Доставка проводиться швидко, на будь-який зручний час і адресу. З нами ви зможете подати апетитне частування вчасно, досхочу нагодувавши будь-яку компанію.</p>
            <h2>Які напої підходять до піци</h2>
            <p>Наша піцерія пропонує величезний асортимент вод, які ідеально поєднуються з ситною, гарячою випічкою і підкреслюють її насичений смак. У нашому меню клієнти знайдуть і зможуть замовити свої улюблені напої до піци.</p>
            <p>Ми пропонуємо великий вибір вод, якими ви зможете запивати улюблене блюдо:</p>
            <ul>
                <li>алкогольні - холодне пиво, ель від нашого партнера Hop Hey Beer Shop;</li>
                <li>безалкогольні - смачний хлібний квас, лимонад, сік, солодкі содові (7 UP, Мерінда, Пепсі).</li>
            </ul>
            <p>У нас ви зможете вибрати і купити найкращі класичні газовані води для себе і для друзів. Води з нашого меню ідеально пасують до улюбленої випічки. Слідкуєте за своїм гідробалансом? Замовте корисну мінеральну воду на обід. Неважливо, які содові вам подобаються. Ви точно знайдете їх у нас.</p>
            <p>Побачити повний асортимент і дізнатися про вартість газованої води, яка ідеально поєднується з гарячим частуванням, ви зможете на нашому офіційному сайті або мобільному додатку, в пункті меню «Напої».</p>
            <p>Смачна, гаряча випічка та напої до піци від IQ Pizza вже чекають на вас. Швидше робіть замовлення і насолоджуйтеся!</p>
        </div>
    );
}

export default Napitki;