import React, { useState, useEffect } from 'react';

import {Helmet} from 'react-helmet';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import s from './product.module.css';
import classes from './product.module.css';
import logo from '../../assets/logo.png';
import close from '../../assets/close.svg';

import ReactPixel from 'react-facebook-pixel';

import ArticleChisburger30Ru from './articles/chizburger-30-sm/article/ru';
import ArticleChisburger30Uk from './articles/chizburger-30-sm/article/uk';

import ArticleBBQ30Ru from './articles/m-yasna-bbq-30-sm/article/ru';
import ArticleBBQ30Uk from './articles/m-yasna-bbq-30-sm/article/uk';

import ArticleCheckenWithCheese30Ru from './articles/sirne-kurcha-30-sm/article/ru';
import ArticleCheckenWithCheese30Ua from './articles/sirne-kurcha-30-sm/article/uk';

import ArticlePeperoni30Ru from './articles/peperoni-30-sm/article/ru';
import ArticlePeperoni30Ua from './articles/peperoni-30-sm/article/uk';

import ArticleMargarita30Ru from './articles/margarita-30-sm/article/ru';
import ArticleMargarita30Ua from './articles/margarita-30-sm/article/uk';

import Article4Siri30Ru from './articles/4-siri-30-sm/article/ru';
import Article4Siri30Ua from './articles/4-siri-30-sm/article/uk';

import ArticleGavayska30Ru from './articles/gavayska-30-sm/article/ru';
import ArticleGavayska30Ua from './articles/gavayska-30-sm/article/uk';

import ArticleMislivska30Ru from './articles/mislivska-30-sm/article/ru';
import ArticleMislivska30Ua from './articles/mislivska-30-sm/article/uk';

import Article4Myasa30Ru from './articles/4-m-yasa-30-sm/article/ru';
import Article4Myasa30Ua from './articles/4-m-yasa-30-sm/article/uk';

const ProductComponent = (props) => {
    const { t, i18n } = useTranslation();
    const [openModalFullImage, setOpenModalFullImage] = useState(false);

    useEffect(() => {
        // const isTargetCity = activeCity.id === '6bf17b9e-3ecd-0491-0179-0e815f2c9df9'|| activeCity.id === '6bf17b9e-3ecd-0491-0179-0e815f2c8b26' || activeCity.id === 'fe74eb1c-055f-4767-b1e6-1a8d7df54070';
        const ViewContent = {
            content_ids: props.item.id,
            content_type: 'product'
        };
        ReactPixel.track('ViewContent', ViewContent);
    }, []);

    const cities = useSelector(state => state.menu.cities);
    const lng = useSelector(state => state.menu.languageMenu);
    const activeCity = useSelector(state => state.menu.activeCity);
    const city = cities.find(t => t.id === activeCity.id);
    
    const groupModifiers = props.item.groupModifiers;
    const validationModifier = groupModifiers.find(t => t.minAmount > 0);
    let minAmount = 0;

    if (validationModifier && validationModifier.minAmount) {
        validationModifier.childModifiers.map(childModifier => {
            minAmount += childModifier.amount;
        });

        if (minAmount === 0) {
            validationModifier.childModifiers[0].amount = 1;
        }
    }

    const images = [];
    props.item.images.forEach(el => images.push(el.imageUrl));


    const showChildModifiers = (data, id, modifierId) => {
        return(
            data.map((item, index) => {
                return(
                    <div className={classes.modifier} key={index}>
                        <div>
                        <input type="checkbox" onChange={() => {
                                                            item.amount === 0 ? props.updateGroupModifire('increment', id, modifierId, item.modifierId) 
                                                            : 
                                                            props.removeGroupModifire(id, modifierId, item.modifierId);
                                                         }} id={item.modifierId} name={item.modifierId} value={item.modifierId}
                                                         checked={item.amount > 0 ? true : false}/>
                         <label htmlFor={item.modifierId}> {item.name} ({item.price}грн.)</label>
                        </div>
                        {item.amount > 0 &&  <div className={classes.modifierControl}>
                            <div className={s.minus} onClick={() => props.updateGroupModifire('decrement', id, modifierId, item.modifierId)}></div>
                                <div className={s.result}>{item.amount}</div>
                            <div className={s.plus} onClick={() => props.updateGroupModifire('increment', id, modifierId, item.modifierId)}></div>
                        </div>}
                    </div>
                )
            })
        )
    }

    const getSum = () => {
        let sum = 0;
            let price = props.item.price;
            let count = props.item.count;
            let modifierSum = 0;

            if (city && city.terminals) {
                props.item.differentPricesOn.map(t => {
                    city.terminals.map(terminal => {
                        if (terminal.id === t.terminalId) {
                            price = t.price;
                        }
                    })
                });
            }

            props.item.modifiers.map(a => modifierSum += a.price*a.amount);
            props.item.groupModifiers.map(a => a.childModifiers.map(b => {
                modifierSum += b.price*b.amount
            }));
            count = !!count ? count : 1;
            sum += ((modifierSum*10+price*10) * count)/10;   
            return sum;
    }

    const handleChangeSizeToImage = () => {
        setOpenModalFullImage(!openModalFullImage);
    }

    const getSeoData = ({ id, name }, lng) => {
        const getFirstText = (first, second) => lng === 'uk' ? first : second;
        switch(id) {
            case '26b86f57-4edb-443d-b722-ba8671037d25':
                return {
                    title: t('titleProduct-id-26b86f57-4edb-443d-b722-ba8671037d25'), 
                    description: t('descriptionProduct-id-26b86f57-4edb-443d-b722-ba8671037d25'), 
                    firstTitle: getFirstText('🍕 Піца Карбонара 30 см 🍕 | ', '🍕 Пицца Карбонара 30 см 🍕 |'),
                    firstDescription: getFirstText('Замовити піцу Карбонара діаметром 30 см для компанії ', 'Заказать пиццу Карбонара диаметром 30 см для компании'),
                };
            default:
                return {
                    title: t('titleProduct-id-default'), 
                    description: t('descriptionProduct-id-default'), 
                    firstTitle: getFirstText(`🍕 Піца ${name} 🍕 | `, `🍕 Пицца ${name} 🍕 |`),
                    firstDescription: getFirstText(`Замовити піцу ${name} для компанії`, `Заказать пиццу ${name} для компании`),
                };
        }
    }

    const getArticle = (url, lng) => {
        switch(url) {
            case 'chizburger-30-sm':
                return lng === 'uk' ? <ArticleChisburger30Uk /> : <ArticleChisburger30Ru />;
            case 'm-yasna-bbq-30-sm':
                return lng === 'uk' ? <ArticleBBQ30Uk /> : <ArticleBBQ30Ru />;
            case 'sirne-kurcha-30-sm':
                return lng === 'uk' ? <ArticleCheckenWithCheese30Ua /> : <ArticleCheckenWithCheese30Ru />;
            case 'peperoni-30-sm':
                return lng === 'uk' ? <ArticlePeperoni30Ua /> : <ArticlePeperoni30Ru />;
            case 'margarita-30-sm':
                return lng === 'uk' ? <ArticleMargarita30Ua /> : <ArticleMargarita30Ru />;
            case '4-siri-30-sm':
                return lng === 'uk' ? <Article4Siri30Ua /> : <Article4Siri30Ru />;
            case 'gavayska-30-sm':
                return lng === 'uk' ? <ArticleGavayska30Ua /> : <ArticleGavayska30Ru />;
            case 'mislivska-30-sm':
                return lng === 'uk' ? <ArticleMislivska30Ua /> : <ArticleMislivska30Ru />;
            case '4-m-yasa-30-sm':
                return lng === 'uk' ? <Article4Myasa30Ua /> : <Article4Myasa30Ru />;
            default:
                return null;
        }
    }

    const { title, description, firstTitle, firstDescription } = getSeoData(props.item, lng);
    const titleSEO = firstTitle + ` ${city.name[lng]} ` + title;
    const descriptionSEO = firstDescription + ` ${city.name[lng]} ` + description;
    const baseUrl = window.origin;
    const canonicalUrl = baseUrl + props.match.url;

    // console.log('titleSEO', titleSEO);
    // console.log('descriptionSEO', descriptionSEO);
    // console.log('props.item', props.item);
    // console.log('---  Product  ---', props.item.url, props.match.params.id);

    console.log('--- >>>', props);

    return(
        <>
            {openModalFullImage && <div 
                className={s.imageFullSizeAlert}
                style={{
                    backgroundImage: props.item.images !== [] && props.item.images.length > 0 
                    ? `url(${props.item.images[props.item.images.length-1].imageUrl})` 
                    : `url(${logo})`
                }}
            >
                <div onClick={handleChangeSizeToImage} className={s.close} style={{ backgroundImage: `url(${close})` }}></div>
            </div>}
            <div className='container'>
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="robots" content="noindex, nofollow"/>
                <title>{titleSEO}</title>
                <meta name="description" content={descriptionSEO} />
                <link rel="canonical" href={canonicalUrl} />
            </Helmet>
            <div className='row'>
                <div className={s.productContainer}>
                   <div className={s.imgContainer}>
                        <div 
                            onClick={handleChangeSizeToImage}
                            className={s.product_img} 
                             style={{
                                 backgroundImage: props.item.images !== [] && props.item.images.length > 0 
                                    ? `url(${props.item.images[props.item.images.length-1].imageUrl})` 
                                    : `url(${logo})`
                                }}
                        />
                    </div>
                    <div className={s.infoContainer}>
                        <div className={s.rowInfo}>
                            <h1 className={s.title}>{props.item['name']} {props.item['weight'] > 0 && `(${Math.round(props.item['weight']*1000)}г.)`}</h1>
                            <div className={s.description}>{props.item['description']}</div>
                            <div className={classes.modifiers}>
                            {props.item.groupModifiers.map((item, index) => {
                                return(
                                    <div className={classes.groupModifiers} key={index}>
                                        <span className={classes.nameGroup}>{item.name}</span>
                                            {showChildModifiers(item.childModifiers, props.item.id, item.modifierId)}
                                    </div>
                                )
                            })}
                            
                            {props.item.modifiers.map((item, index) => {
                                return(
                                    <div className={classes.modifier} key={index}>
                                        <div>
                                            <input type="checkbox" onChange={() => {
                                            item.amount === 0 ? props.updateModifire('increment', props.item['id'], item.modifierId) 
                                            : 
                                            props.removeModifire(props.item['id'], item.modifierId);
                                            }} id={item.modifierId} name={item.modifierId} value={item.modifierId}
                                            checked={item.amount > 0 ? true : false}/>
                                        <label htmlFor={item.modifierId}> {item.name} ({item.price}грн.)</label>
                                        </div>
                                        {item.amount > 0 && <div className={classes.modifierControl}>
                                            <div className={s.minus} onClick={() => props.updateModifire('decrement', props.item['id'], item.modifierId)}></div>
                                            <div className={s.result}>{item.amount}</div>
                                            <div className={s.plus} onClick={() => props.updateModifire('increment', props.item['id'], item.modifierId)}></div>
                                        </div>}
                                    </div>
                                    )
                            })}
                        </div>
                            <div className={s.product_footer}>
                            <div className={s.price}>
                                <span>{getSum()}</span> ₴
                            </div>
                            <div className={s.add_container}>
                                {props.item.count > 0 ? <div className={[`${s.count_product} ${s.count}`]}>
                                    <div className={s.minus} onClick={() => props.updateProduct('decrement', props.item['id'])}></div>
                                        <div className={s.result}>{props.item.count}</div>
                                    <div className={s.plus} onClick={() => props.updateProduct('increment', props.item['id'])}></div>
                                </div> :  <div onClick={() => props.updateProduct('increment', props.item['id'])} className={s.add_product}>
                                    <span>{t('add')}</span>
                                 </div>}
                            </div>
                        </div>
                        
                        </div>
                        
                        {/* <div className={s.productCalories}>
                            <div className={s.productCaloriesItem}><div className={s.productCaloriesCount}>{Math.round(props.item.weight * 1000)}</div>Вага, г.</div>
                            <div className={s.productCaloriesItem}><div className={s.productCaloriesCount}>{Math.round(props.item.fiberFullAmount).toFixed(0)}</div>Білки, г.</div>
                            <div className={s.productCaloriesItem}><div className={s.productCaloriesCount}>{Math.round(props.item.fatFullAmount)}</div>Жири, г.</div>
                            <div className={s.productCaloriesItem}><div className={s.productCaloriesCount}>{Math.round(props.item.carbohydrateFullAmount).toFixed(0)}</div>Вуглеводи, г.</div>
                            <div className={s.productCaloriesItem}><div className={s.productCaloriesCount}>{Math.round(props.item.energyFullAmount).toFixed(0)}</div>Ккал</div>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
        <div className="container">
            <div className="row">
                {getArticle(props.item.url, lng)}
            </div>
        </div>
        </>
    )
};

export default withRouter(ProductComponent);