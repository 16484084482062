import React from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import s from './blog.module.css';

const Blog = () => {
    const { t, i18n } = useTranslation(); 
    const lng = useSelector(state => state.menu.languageMenu);
    const isHomePage = useSelector(state => state.menu.isHomePage);
    const host = !isHomePage ? window.location.host.split('.')[0] + '.': '';
    const canonicalUrl = lng === 'uk' ? `https://${host}iqpizza.com.ua/blog/` : `https://${host}iqpizza.com.ua/ru/blog/`;
    // let url = lng === 'uk' ? `https://iqpizza.com.ua/blog/` : `https://iqpizza.com.ua/ru/blog/`;
    return (
        <div className={s.aboutUs}>
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="robots" content="noindex, nofollow"/>
                <title>{t('blogTitle')}</title>
                <meta name="description" content={t('blogDescription')} />
                <link rel="canonical" href={canonicalUrl} />
                <link rel="alternate" hreflang="x-default" href={`https://${host}iqpizza.com.ua/blog/`} />
                <link rel="alternate" hreflang="ru" href={`https://${host}iqpizza.com.ua/ru/blog/`} />
            </Helmet>
            <div className="container">
                <div className="row">
                        <h3>{t('blog')}</h3>
                    </div>
            </div>
            <div>
                    <div className="row">
                        <div className={s.container_wrap}>
                            <div className={s.delivery_title}></div>
                            <div className={s.delivery_content} style={{ height: 300 }}></div>
                        </div>
                    </div>
            </div>
        </div>
    )
}

export default Blog;