import {combineReducers, createStore, applyMiddleware} from 'redux';
import  thunkMiddleware from 'redux-thunk';
import { reducer as formReducer } from 'redux-form'

import menu from './menu';
import firebase from './firebase';
import modifiers from './modifiers';

let reducers = combineReducers({
    form: formReducer,
    menu,
    modifiers,
    firebase,
})

const store = createStore(reducers, applyMiddleware(thunkMiddleware));

export default store;