import React from 'react';
import './article.style.css';

const ArticleUa = () => {
    return (
        <div className="about-us">
            <h1>ПИЦЦА СЫРНЫЙ ЦЫПЛЕНОК</h1>
            <p>Пицца Сырный цыпленок 30 см – нежнейшая и вкуснейшая выпечка с курицей, которая вам точно понравится! Аппетитное куриное мясо под хрустящей корочкой. Слюнки уже потекли? Тогда скорее заказывайте это угощение от IQ Pizza!</p>
            <p>Варианты начинки:</p>
            <ul>
                <li>соус сырный;</li>
                <li>"Моцарелла"; </li>
                <li>"Гауда"; </li>
                <li>цыплёнок.</li>
            </ul>
            <p>Чтобы пицца Сырный цыпленок 30 см стала еще вкуснее, добавьте на тесто дополнительные ингредиенты. Выпечку могут дополнить: сыр твердый, мясо, ананасы, оливки, зелень и многое другое! Также не забудьте добавить наполнители в бортик себе по вкусу: сыр, сосиски. </p>
            <p>Заказать любимое блюдо можно каждый день, с 10:00 до 22:00.</p>
            <p>Как оформить доставку:</p>
            <ul>
                <li>телефон 068 115 1010;</li>
                <li>сайт;</li>
                <li>мобильное приложение. </li>
            </ul>
            <p>С подробностями курьерской службы вы можете ознакомиться в пункте меню «Доставка».</p>
        </div>
    );
}

export default ArticleUa;