import React from 'react';
import './article.style.css';

const ArticleUa = () => {
    return (
        <div className="public-offer">
            <h1>Договір публічної оферти</h1>
            <ol>
                <li>
                    <h2>ЗАГАЛЬНІ ПОЛОЖЕННЯ</h2>
                    <p>Інтернет-магазин www.iqpizza.com.ua (далі - Продавець) публікує цей договір, який є публічним договором-офертою (далі Договір) про купівлю-продаж товару дистанційним способом.</p>
                    <p>Договір визначає порядок ведення Продавцем продажу товару через Інтернет-магазин відповідно до ст. 633, 641, 642 Цивільного кодексу України (далі ЦКУ), Правилами продажу товарів на замовлення та поза торгівельними або офісними приміщеннями, затверджених наказом Міністерства економіки України від 19.04.2007 № 103 та є публічною офертою Продавця, що адресується невизначеному колу, як фізичних, так і юридичних осіб (далі Покупець).</p>
                    <p>Факт скоєння Покупцем замовлення, в усній (по телефону) або письмовій (через сайт) формі, і отримання даного замовлення Продавцем - є повним і безумовним прийняттям даного публічного договору та інформації, викладеної на сайті.</p>
                    <p>Продавець і Покупець гарантують, що володіють необхідною право- і дієздатністю, а також всіма правами і повноваженнями, необхідними і достатніми для укладання та виконання Договору.</p>
                    <p>На підставі вищевикладеного, уважно ознайомтеся з текстом публічної оферти, і якщо Ви не згодні з будь-яким пунктом оферти, Вам пропонується відмовитися від покупки товарів і послуг, що надаються Продавцем.</p>
                    <p>У цьому Договорі, якщо зміст не вимагає іншого, наведені нижче терміни слід тлумачити таким чином:</p>
                    <p>«Оферта» - публічна пропозиція Продавця, адресована будь-якій фізичній та юридичній особі, з метою укласти договір купівлі-продажу, на існуючих умовах, зазначених в Договорі.</p>
                    <p>«Товар» - перелік найменувань асортименту, представлений на офіційному сайті Продавця.</p>
                    <p>«Продавець» - організація, незалежно від її організаційно-правової форми та / або фізична особа підприємець, який здійснює продаж товару, представленого в інтернет-магазині.</p>
                    <p>«Покупець» - фізична або юридична особа, вступили в договірні відносини з Продавцем на умовах, зазначених в Договорі.</p>
                    <p>«Замовлення» - окремі позиції з асортиментного переліку товару, зазначені Покупцем при розміщенні заявки на інтернет-сайті Продавця.</p>
                    <p>«Кур'єрська доставка» - безпосередня передача товару від продавця до покупця незалежним підрядником, який надає послуги Продавцю відповідно до окремого укладеного договору. Продавець не здійснює самостійно доставку товару.</p>
                    <p>«Інтернет-магазин» - веб-сторінка продавця www.iqpizza.com.ua</p>
                </li>
                <li>
                    <h2>ПРЕДМЕТ ДОГОВОРУ</h2>
                    <ol>
                        <li>Цей Договір є офіційним документом Продавця і невід'ємною частиною оферти.</li>
                        <li>Продавець продає товар відповідно до чинних на момент здійснення замовлення цінами, а Покупець здійснює оплату і приймає товар відповідно до умов цього Договору.</li>
                        <li>Договір регулює купівлю-продаж товарів в Інтернет-магазині, в тому числі:
                            <ul>
                                <li>добровільний вибір Покупцем товарів в Інтернет-магазині;</li>
                                <li>самостійне оформлення Покупцем замовлення в Інтернет-магазині;</li>
                                <li>оплата Покупцем замовлення, оформленого в Інтернет-магазині;</li>
                                <li>обробка і доставка замовлення Покупцеві у власність на умовах цього Договору.</li>
                            </ul>
                        </li>
                    </ol>
                </li>
                <li>
                    <h2>ПОРЯДОК ОФОРМЛЕННЯ ЗАМОВЛЕННЯ</h2>
                    <ol>
                        <li>Замовлення Покупця може бути оформлено через оператора або за допомогою заповнення електронної форми замовлення на сайті Інтернет-магазину.</li>
                        <li>Покупець має право оформити замовлення на будь-який товар, представлений на сайті Інтернет-магазину і наявний.</li>
                        <li>При відсутності товару, Покупець має право замінити його аналогічним товаром, або відмовитися від даного товару, анулювати замовлення.</li>
                        <li>При оформленні замовлення через оператора або за допомогою електронної форми на сайті Інтернет-магазину, Покупець тим самим підтверджує, що він ознайомлений з умовами даної оферти і зобов'язується надати Продавцю всю інформацію, необхідну для належного оформлення і виконання Замовлення.</li>
                        <li>При оформленні замовлення через оператора або за допомогою електронної форми на сайті Інтернет-магазину, Покупець зобов'язується надати наступну реєстраційну інформацію про себе: ім'я, фактична адреса доставки, контактний телефон, e-mail та іншу інформацію, зазначену в реєстраційній формі на сайті.</li>
                        <li>Продавець не несе відповідальності за зміст і достовірність інформації, наданої Покупцем при оформленні замовлення.</li>
                        <li>Покупець несе відповідальність за достовірність наданої інформації при оформленні замовлення.</li>
                        <li>Договір купівлі-продажу дистанційним способом між Продавцем і Покупцем вважається укладеним з моменту електронного оформлення замовлення на сервісі сайту Інтернет-магазину www.де-шашлік.com.ua або видачі Продавцем Покупцеві касового або товарного чека або іншого документа, що підтверджує оплату товару.</li>
                    </ol>
                </li>
                <li>
                    <h2>ЦІНА ТОВАРУ</h2>
                    <ol>
                        <li>Ціни в інтернет-магазині вказані в національній валюті України (гривня) за одиницю товару відповідно до існуючого прейскурантом.</li>
                        <li>Продавець залишає за собою право змінювати ціну товару до моменту оформлення замовлення без попередження Покупця.</li>
                        <li>Остаточною є ціна, зазначена оператором при оформленні замовлення.</li>
                        <li>Ціни, зазначені на сайті www.iqpizza.com.ua, носять ознайомчий характер.</li>
                    </ol>
                </li>
                <li>
                    <h2>ОПЛАТА ТОВАРУ</h2>
                    <ol>
                        <li>При готівковій формі оплати Покупець зобов'язаний сплатити Продавцю ціну товару в момент його передачі, якщо інше не передбачено домовленістю або договором між сторонами.</li>
                        <li>Оплата товарів та послуг інтернет-магазину www.iqpizza.com.ua здійснюється в національній валюті України відповідно до цін і тарифів, встановлених на момент покупки товарів.</li>
                        <li>Оплата товарів Інтернет-магазину здійснюється відповідно до розділу «Оплата та доставка», опублікованому на сайті www.iqpizza.com.ua. Оплата зараховується тільки за умови дотримання порядку, опублікованому на сайті.</li>
                        <li>У разі несплати, неповної або несвоєчасної оплати Інтернет-магазин залишає за собою право ненадання товару, призупинення або повного невиконання взятих на себе зобов'язань і при цьому не несе відповідальності за можливі наслідки такого рішення / дій.</li>
                        <li>При безготівковій формі оплати обов'язок Покупця по сплаті вартості товару вважається виконаним з моменту зарахування відповідних грошових коштів на розрахунковий рахунок Продавця.</li>
                        <li>При безготівковій формі розрахунку Покупець зобов'язаний пред'явити оператору або кур'єру документ, що підтверджує факт оплати товару (платіжне доручення, квитанція про сплату т.д.).</li>
                        <li>Ціни на будь-які позиції товару можуть бути змінені Продавцем в односторонньому порядку. У разі зміни ціни на замовлення позиції товару до оплати замовлення, Продавець зобов'язаний в найкоротші терміни повідомити Покупця про такі зміни. Покупець має право підтвердити або анулювати замовлення. У разі відсутності зв'язку з Покупцем замовлення вважається анульованим протягом 24-х годин з моменту зміни ціни на товар.</li>
                    </ol>
                </li>
                <li>
                    <h2>ДОСТАВКА ТОВАРУ</h2>
                    <ol>
                        <li>Порядок і умови доставки замовленого товару Покупець погоджує з оператором Інтернет-магазину в момент оформлення покупки.</li>
                        <li>Кур'єрська доставка замовлення Покупцеві здійснюється за погодженням з Покупцем і за вказаною ним адресою.</li>
                        <li>Самовивіз здійснюється Покупцем із закладу громадського харчування (піцерії) Продавця, який буде зазначений у замовленні.</li>
                        <li>При отриманні товару замовник повинен у присутності представника кур'єра перевірити відповідність товару якісним і кількісним характеристикам, (найменування товару, кількість, комплектність, термін придатності).</li>
                        <li>Доставка замовлення здійснюється в межах зони доставки (зона доставки вказана на сайті www.iqpizza.com.ua або може бути уточнена по телефону).</li>
                    </ol>
                </li>
                <li>
                    <h2>ПРАВА ТА ОБОВ'ЯЗКИ СТОРІН</h2>
                    <ol>
                        <li>
                            <span>Продавець зобов'язується:</span>
                            <ol>
                                <li>Не розголошувати будь-яку приватну інформацію Покупця і не надавати доступ до цієї інформації третім особам, за винятком випадків, передбачених чинним законодавством України.</li>
                                <li>Надати Покупцеві можливість отримання безкоштовних телефонних консультацій за телефонами, вказаними на сайті www.iqpizza.com.ua. Обсяг консультацій обмежується конкретними питаннями, пов'язаними з виконанням замовлення.</li>
                                <li>Продавець залишає за собою право невиконання замовлення в разі виникнення форс-мажорних обставин.</li>
                                <li>Продавець залишає за собою право змінювати цей Договір в односторонньому порядку до моменту його укладення.</li>
                            </ol>
                        </li>
                    </ol>
                </li>
                <li>
                    <h2>Форс-мажорні обставини</h2>
                    <ol>
                        <li>Сторони звільняються від відповідальності за невиконання або неналежне виконання зобов'язань за умовами договору на час дії непереборної сили. Під непереборною силою розуміються надзвичайні і непереборні за даних умов обставини, що перешкоджають виконанню своїх зобов'язань сторонами за цим Договором. До них відносяться стихійні явища (землетруси, повені і т.д.), обставини суспільного життя (воєнні дії, надзвичайні стани, найбільші страйки, епідемії і т.д.), заборонні заходи державних органів (заборона перевезень, валютні обмеження, міжнародні санкції, заборони на торгівлю та інше). Протягом цього часу сторони не мають взаємних претензій і кожна зі сторін приймає на себе свій ризик за результатами форс-мажорних обставин.</li>
                    </ol>
                </li>
                <li>
                    <h2>ІНФОРМАЦІЯ І ЇЇ ВИКОРИСТАННЯ</h2>
                    <ol>
                        <li>
                            <span>Продавець збирає і обробляє персональні дані Покупців (а саме: ім'я Покупця, адреса доставки, контактні телефони, адресу електронної пошти і т.д.) з метою:</span>
                            <ul>
                                <li>виконання умов даного Договору;</li>
                                <li>доставки Покупцеві замовленої ним продукції.</li>
                            </ul>
                        </li>
                        <li>Здійснюючи замовлення товару через Інтернет-магазин, Покупець дає згоду на збір і обробку такої інформації.</li>
                        <li>Доступ до персональних даних Покупця мають тільки особи, які безпосередньо залучені до виконання замовлення.</li>
                        <li>Продавець зобов'язується: дотримуватися конфіденційності щодо персональних даних Покупців; не допускати спроби несанкціонованого використання персональних даних Покупців третіми особами; виключити доступ до персональних даних Покупців, осіб, які не мають безпосереднього відношення до виконання заявки, крім випадків, передбачених чинним законодавством України.</li>
                    </ol>
                </li>
                <li>
                    <h2>ПРОДАЖ ТОВАРУ</h2>
                    <ol>
                        <li>Інтернет-магазин не гарантує безумовне виконання замовлення. Всі товари надаються за принципом «як є». Задіяні технічні системи (веб-сторінка і сам сайт: www.iqpizza.com.ua) можуть включати невиявлені технічні помилки, наслідком яких є неможливість виконання замовлення, покупки товару або його несвоєчасне виконання. Крім того, до неможливості виконання замовлення або його несвоєчасного виконання можуть призвести обставини, зумовлені людським фактором або ж обставини непереборної сили.</li>
                        <li>У разі неможливості або несвоєчасного виконання прийнятих на себе зобов'язань за обставин, викладених у п.10.1 (виключаючи «форс-мажор») Інтернет-магазин несе відповідальність в межах сум, отриманих від клієнтів в якості оплати за товари.</li>
                        <li>Інтернет-магазин не несе і ні за яких обставин і не може нести ніякої матеріальної відповідальності, що виходить за рамки сум, отриманих від клієнта в якості оплати за товари і послуги.</li>
                        <li>Інтернет-магазин залишає за собою право відмовити в наданні послуг або продажу товару Покупцеві в разі відсутності у нього можливості надати відповідний товар.</li>
                        <li>
                            <span>Грошові кошти, сплачені Покупцем, не повертаються в наступних випадках:</span>
                            <div>
                                <p>1) відмова клієнта від отриманого товару після оплати і отримання від кур'єра;</p>
                                <p>2) якщо даний товар є належної якості.</p>
                            </div>
                        </li>
                    </ol>
                </li>
                <li>
                    <h2>АВТОРСЬКІ ПРАВА</h2>
                    <ol>
                        <li>Вся текстова інформація та графічні зображення, розміщені на сайті інтернет-магазину: www.iqpizza.com.ua є власністю продавця та / або його постачальників і виробників Товару.</li>
                    </ol>
                </li>
            </ol>
        </div>
    );
}

export default ArticleUa;