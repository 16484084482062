import React from 'react';
import './article.style.css';

const ArticleUa = () => {
    return (
        <div className="4myasa">
            <p>Піца 4 м'яса 30 см - півкілограма ситного задоволення для тих, хто жити не може без м'яса. Пікантний соус і зелень зроблять обід ще смачнішим. А де найапетитніша піца? Звичайно ж в закладі IQ Pizza! Ми пропонуємо вам не тільки гарячу і апетитну випічку, а й гарні ціни, приємні бонуси, швидку кур'єрську доставку у будь-яку точку міста. Найкраще обслуговування тільки для наших клієнтів!</p>
            <p>Склад страви:</p>
            <ul>
                <li>томатний соус болоньєзе;</li>
                <li>сир "моцарела";</li>
                <li>м’ясо (курка);</li>
                <li>ковбаски;</li>
                <li>бекон;</li>
                <li>цибуля маринована;</li>
                <li>зелень.</li>
            </ul>
            <p>Хочете, щоб ваша піца стала ще більш м'ясною? Не забудьте додати в неї додаткові інгредієнти. До вашої випічки відмінно пасуватиме шинка, салямі або яловичина. Але, тільки вам вирішувати, яким буде ваш ідеальний обід. Також не забудьте додати сир або сосиски в бортик, щоб зробити смак страви ще більш насиченим.</p>
            <p>Хотите сделать себе вкусный итальянский перекус? Или планируете посиделки с близкими людьми? Тогда пицца 4 мяса 30 см – идеальный вариант! Размер и объем выпечки, 510 гр, достаточный для того, чтобы каждый в компании наелся. Количество кусочков – 8 штук.  Этого точно хватит, чтобы каждому досталось аппетитное лакомство. </p>
            <p>Хочете зробити собі смачний італійський перекус? Або плануєте посиденьки з близькими? Тоді піца 4 м'яса 30 см - ідеальний варіант! Розмір і обсяг випічки, 510 гр, достатній для того, щоб кожен в компанії наївся. Кількість шматочків - 8 штук. Цього цілком вистачить, щоб кожному дісталися апетитні ласощі.</p>
            <p>Хочете замовити їжу? Доставка проводиться щодня, з 10:00 до 22:00. Замовити піцу ви можете за номером телефону нашої кур'єрської служби, на офіційному сайті чи в мобільному додатку. Ваша їжа вже чекає на зустріч!</p>
        </div>
    );
}

export default ArticleUa;