import React from 'react';
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
// import { getBredCrumbsPath } from '../../hoc/breadCrumbs';

import s from './breadcrumbs.module.css';
import Arrow from '../../assets/breadCrumbsArrow.svg';

const BreadCrumbs = (props) => {
    const { t, i18n } = useTranslation();
    const categories = useSelector(state => state.menu.categories);
    const lng = useSelector(state => state.menu.languageMenu);
    const products = useSelector(state => state.menu.products);
    let pathname = props.location.pathname;
    pathname = pathname[pathname.length - 1] === '/' ? pathname.slice(0, pathname.length - 1) : pathname;
    let pathSections = pathname.split('/').filter(name => name !== '/');
    pathSections = pathSections.filter(t => t != 'ru' && t != 'uk');
    pathSections = pathSections.length === 1 && pathSections[0] === '' ?  [] : pathSections;
    pathSections = pathSections.includes('success') ? lng === 'uk' ? ['', 'На головну'] : ['', 'На главную'] : pathSections;

    const makeBreadCrumbsMenu = (path) => {
        // console.log('breadcrumbs path', path);
        switch(path) {
            case '':
                return { url: `${lng === 'uk' ? '/' : '/ru/'}`, name: t('main') };
            case 'menu':
                return { url: `${lng === 'uk' ? '/menu/' : '/ru/menu/'}`, name: t('menu') };
            case 'dostavka':
                return { url: `/${lng === 'uk' ? '/dostavka' : '/ru/dostavka'}`, name: t('delivery') };
            case 'basket':
                return { url: `/${lng === 'uk' ? '/basket' : '/ru/basket'}`, name: t('basket') };
            case 'pro-nas': 
                return { url: `/${lng === 'uk' ? '/pro-nas/' : '/ru/pro-nas/'}`, name: t('aboutUs') };
            case 'o-nas': 
                return { url: `/${lng === 'uk' ? '/o-nas' : 'ru/o-nas'}`, name: t('aboutUs') };
            case 'napoji':
                return { url: '/menu/napoji/', name: t('napitki') };
            case 'napitki':
                return { url: '/menu/napitki/', name: t('napitki') };
            case 'combo':
                return { url: '/menu/combo/', name: t('combo') };
            case 'helloween':
                return { url: '/menu/helloween/', name: t('helloween') };
            case 'box-menu':
                return { url: '/menu/box-menu/', name: t('boxmenu') };
            case 'sezonnemenu':
                return { url: '/menu/sezonnemenu/', name: t('sezonnemenu') };
            case '30sm':
                return { url: '/menu/30sm/', name: t('30sm') };
            case '45sm':
                return { url: '/menu/45sm/', name: t('45sm') };
            case 'hophey':
                return { url: '/menu/hophey/', name: t('hophey') };
            case 'pica-30-sm':
                return { url: '/menu/pica-30-sm/', name: t('pizza30') };
            case 'picca-30-sm':
                return { url: '/menu/picca-30-sm/', name: t('pizza30') };
            case 'pica-45-sm':
                return { url: '/menu/pica-45-sm/', name: t('pizza45') };
            case 'picca-45-sm':
                return { url: '/menu/picca-45-sm/', name: t('pizza45') };
            case 'love':
                return { url: '/menu/love/', name: t('love') };
            case 'blog':
                return { url: `/${lng === 'uk' ? '/blog/' : 'ru/blog/'}`, name: t('blog') };
            case 'checkout':
                return { url: `/${lng === 'uk' ? '/checkout' : 'ru/checkout'}`, name: t('checkout') };
            case 'vakansji':
            case 'vakansii':
                return { url: `/${lng === 'uk' ? '/blog/' : 'ru/blog/'}`, name: t('vacancies') };
            case 'publichna-oferta':
            case 'publichnaja-oferta':
                return { url: `/${lng === 'uk' ? '/publichna-oferta/' : 'ru/publichnaja-oferta/'}`, name: t('publicOffer') };
            case 'politika-konfidencialnosti':
            case 'polityka-konfidencijnosti':
                return { url: `/${lng === 'uk' ? '/polityka-konfidencijnosti/' : 'ru/politika-konfidencialnosti/'}`, name: t('privacyPolicy') };
            case 'product':
                return {  url: `${lng === 'uk' ? '/menu/product/' : '/ru/menu/product/'}` + pathname.split(`${lng === 'uk' ? '/menu/product/' : '/ru/menu/product/'}`)[1], name: 'Продукт' };
            case categories.find(c => c.id === path) && categories.find(c => c.id === path).id:
                return { url: `${lng === 'uk' ? '/menu/' : '/ru/menu/'}` + path, name: categories.find(c => c.id === path).name };
            case products.find(s => s.id === path) && products.find(s => s.id === path).id:
                return { url: `${lng === 'uk' ? '/menu/product/' : '/ru/menu/product/'}` + path, name: products.find(s => s.id === path).name };
            default:
                let productName = products.find(t => t.url === path)
                productName = productName ? productName.name : '';
                return { url: productName, name: productName };
        }
    }

    return (
        <div className={s.container}>
            <ul className={s.breadCrumbs}>
                {pathSections.map((path, index) => {
                    const { url, name } = makeBreadCrumbsMenu(path);
                    return (
                        <li className={s.breadCrumbsItem} key={index}>
                            <Link className={s.link} to={url}>{name}</Link>
                            <img className={s.arrow} src={Arrow} alt="breadcrumbs arrow" />
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}

export default withRouter(BreadCrumbs);