export const isCityName = (path) => path === 'dnipro' || path === 'zp' || path === 'chernihyv' || 
  path === 'kyiv' || path === 'berdyansk' || path === 'novomoskovsk' || path === 'melitopol' ||
  path === 'energodar' || path === 'pavlograd' || path === 'tokmak' || path === 'dniprorydne' || 
  path === 'pology' || path === 'vilnyansk' || path === 'huliaipole' || path === 'pershotravensk' ||
  path === 'kamenka' || path === 'kamyanske' || path === 'pokrovsk' || path === 'marganec' || path === 'nikopol' ||
  path === 'mykhailivka';

export const changeCity = cityName => {
    switch(cityName) {
      case 'dnipro':
        return {
          name: {uk: 'Дніпро', ru: 'Днепр'},
          id: '6bf17b9e-3ecd-0491-0179-0e815f2c8b26',
          gtm: 'GTM-T72MPSK'
        };
      case 'zp':
        return {
          name: {uk: 'Запоріжжя', ru: 'Запорожье'},
          id: 'fe74eb1c-055f-4767-b1e6-1a8d7df54070',
          gtm: 'GTM-K2SJCVK'
        }
      case 'chernihyv':
        return {
          name: {uk: 'Чернігів', ru: 'Чернигов'},
          id: '6bf17b9e-3ecd-0491-0179-0e815f2cf1aa',
          gtm: 'GTM-5MSC8LM',
        }
      case 'kyiv':
        return {
          name: {uk: 'Київ', ru: 'Киев'},
          id: '6bf17b9e-3ecd-0491-0179-0e815f2c9df9',
          gtm: 'GTM-TMWF9DP'
        }
      case 'berdyansk':
        return {
          name: {uk: 'Бердянськ', ru: 'Бердянск'},
          id: '6bf17b9e-3ecd-0491-0179-0e815f2c8027',
          gtm: 'GTM-KKNW5VM'
        }
      case 'novomoskovsk':
        return {
          name: {uk: 'Новомосковськ', ru: 'Новомосковск'},
          id: '6bf17b9e-3ecd-0491-0179-0e815f2cc7c8',
          gtm: 'GTM-552NJS4',
        }
      case 'melitopol':
        return {
          name: {uk: 'Мелітополь', ru: 'Мелитополь'},
          id: '6bf17b9e-3ecd-0491-0179-0e815f2cc139',
          gtm: 'GTM-K6FLFMW'
        }
      case 'energodar':
        return {
          name: {uk: 'Енергодар', ru: 'Энергодар'},
          id: '50cf0215-f3a4-4f8c-8905-676ff787cb69',
          gtm: 'GTM-T4PSRD2'
        }
      case 'pavlograd':
        return {
          name: {uk: 'Павлоград', ru: 'Павлоград'},
          id: '417177e7-cbf6-4039-ac09-9e94d8fea689',
          gtm: 'GTM-PQ3VMKJ'
        }
      case 'dniprorydne':
        return {
          name: {uk: 'Дніпрорудне', ru: 'Днепрорудное'},
          id: '716229c9-6a8f-4cf9-8a9e-99de17ae4c18',
          gtm: 'GTM-WXW2TP6',
        }
      case 'pology':
        return {
          name: {uk: 'Пологи', ru: 'Пологи'},
          id: '3f8bb8cf-f815-493d-a0ac-67f64f2329bd',
          gtm: 'GTM-K4KS2DR'
        }
      case 'tokmak':
        return {
          name: {uk: 'Токмак', ru: 'Токмак'},
          id: '26980b21-5caf-4298-b933-ce094cc10ac0',
          gtm: 'GTM-N6PSNR8'
        }
      case 'vilnyansk': 
        return {
          name : {uk: 'Вільнянськ', ru: 'Вольнянск'},
          id: '924a64d7-6405-4847-a07b-7ec3d077e9be',
          gtm: 'GTM-KL848LG',
        }
      case 'huliaipole':
        return {
          name: {uk: 'Гуляйполе', ru: 'Гуляйполе'},
          id: '5da9c4d0-aaf7-4b1c-85d0-8d8ee6cf7d46',
          gtm: 'GTM-PRZMNTJ'
        };
      case 'pershotravensk':
        return {
          name: {uk: 'Першотравенск', ru: 'Першотравенск'},
          id: 'a8ec38d3-a1bd-4226-a527-2786bb44634b',
          gtm: 'GTM-TPLDXK8'
        };
      case 'kamenka':
        return {
          name: {uk: 'Кам\'янка-Дніпровська', ru: 'Каменка-Днепровская'},
          id: '6c4e0035-0ca0-4965-8e18-bbadb05e9775',
          gtm: 'GTM-PH3V9N5'
        };
      case 'kamyanske':
        return {
          name: {uk: 'Кам\'янське', ru: 'Каменское'},
          id: '6bf17b9e-3ecd-0491-0179-0e815f2c9c1e',
          gtm: 'GTM-MTTGWQ5'
        };
      case 'pokrovsk':
        return {
          name: {uk: 'Покровське', ru: 'Покровское'},
          id: '49328221-67fc-4e63-a74a-4afd996a36a4',
          gtm: null
        };
      case 'marganec':
        return {
          name: {uk: 'Марганець', ru: 'Марганец'},
          id: 'd1f424c1-8c87-dc8b-017d-7bd590589350',
          gtm: null
        };
      case 'nikopol':
        return {
          name: {uk: 'Нікополь', ru: 'Никополь'},
          id: '0c8397ce-f3b6-b0e6-017e-4e62ecc739dd',
          gtm: null
        };
      case 'mykhailivka':
        return {
          name: {uk: 'Михайлівка', ru: 'Михайловка'},
          id: 'ace87627-d363-404d-93c4-7ceab2ed7d97',
          gtm: null
        };
      default:
        return {
          name: {uk: 'Київ', ru: 'Киев'},
          id: '6bf17b9e-3ecd-0491-0179-0e815f2c9df9',
          gtm: 'GTM-TMWF9DP'
        };
    }
  }