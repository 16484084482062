import React from 'react';
import './article.style.css';

const ArticleUa = () => {
    return (
        <div className="about-us">
            <h1>ПІЦА ЧІЗБУРГЕР</h1>
            <p>Піца Чізбургер 30 см - те, що потрібно гурманам, які полюбляють бургери і жити не можуть без піци. Як то кажуть: «Навіщо розриватися між двома улюбленими частуваннями, якщо можна отримати одразу обидва?»</p>
            <p>Смачна піца Чізбургер 30 см від IQ Pizza - це частування, яке нікого не залишить байдужим. Тонке тісто, апетитна, свіжа начинка - в цій випічці ідеально все!</p>
            <p>Тільки уявіть найбільший бургер, яким можна легко наїстися самому і поділитися з близькими!</p>
            <p>Що всередині піца Чізбургер 30 см:</p>
            <ul>
                <li>соус болоньєзе BBQ;</li>
                <li>сир моцарелла;</li>
                <li>курка;</li>
                <li>сир "Чеддер";</li>
                <li>огірки солоні;</li>
                <li>цибуля маринована.</li>
            </ul>
            <p>Також не забудьте додати додаткові інгредієнти, які зроблять улюблене блюдо ще смачнішим! Доступні такі варіанти начинки: бортик з сиром, бортик з сосисками, подвійний сир, м'ясо і багато іншого!</p>
            <p>Подобається піца Чізбургер 30 см, але не виходить забрати її із закладу особисто? Не біда! Можна замовити кур'єра. Ми привеземо вам гарячу і свіжу випічку за будь-якою зручною адресою, в потрібний час!</p>
            <ul>
                <li>вартість перевезення до 5 км від найближчого закладу - 35 грн;</li>
                <li>вартість перевезення на відстань більш ніж 5 км від закладу - обговорюється окремо.</li>
            </ul>
            <p>Вирішили влаштувати бенкет? Тоді скористайтеся нашою кур'єрською службою! При замовленні на суму більш ніж 350 грн доставка безкоштовна! Детальніше про послуги сервісу читайте в пункті меню «Доставка» на нашому сайті.</p>
        </div>
    );
}

export default ArticleUa;