import React from 'react';
import { useSelector } from 'react-redux';

import s from './product.module.css';
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router";
import logo from '../../assets/logo.png';
import { getCategoryId } from '../Router/ohter';

const ProductCard = (props) => {
    const { t, i18n } = useTranslation();
    const cities = useSelector(state => state.menu.cities);
    const categories = useSelector(state => state.menu.categories);
    const language = useSelector(state => state.menu.languageMenu);
    const activeCity = useSelector(state => state.menu.activeCity);
    const city = cities.find(t => t.id === activeCity.id);

    const getSum = () => {
        let sum = 0;
        let price = props.item.price;
        let modifierSum = 0;

        if (city && city.terminals) {
            props.item.differentPricesOn.map(t => {
                city.terminals.map(terminal => {
                    if (terminal.id === t.terminalId) {
                        price = t.price;
                    }
                })
            });
        }
        
        props.item.modifiers.map(a => modifierSum += a.price*a.amount);
        props.item.groupModifiers.map(a => a.childModifiers.map(b => {
            modifierSum = modifierSum +  (b.price * (b.amount * 10)) / 10
        }));
        sum += (modifierSum+price);             
        return sum;
    }

    const parentGroup = props.item.parentGroup;
    const category = categories.find(t => t.id === parentGroup);
    let url = '';

    if (category.id === getCategoryId('napitki')) url = language === 'uk' ? 'napoji' : 'napitki';
    if (category.id === getCategoryId('pizza30')) url = language === 'uk' ? 'pica-30-sm' : 'picca-30-sm';
    if (category.id === getCategoryId('pizza45')) url = language === 'uk' ? 'pica-45-sm' : 'picca-45-sm';
    if (category.id === getCategoryId('love')) url = language === 'uk' ? 'love' : 'love';
    if (category.id === getCategoryId('hophey')) url = language === 'uk' ? 'hophey' : 'hophey';
    if (category.id === getCategoryId('combo', activeCity.id)) url = language === 'uk' ? 'combo' : 'combo';
    if (category.id === getCategoryId('helloween')) url = language === 'uk' ? 'helloween' : 'helloween';
    if (category.id === getCategoryId('boxmenu')) url = language === 'uk' ? 'box-menu' : 'box-menu';
    if (category.id === getCategoryId('sezonnemenu')) url = language === 'uk' ? 'sezonnemenu' : 'sezonnemenu';
    if (category.id === getCategoryId('30sm')) url = language === 'uk' ? '30sm' : '30sm';
    if (category.id === getCategoryId('45sm')) url = language === 'uk' ? '45sm' : '45sm';

    return(
        <div className={s.product}>
            <div className={s.product_row}>
                <div onClick={() => props.history.replace(`${language === 'uk' ? '' : '/ru'}/menu/${url}/${props.item.url}`)} 
                    className={s.product_img} 
                    style={{backgroundImage: props.item.images !== [] && props.item.images.length > 0 ? `url(${props.item.images[props.item.images.length-1].imageUrl})` : 
                                 `url(${logo})`
                            }}/>
                    <div onClick={() => props.history.replace(`${language === 'uk' ? '' : '/ru'}/menu/${url}/${props.item.url}`)}  className={s.product_title}>{props.item.name} {props.item['weight'] > 0 && `(${Math.round(props.item['weight']*1000)}г.)`}</div>
                        <div onClick={() => props.history.replace(`${language === 'uk' ? '' : '/ru'}/menu/${url}/${props.item.url}`)} className={s.product_description}>
                            {props.item.description}
                        </div>
                        <div className={s.product_footer}>
                            <div className={s.price}>
                                <span>{props.item.count && props.item.count > 1 ? Number((getSum())*10*props.item.count)/10 : getSum()}</span> ₴
                            </div>
                            <div className={s.add_container}>
                                {props.item.count > 0 ? <div className={[`${s.count_product} ${s.count}`]}>
                                    <div className={s.minus} onClick={() => props.updateProduct('decrement', props.item.id)}></div>
                                        <div className={s.result}>{props.item.count}</div>
                                    <div className={s.plus} onClick={() => props.updateProduct('increment', props.item.id)}></div>
                                </div> :  <div onClick={() => {
                                                if(props.item.groupModifiers.length > 0 || props.item.modifiers.length > 0){
                                                    props.history.replace(`${language === 'uk' ? '' : '/ru'}/menu/${url}/${props.item['url']}`);
                                                } else {
                                                    props.updateProduct('increment', props.item['id']);
                                                };
                                }}  className={s.add_product}>
                                    <span>{t('add')}</span>
                                 </div>}
                            </div>
                        </div>
                    </div>
                </div>
            )
}

export default withRouter(ProductCard);