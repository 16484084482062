import React from 'react';
import './article.style.css';

const ArticleUa = () => {
    return (
        <div className="about-us">
            <h1>ПИЦЦА МАРГАРИТА</h1>
            <p>Пицца Маргарита 30 см – классическая итальянская выпечка, любимая многими. Сытная, сочная, в меру пикантная. Ароматная зелень и томатный соус, сыр. Ингредиенты дополняют друг друга и делают «Маргариту» идеальной! Правду говорят: «Все самое лучшее называют женскими именами!»</p>
            <p>Планируете итальянский обед с семьей или друзьями? А может захотели сделать приятное себе любимому? Тогда пицца Маргарита 30 см от IQ Pizza – ваш вариант! Мы приготовим ваш обед в лучших итальянских традициях и доставим его в удобное место, время специально для вас!</p>
            <p>Ингредиенты, которые выкладываются на тесто:</p>
            <ul>
                <li>томатный соус; </li>
                <li>сыр "Моцарелла"; </li>
                <li>помидоры;</li>
                <li>зелень.</li>
            </ul>
            <p>Хотите, чтобы ваша пицца Маргарита 30 см стала еще вкуснее? Предлагаем вам добавить к ней дополнительные ингредиенты. Подберите начинку на свой вкус из нашего меню, когда будете оформлять заказ. И не забудьте про бортики! У нас есть такие варианты начинки бортика: сырный и сосисочный.  </p>
            <p>Доставка оформляется тремя способами:</p>
            <ul>
                <li>позвонить по номеру телефона нашего курьерского сервиса: 068 115 1010;</li>
                <li>через сайт;</li>
                <li>через личный кабинет мобильного приложения</li>
            </ul>
            <p>Наши курьеры с радостью привезут вам свежую, горячую, сытную еду пицца Маргарита 30 см каждый день, с 10:00 до 22:00. Вам же нужно только ее заказать!</p>
        </div>
    );
}

export default ArticleUa;