import React from 'react';

import s from './categories.module.css';

const Categories = (props) => {
    return(
        <div className={s.categoriesContainer}>
                    <div className={s.categories_container}>
                        {props.categories
                        .sort((a, b) => a.order-b.order)
                        .map((item, index) => {
                            let search = props.products.find(it => it.parentGroup === item.id);
                            if(search){
                            return(
                                 <span key={index} onClick={() => props.updateCategory(item.id)} className={props.activeCategory === item.id ? s.active : ''}>
                                        {item.name}
                                 </span>
                            )
                            }
                        })}
                    </div>
        </div>
    )
}

export default Categories;