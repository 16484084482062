import React from 'react';

import s from './button.module.css';

const Button = (props) => {
    return(
        <div  className={`${s.button} ${props.className}`} onClick={props.handleClick}>
            {props.title}
        </div>
    )
}

export default Button;