
export const getPathUrl = (path) => {
    switch(path) {
        case '/pro-nas/':
            return '/ru/o-nas/';
        case '/ru/o-nas/':
            return '/pro-nas/';
        case '/ru/menu/napitki':
            return '/menu/napoji';
        case '/menu/napoji':
            return '/ru/menu/napitki';
        case '/menu/combo/':
            return '/ru/menu/combo/';
        case '/ru/menu/combo/':
            return '/menu/combo/';
        case '/menu/helloween/':
            return '/ru/menu/helloween/';
        case '/ru/menu/helloween/':
            return '/menu/helloween/';
        case '/menu/box-menu/':
            return '/ru/menu/box-menu/';
        case '/ru/menu/box-menu/':
            return '/menu/box-menu/';
        case '/menu/sezonnemenu/':
            return '/ru/menu/sezonnemenu/';
        case '/ru/menu/sezonnemenu/':
            return '/menu/sezonnemenu/';
        case '/menu/30sm/':
            return '/ru/menu/30sm/';
        case '/ru/menu/30sm/':
            return '/menu/30sm/';
        case '/menu/45sm/':
            return '/ru/menu/45sm/';
        case '/ru/menu/45sm/':
            return '/menu/45sm/';
        case '/menu/hophey/':
            return '/ru/menu/hophey/';
        case '/ru/menu/hophey/':
            return '/menu/hophey/';
        case '/ru/menu/picca-30-sm/':
            return '/menu/pica-30-sm/';
        case '/menu/pica-30-sm/':
            return '/ru/menu/picca-30-sm/';
        case '/ru/menu/picca-45-sm/':
            return '/menu/pica-45-sm/';
        case '/menu/pica-45-sm/':
            return '/ru/menu/picca-45-sm/';
        case '/polityka-konfidencijnosti/':
            return '/ru/politika-konfidencialnosti/';
        case '/ru/politika-konfidencialnosti/':
            return '/polityka-konfidencijnosti/';
        case '/publichna-oferta/':
            return '/ru/publichnaja-oferta/';
        case '/ru/publichnaja-oferta/':
            return '/publichna-oferta/';
        case '/vakansji/':
            return '/ru/vakansii/';
        case '/ru/vakansii/':
            return '/vakansji/';
        case '/success/':
            return '/ru/success/';
        case '/ru/success':
            return '/success/';
        default:
            return path;
    }
};

export const getRoutes = (routeName, lng) => {
    const getUrl = (a, b) => lng === 'uk' ? a : b;

    switch(routeName) {
        case 'basket':
            return getUrl('/basket', '/ru/basket');
        case 'blog':
            return getUrl('/blog/', '/ru/blog/');
        case 'vacancies':
            return getUrl('/vakansji/', '/ru/vakansii/');
        case 'main':
            return getUrl('/', '/ru/');
        case 'dostavka':
            return getUrl('/dostavka', '/ru/dostavka');
        case 'aboutUs': 
            return getUrl('/pro-nas/', '/ru/o-nas/');
        case 'product': 
            return getUrl('/menu/product/:id', '/ru/menu/product/:id');
        case 'productCombo': 
            return getUrl('/menu/combo/:id', '/ru/menu/combo/:id');
        case 'productHophey': 
            return getUrl('/menu/hophey/:id', '/ru/menu/hophey/:id');
        case 'productHelloween':
            return getUrl('/menu/helloween/:id', '/ru/menu/helloween/:id');
        case 'productBoxMenu':
            return getUrl('/menu/box-menu/:id', '/ru/menu/box-menu/:id');
        case 'productSezonnemenu':
            return getUrl('/menu/sezonnemenu/:id', '/ru/menu/sezonnemenu/:id');
        case 'product30sm':
            return getUrl('/menu/30sm/:id', '/ru/menu/30sm/:id');
        case 'product45sm':
            return getUrl('/menu/45sm/:id', '/ru/menu/45sm/:id');
        case 'productLove':
            return getUrl('/menu/love/:id', '/ru/menu/love/:id');
        case 'productNapitki': 
            return getUrl('/menu/napoji/:id', '/ru/menu/napitki/:id');
        case 'productPizza30': 
            return getUrl('/menu/pica-30-sm/:id', '/ru/menu/picca-30-sm/:id');
        case 'productPizza45': 
            return getUrl('/menu/pica-45-sm/:id', '/ru/menu/picca-45-sm/:id');
        case 'menu': 
            return getUrl('/menu/:id', 'ru/menu/:id');
        case 'menuCategory': 
            return getUrl('/menu/', '/ru/menu');
        case 'menuCombo': 
            return getUrl('/menu/combo', '/ru/menu/combo');
        case 'menuNapitki':
            return getUrl('/menu/napoji', '/ru/menu/napitki');
        case 'menuHophey':
            return getUrl('/menu/hophey', '/ru/menu/hophey');
        case 'menuHelloween':
            return getUrl('/menu/helloween', '/ru/menu/helloween');
        case 'menuBoxMenu':
            return getUrl('/menu/box-menu', '/ru/menu/box-menu');
        case 'menuSezonnemenu':
            return getUrl('/menu/sezonnemenu', '/ru/menu/sezonnemenu');
        case 'menu30sm':
            return getUrl('/menu/30sm', '/ru/menu/30sm');
        case 'menu45sm':
            return getUrl('/menu/45sm', '/ru/menu/45sm');
        case 'menuPizza30': 
            return getUrl('/menu/pica-30-sm/', '/ru/menu/picca-30-sm/');
        case 'menuPizza45': 
            return getUrl('/menu/pica-45-sm/', '/ru/menu/picca-45-sm/');
        case 'menuLove': 
            return getUrl('/menu/love/', '/ru/menu/love/');
        case 'checkout':
            return getUrl('/checkout/', '/ru/checkout/');
        case 'success':
            return getUrl('/success/:phone/:order', '/ru/success/:phone/:order');
        case 'offer':
            return getUrl('/publichna-oferta/', '/ru/publichnaja-oferta/');
        case 'privacy':
            return getUrl('/polityka-konfidencijnosti/', '/ru/politika-konfidencialnosti/');
        default:
            return '/';
    }
}

export const getCategoryId = (id, cityId) => {
    switch(id) {
        case 'napitki': 
            return '617b7419-a079-4d59-8f19-5e93f0403a41';
        case 'pizza30':
            return 'c6d843d7-6fdb-4e85-84c9-7d039c2e4daf';
        case 'pizza45':
            return '92f83f3e-afca-4b75-9ef4-99ad260865ef';
        case 'love':
            return 'c8549ec3-f367-479c-bb1a-35996ad1c6c9';
        case 'hophey':
            return 'fafaee28-2ed2-4544-8f5a-c31e10d16dc9';
        case 'combo':
            return '1aa852db-d4d0-4861-a71e-b83a310158b6';
            // return cityId === '417177e7-cbf6-4039-ac09-9e94d8fea689' 
            //     ? '1aa852db-d4d0-4861-a71e-b83a310158b6' 
            //     : '1839494d-9bcd-49d9-b624-17a496b412ec'
        case 'helloween': 
            return '6bceb5f9-7a9c-4ea9-8da2-dc37c261cec7';
        case 'boxmenu': 
            return '94adf9c8-3c22-4e4f-b2e9-d30b15f3d4f2';
        case 'sezonnemenu': 
            return 'ad4ee78a-943b-4fb1-bfe6-767148ab4350';
        case '30sm': 
            return '03bb81c4-d745-4de2-990a-1d47f2761321';
        case '45sm': 
            return 'd1537420-4fd6-4ec4-90be-626b37edbec3';
        default:
            return '617b7419-a079-4d59-8f19-5e93f0403a41';
    }
}