import React from 'react';

const ArticleUa = ({ cityName }) => {
    return (
        <div style={{
            height: 400,
            overflowY: 'scroll',
        }}>
            <h1>Піца меню {cityName}</h1>
            <p>Шукаєте свою ідеальну і найапетитнішу їжу? У піца меню від IQ Pizza точно знайдете своє улюблене смачне частування. Не вірите нам? Переконайтеся самі! Серед нашого піца меню ви точно знайдете свій улюблений рецепт!</p>
            <h2>Види піци</h2>
            <p>Меню від IQ Pizza пропонує вам величезний вибір найсмачніших і різноманітних страв. У вас є улюблена випічка? Ви точно знайдете її у нас.</p>
            Наші хіти продажів:
            <ul>
                <li>«Гавайська» - пікантна свіжа випічка, до складу якої входить ананас, курка, сир «Моцарелла», легкий вершковий соус, маринована цибуля і зелень.</li>
                <li>«Карбонара» - ситний обід, до складу якого входить сир «Моцарелла», бекон, пармезан, запашний перець, яйце. Щоб їжа була ще смачнішою і соковитішою, наші кухарі додають вершковий соус.</li>
                <li>«Маргарита» - найапетитніша випічка для тих, хто не їсть м'ясо. До складу входить: томатний соус, сир «Моцарелла», помідори, зелень. Порада від кухаря: приправте «Маргариту» печерицями, щоб вона стала ще смачнішою!</li>
                <li>Барбекю - любите, щоб в страві було побільше м'яса? Тоді таке частування вам точно сподобається! Склад «Барбекю»: соус bbq, сир «Моцарелла», курка, кукурудза і маринована цибуля.</li>
                <li>«Чотири сиру» - найсмачніша сирна випічка! Склад: соус сирний, сир «Моцарелла», сир «Гауда«, крем-сир «Дор Блю«.</li>
                <li>«Чізбургер» - не можете вирішити: бургер або піца? Замовте наш «Чізбургер»! Склад: соус болоньєзе BBQ, сир «Моцарела«, курка, сир «Чеддер«, солоні огірки, цибуля маринована.</li>
            </ul>
            <p>Хочете, щоб свіжу і ароматну їжу вам привезли додому або в офіс на зручний час? Оформіть кур'єрську доставку від IQ Pizza! Ми доставляємо замовлення в багатьох великих містах України. Зі списком міст, які входять в зону доставки, і з адресами пунктів, де є самовивіз, ви можете ознайомитися у пункті меню «Доставка», зайшовши на сайт IQ Pizza. Щоб оформити доставку через наш сайт або додаток, необхідно усього пару хвилин. Для  вашої зручності ми зробили інтерфейс максимально інтуїтивним та зручним. Оплата замовлення здійснюється в особистому кабінеті, за допомогою карти, або кур’єрові після отримання їжі.</p>
            <h2>Розміри піци</h2>
            <p>В піца меню від IQ Pizza ви знайдете свій ідеальний розмір страви. Асортимент, який пропонують наші ресторани, складається з випічки з таким діаметром:</p>
            <ul>
                <li>30 см - від 350 грам (міні розмір) до 530 грам;</li>
                <li>45 см - велика випічка від 640 грам до 1020 грам.</li>
            </ul>
            <p>Вага замовлення залежить від начинки страви і додаткових інгредієнтів з меню.</p>
            <p>Хочете ситно перекусити? Замовте в піца меню собі маленьку піцу 30 см. Або може ви плануєте веселий вечір з друзями? Щоб шматків смачного частування вистачило кожному, замовте велику піцу! З такою вечерею ніхто з вашої компанії не залишиться голодним!</p>
            <h2>Основа для піци</h2>
            <p>Кожна смачна випічка з піца меню від IQ Pizza виготовляється на смачному тонкому тісті. Ми готуємо корж для страви максимально легким. Так наші кухарі можуть помістити в блюдо більше соусу, начинки і додаткових інгредієнтів, щоб ви відчували смак піци в кожному шматочку, а не їли просто м'яке тонке тісто.</p>
            <p>Наші кухарі дуже відповідально підходять до випікання коржів. Щоб основа для випічки була якомога смачнішою, використовується найкраща мука екстра-класу.</p>
            <h2>Піца з бортиками</h2>
            <p>Втомилися від стандартної подачі піци? Додайте в улюблене частування цікаву і смачну деталь - бортик з наповненням.</p>
            Піца меню нашого закладу пропонує такі інгредієнти, які можна додати в бортик:
            <ul>
                <li>Сирний бортик - ніжний крем-сир, що додає пікантності. Любителям пряного сиру, в бортик можна додати сулугуні або пікантний дор блю;</li>
                <li>бортик з сосисками - смачні ковбаски, які роблять улюблену випічку ще смачнішою й ситнішою.</li>
            </ul>
            <p>Щоб додати улюблений інгредієнт до бортика, зайдіть в піца меню, виберіть  улюблену випічку, клікніть на посилання «В кошик» і вкажіть улюблену начинку. Вартість бортика з наповнювачами залежить від інгредієнтів і розміру страви. Зручний калькулятор піца меню допоможе прорахувати вартість улюбленої їжі легко і швидко!</p>
            <h2>Інгредієнти і соус для піци</h2>
            <p>Без чого їжа не буде такої соковитою і пряною? Звичайно ж без соусу! Піца меню від IQ Pizza пропонує вам додати до улюбленої страви такі соуси:</p>
            <ul>
                <li>ніжний білий вершковий;</li>
                <li>пряний сирний;</li>
                <li>пікантний болоньєзе;</li>
                <li>оригінальний BBQ, який зробить м'ясні страви з піца меню ще смачнішими;</li>
                <li>улюблений багатьма томатний. Любителям яскравих вражень точно сподобається гостра томатна паста в страві.</li>
                <li>Оригінальний і ніжний сирно-шпинатний, який зробить будь-яку курячу випічку ще смачнішою.</li>
            </ul>
            <p>Кожен соус для піца меню готується нашими кухарями з кращих інгредієнтів. Мета IQ Pizza - максимальне задоволення від кожного шматочка!</p>
            <p>Швидше вибирайте свій улюблений соус, замовляйте смачний і гарячий обід для себе й своїх близьких з нашого піца меню!</p>
            <p>Але не тільки за допомогою соусу можна зробити улюблене блюдо ще смачнішим! Додайте до свого замовлення додаткові інгредієнти!</p>
            <p>На ваше прохання наші кухарі можуть додати до начинки більше свіжих продуктів з нашого піца меню:</p>
            <ul>
                <li>ананас;</li>
                <li>бекон;</li>
                <li>гриби;</li>
                <li>печериці;</li>
                <li>жовток;</li>
                <li>кукурудзу;</li>
                <li>копчену ковбасу;</li>
                <li>курку;</li>
                <li>маслини;</li>
                <li>маринований огірок;</li>
                <li>пепероні;</li>
                <li>петрушку;</li>
                <li>помідор;</li>
                <li>рукола;</li>
                <li>салямі;</li>
                <li>вершковий сир «Дор Блю»;</li>
                <li>додаткову начинку з сиром «Чеддер»;</li>
                <li>додаткову начинку з шинкою;</li>
                <li>мариновану цибулю;</li>
                <li>мисливські сосиски;</li>
                <li>сосиски «баварська»;</li>
                <li>подвійна сирна скоринка;</li>
                <li>курку;</li>
                <li>болгарський перець;</li>
                <li>додаткову начинка з грибами;</li>
                <li>шинка.</li>
            </ul>
            <p>Для того, щоб додати додаткові інгредієнти в класичне блюдо, клікніть в піца меню на блюдо, що сподобалося. Далі через зручний конструктор додайте улюблені продукти до начинки. Вартість додаткових інгредієнтів автоматично додається до загальної вартості замовлення.</p>
            <h2>Що замовити крім піци?</h2>
            <p>Чи не піцою єдиною! В піца меню від IQ Pizza ви також можете знайти і замовити освіжаючі напої:</p>
            <ul>
                <li>мінеральну воду;</li>
                <li>солодкі содові напої;</li>
                <li>холодне пиво.</li>
            </ul>
            <p>Тепер не доведеться бігти в магазин в перерві, поки ви чекаєте доставку. Наші кур'єри привезуть вам повноцінний обід, з їжею і напоями. Все, що потрібно від вас, - вибрати бажаний товар з нашого піца меню!</p>
        </div>
    );
}

export default ArticleUa;