import React from 'react';
import './article.style.css';

const ArticleUa = () => {
    return (
        <div className="about-us">
            <h1>ПИЦЦА МЯСНАЯ BBQ</h1>
            <p>Пицца Мясная BBQ 30 см – обед, который идеально подойдет для фанатов всего мясного. Ведь мяса много не бывает, правда? Хотите, чтобы вкусную выпечку с курицей приготовили специально для вас, привезли по любому удобному адресу и в подходящее время? IQ Pizza к вашим услугам! Мы приготовим вам вкусное, горячее угощение, у которого тонкое тесто, и доставим по адресу на то время, которое вам удобно!</p>
            <p>Ингредиенты, которые включает в себя пицца Мясная BBQ 30 см: </p>
            <ul>
                <li>соус BBQ;</li>
                <li>сыр "Моцарелла";</li>
                <li>курица;</li>
                <li>колбаса копченая;</li>
                <li>бекон; </li>
                <li>лук маринованный;</li>
                <li>кукуруза</li>
            </ul>
            <p>Чтобы Пицца Мясная BBQ 30 см стала еще вкуснее, можно добавить в нее ароматную зелень. Также не забудьте про бортик: сырный или сосисочный. С полным списком дополнительных начинок вы можете ознакомиться в нашем меню. </p>
            <p>Пицца Мясная BBQ 30 см имеет вес 450 гр. Этого вполне хватит для того, чтобы не только сытно поесть одному, но и угостить своих близких. Кусочка аппетитной выпечки хватит каждому!</p>
            <p>Доставка еды осуществляется каждый день, с 10:00 до 22:00. Сделайте заказ на свой сытный обед уже сейчас!</p>
        </div>
    );
}

export default ArticleUa;