import React from 'react';
import './article.style.css';

const ArticleUa = () => {
    return (
        <div className="about-us">
            <h1>ПИЦЦА ПЕППЕРОНИ</h1>
            <p>Пицца Пепперони 30 см – пикантное блюдо для ценителей итальянской кухни. Соус с «огоньком», копчености и сочные томаты – в этой пицце прекрасно все! А где самая вкусная пицца Пепперони 30 см? Конечно же в IQ Pizza! У выпечки из нашего меню тонкое тесто. Мы сделали это, чтобы положить как можно больше начинки для вашего большего удовольствия.</p>
            <p>Начинка выпечки, которую можно заказать:</p>
            <ul>
                <li>соус томатный острый;</li>
                <li>сыр "Моцарелла";</li>
                <li>пепперони;</li>
                <li>халапеньо.</li>
            </ul>
            <p>Это блюдо имеет насыщенный вкус с «огоньком». То, что нужно, для любителей поострее! Хотите сделать свой обед еще более пикантным и сытным? Добавьте к нему дополнительные ингредиенты!</p>
            <p>Пицца Пепперони 30 см будет идеально сочетаться с такими продуктами: руккола, сладкий перец, помидоры коктейльные, кукуруза. Можно ли сделать свой итальянский обед еще лучше? Конечно да! Вы можете добавить в бортик сосиски или сыр и наслаждаться.</p>
        </div>
    );
}

export default ArticleUa;