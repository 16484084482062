import React from 'react';
import './article.style.css';

const ArticleRu = () => {
    return (
        <div className="public-offer">
            <h1>Договор публичной оферты</h1>
            <ol>
                <li>
                    <h2>ОБЩИЕ ПОЛОЖЕНИЯ</h2>
                    <p>Интернет-магазин www.iqpizza.com.ua (далее – Продавец) публикует настоящий договор, являющийся публичным договором-офертой (далее Договор) о купле-продаже товара дистанционным способом.</p>
                    <p>Договор определяет порядок ведения Продавцом продажи товара через Интернет-магазин в соответствии со ст. 633, 641, 642  Гражданского кодекса Украины (далее ГКУ), Правилами продажи товаров на заказ и вне торговых или офисных помещений, утвержденных приказом Министерства экономики Украины от 19.04.2007 № 103 и является публичной офертой Продавца, адресуемой неопределенному кругу, как физических, так и юридических лиц (далее Покупатель).</p>
                    <p>Факт совершения Покупателем заказа, в устной (по телефону) или письменной (через сайт) форме, и получения данного заказа Продавцом – является полным и безусловным принятием данного публичного договора и информации, изложенной на сайте.</p>
                    <p>Продавец и Покупатель гарантируют, что обладают необходимой право- и дееспособностью, а также всеми правами и полномочиями, необходимыми и достаточными для заключения и исполнения Договора.</p>
                    <p>На основании вышеизложенного, внимательно ознакомьтесь с текстом публичной оферты, и если Вы не согласны с каким-либо пунктом оферты, Вам предлагается отказаться от покупки товаров и услуг, предоставляемых Продавцом.</p>
                    <p>В настоящем Договоре, если содержание не требует иного, нижеприведенные термины следует толковать следующим образом:</p>
                    <p>«Оферта» – публичное предложение Продавца, адресованное любому физическому и юридическому лицу, с целью заключить договор купли-продажи, на существующих условиях, указанных в Договоре.</p>
                    <p>«Товар» – перечень наименований ассортимента, представленный на официальном сайте Продавца.</p>
                    <p>«Продавец» – организация, независимо от её организационно-правовой формы и/или физическое лицо предприниматель, осуществляющий продажу товара, представленного в интернет-магазине.</p>
                    <p>«Покупатель» – физическое или юридическое лицо, вступили в договорные отношения с Продавцом на условиях, указанных в Договоре.</p>
                    <p>«Заказ» – отдельные позиции из ассортиментного перечня товара, указанные Покупателем при размещении заявки на интернет-сайте Продавца.</p>
                    <p>«Курьерская доставка» – непосредственная передача товара от продавца к Покупателю независимым подрядчиком, который предоставляет услуги Продавцу в соответствии с отдельным заключенным договором. Продавец не осуществляет самостоятельно доставку товара.</p>
                    <p>«Интернет-магазин» – веб-страница продавца www.iqpizza.com.ua</p>
                </li>
                <li>
                    <h2>ПРЕДМЕТ ДОГОВОРА</h2>
                    <ol>
                        <li>Настоящий Договор является официальным документом Продавца и неотъемлемой частью оферты.</li>
                        <li>Продавец продает товар в соответствии с действующими на момент осуществления заказа ценами, а Покупатель производит оплату и принимает товар в соответствии с условиями настоящего Договора.</li>
                        <li>Договор регулирует куплю-продажу товаров в Интернет-магазине, в том числе:
                            <ul>
                                <li>добровольный выбор Покупателем товаров в Интернет-магазине;</li>
                                <li>самостоятельное оформление Покупателем заказа в Интернет-магазине;</li>
                                <li>оплата Покупателем заказа, оформленного в Интернет-магазине;</li>
                                <li>обработка и доставка заказа Покупателю в собственность на условиях настоящего Договора.</li>
                            </ul>
                        </li>
                    </ol>
                </li>
                <li>
                    <h2>ПОРЯДОК ОФОРМЛЕНИЯ ЗАКАЗА</h2>
                    <ol>
                        <li>Заказ Покупателя может быть оформлен через оператора или посредством заполнения электронной формы заказа на сайте Интернет-магазина.</li>
                        <li>Покупатель имеет право оформить заказ на любой товар, представленный на сайте Интернет-магазина и имеющийся в наличии.</li>
                        <li>При отсутствии товара, Покупатель вправе заменить его аналогичным товаром, или отказаться от данного товара, аннулировать заказ.</li>
                        <li>При оформлении заказа через оператора или посредством электронной формы на сайте Интернет-магазина, Покупатель тем самым подтверждает, что он ознакомлен с условиями настоящей оферты и обязуется предоставить Продавцу всю информацию, необходимую для надлежащего оформления и исполнения Заказа.</li>
                        <li>При оформлении заказа через оператора или посредством электронной формы на сайте Интернет-магазина, Покупатель обязуется предоставить следующую регистрационную информацию о себе: имя, фактический адрес доставки, контактный телефон, e-mail и иную информацию, указанную в регистрационной форме на сайте.</li>
                        <li>Продавец не несет ответственности за содержание и достоверность информации, предоставленной Покупателем при оформлении заказа.</li>
                        <li>Покупатель несет ответственность за достоверность предоставленной информации при оформлении заказа.</li>
                        <li>Договор купли-продажи дистанционным способом между Продавцом и Покупателем считается заключенным с момента электронного оформления заказа на сервисе сайта Интернет-магазина www.де-шашлик.com.ua или выдачи Продавцом Покупателю кассового или товарного чека либо иного документа, подтверждающего оплату товара.</li>
                    </ol>
                </li>
                <li>
                    <h2>ЦЕНА ТОВАРА</h2>
                    <ol>
                        <li>Цены в интернет-магазине указаны в национальной валюте Украины (гривна) за единицу товара в соответствии с существующим прейскурантом.</li>
                        <li>Продавец оставляет за собой право изменять цену товара до момента оформления заказа без предупреждения Покупателя.</li>
                        <li>Окончательной является цена, указанная оператором при оформлении заказа.</li>
                        <li>Цены, указанные на сайте www.iqpizza.com.ua, носят ознакомительный характер.</li>
                    </ol>
                </li>
                <li>
                    <h2>ОПЛАТА ТОВАРА</h2>
                    <ol>
                        <li>При наличной форме оплаты Покупатель обязан уплатить Продавцу цену товара в момент его передачи, если иное не предусмотрено договоренностью или договором между сторонами.</li>
                        <li>Оплата товаров и услуг интернет-магазина www.iqpizza.com.ua осуществляется в национальной валюте Украины в соответствии с ценами и тарифами, установленными на момент покупки товаров.</li>
                        <li>Оплата товаров Интернет-магазина осуществляется в соответствии с разделом «Оплата и Доставка», опубликованном на сайте www.iqpizza.com.ua. Оплата зачисляется только при условии соблюдения порядка, опубликованном на сайте.</li>
                        <li>В случае не оплаты, неполной или несвоевременной оплаты Интернет-магазин оставляет за собой право непредоставления товара, приостановления или полного неисполнения взятых на себя обязательств и при этом не несет ответственности за возможные последствия такого решения/действий.</li>
                        <li>При безналичной форме оплаты обязанность Покупателя по уплате стоимости товара считается исполненной с момента зачисления соответствующих денежных средств на расчетный счет Продавца.</li>
                        <li>При безналичной форме расчета Покупатель обязан предъявить оператору или курьеру документ, подтверждающий факт оплаты товара (платежное поручение, квитанция об уплате т.д.).</li>
                        <li>Цены на любые позиции товара могут быть изменены Продавцом в одностороннем порядке. В случае изменения цены на заказе позиции товара к оплате заказа, Продавец обязан в кратчайшие сроки уведомить Покупателя о таких изменениях. Покупатель имеет право подтвердить или аннулировать заказ. В случае отсутствия связи с Покупателем заказ считается аннулированным в течение 24-х часов с момента изменения цены на товар.</li>
                    </ol>
                </li>
                <li>
                    <h2>ДОСТАВКА ТОВАРА</h2>
                    <ol>
                        <li>Порядок и условия доставки заказанного товара Покупатель согласовывает с оператором Интернет-магазина в момент оформления покупки.</li>
                        <li>Курьерская доставка заказа Покупателю осуществляется по согласованию с Покупателем и по указанному им адресу.</li>
                        <li>Самовывоз осуществляется Покупателем из заведения общественного питания (пиццерии) Продавца, который будет указан в заказе.</li>
                        <li>При получении товара заказчик должен в присутствии представителя курьера проверить соответствие товара качественным и количественным характеристикам, (наименование товара, количество, комплектность, срок годности).</li>
                        <li>Доставка заказа осуществляется в пределах зоны доставки (зона доставки указана на сайте www.iqpizza.com.ua или может быть уточнена по телефону).</li>
                    </ol>
                </li>
                <li>
                    <h2>ПРАВА И ОБЯЗАНОСТИ СТОРОН</h2>
                    <ol>
                        <li>
                            <span>Продавец обязуется:</span>
                            <ol>
                                <li>Не разглашать любую частную информацию Покупателя и не предоставлять доступ к этой информации третьим лицам, за исключением случаев, предусмотренных действующим законодательством Украины.</li>
                                <li>Предоставить Покупателю возможность получения бесплатных телефонных консультаций по телефонам, указанным на сайте www.iqpizza.com.ua. Объем консультаций ограничивается конкретными вопросами, связанными с выполнением заказа.</li>
                                <li>Продавец оставляет за собой право невыполнения заказа в случае возникновения форс-мажорных обстоятельств.</li>
                                <li>Продавец оставляет за собой право изменять настоящий Договор в одностороннем порядке до момента его заключения.</li>
                            </ol>
                        </li>
                        <li>Продавец не несет ответственности за ненадлежащее использование товаров Покупателем, заказанных в Интернет-магазине.</li>
                        <li>Продавец вправе передать свои права и обязанности по выполнению заказов третьим лицам.</li>
                        <li>
                            <span>Покупатель обязуется:</span>
                            <ol>
                                <li>К моменту обращения в Интернет-магазин ознакомиться с содержанием Договора-оферты, условиями оплаты и доставки на сайте магазина.</li>
                                <li>Право собственности на заказ, а также риск его случайного повреждения или потери переходят к Покупателю с момента передачи товара.</li>
                            </ol>
                        </li>
                    </ol>
                </li>
                <li>
                    <h2>ФОРС-МАЖОРНЫЕ ОСТОЯТЕЛЬСТВА</h2>
                    <ol>
                        <li>Стороны освобождаются от ответственности за неисполнение или ненадлежащее исполнение обязательств по условиям договора на время действия непреодолимой силы. Под непреодолимой силой понимаются чрезвычайные и непреодолимые при данных условиях обстоятельства, препятствующие исполнению своих обязательств сторонами по настоящему Договору. К ним относятся стихийные явления (землетрясения, наводнения и т.д.), обстоятельства общественной жизни (военные действия, чрезвычайные положения, крупнейшие забастовки, эпидемии и т.д.), запрещающие меры государственных органов (запрещение перевозок, валютные ограничения, международные санкции, запреты на торговлю и прочее). В течение этого времени стороны не имеют взаимных претензий и каждая из сторон принимает на себя свой риск по результатам форс-мажорных обстоятельств.</li>
                    </ol>
                </li>
                <li>
                    <h2>ИНФОРМАЦИЯ И ЕЕ ИСПОЛЬЗОВАНИЕ</h2>
                    <ol>
                        <li>
                            <span>Продавец собирает и обрабатывает персональные данные Покупателей (а именно: имя Покупателя, адрес доставки, контактные телефоны, адрес электронной почты и т.д.) в целях:</span>
                            <ul>
                                <li>выполнения условий данного Договора;</li>
                                <li>доставки Покупателю заказанной им продукции.</li>
                            </ul>
                        </li>
                        <li>Осуществляя заказ товара через Интернет-магазин, Покупатель дает согласие на сбор и обработку такой информации.</li>
                        <li>Доступ к персональным данным Покупателя имеют только лица, которые непосредственно вовлечены в выполнение заказа.</li>
                        <li>Продавец обязуется: соблюдать конфиденциальность в отношении персональных данных Покупателей; не допускать попытки несанкционированного использования персональных данных Покупателей третьими лицами; исключить доступ к персональным данным Покупателей, лиц, не имеющих непосредственного отношения к исполнению заявки, кроме случаев, предусмотренных действующим законодательством Украины</li>
                    </ol>
                </li>
                <li>
                    <h2>ПРОДАЖА ТОВАРА</h2>
                    <ol>
                        <li>Интернет-магазин не гарантирует безусловное выполнение заказа. Все товары предоставляются по принципу «как есть». Задействованные технические системы (веб-страница и сам сайт: www.iqpizza.com.ua) могут включать необнаруженные технические ошибки, следствием которых является невозможность выполнения заказа, покупки товара или его несвоевременное выполнение. Кроме того, к невозможности выполнения заказа или его несвоевременного выполнения могут привести обстоятельства, обусловленные человеческим фактором или же обстоятельства непреодолимой силы.</li>
                        <li>В случае невозможности или несвоевременного выполнения принятых на себя обязательств при обстоятельствах, изложенных в п.10.1 (исключая «форс-мажор») Интернет-магазин  несет ответственность в пределах сумм, полученных от клиентов в качестве оплаты за товары.</li>
                        <li>Интернет-магазин  не несет и ни при каких обстоятельствах и не может нести никакой материальной ответственности, выходящей за рамки сумм, полученных от клиента в качестве оплаты за товары и услуги.</li>
                        <li>Интернет-магазин  оставляет за собой право отказать в предоставлении услуг или продажи товара Покупателю в случае отсутствия у него возможности предоставить соответствующий товар.</li>
                        <li>
                            <span>Денежные средства, оплаченные Покупателем, не возвращаются в следующих случаях:</span>
                            <div>
                                <p>1) отказ клиента от полученного товара после оплаты и получения от курьера;</p>
                                <p>2) если данный товар является надлежащего качества.</p>
                            </div>
                        </li>
                    </ol>
                </li>
                <li>
                    <h2>АВТОРСКИЕ ПРАВА</h2>
                    <ol>
                        <li>Вся текстовая информация и графические изображения, размещенные на сайте интернет-магазина: www.iqpizza.com.ua являются собственностью продавца и/или его поставщиков и производителей Товара.</li>
                    </ol>
                </li>
            </ol>
        </div>
    );
}

export default ArticleRu;