import React from 'react';
import { withRouter } from "react-router";
import { useTranslation } from "react-i18next";

import s from './style.module.css';

const Success = (props) => {
    const { t, i18n } = useTranslation();
       return(
        <div className={s.container}>
            <div className={s.content}>
            <div className={s.row}>
            <img className={s.logo} src={require('../../assets/logo_success.jpg')}/>
            <h1>{t('successTitle')}</h1>
            <h3 className={s.title}>{t('successInfoFirst')} №{props.match.params.order} {t('successInfoLast')}</h3>
            {/* <p className={s.description}>{t('successTimeFirst')} {props.match.params.phone} {t('successTimeLast')}</p> */}
            <p className={s.description}>{t('successTimeFirst')}</p>

            <p className={s.description}>{t('successThanksYou')} <br />{t('successFollowWithUs')}</p>    
            <div className={s.socialContainer}>
                <a target="_blank" href="https://www.instagram.com/iq_pizza_ukraine/" className={s.social}>
                    <img src={require('../../assets/instagram.svg')}/>
                </a>
            </div>
            <div className={s.bt}>
            <a href='tel:0681151010' className={s.button}>
                <p>{t('successQuestions')}</p>
                <span class="">068 115 10 10</span>
            </a>
           {/**<Button title={t('main')} 
                        handleClick={() => props.history.push('/')}/> */}
            </div>
            </div>
            </div>
        </div>
       )
    
}


export default withRouter(Success);