export const getCookie = (name) => {
    let matches = document.cookie.match(new RegExp(
        "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
      ));
      return matches ? decodeURIComponent(matches[1]) : undefined;
}

export const setCookie = (name, value, options = {}) => {
    options = { path: '/', ...options };
    
    if (options.expires instanceof Date) {
        options.expires = options.expires.toUTCString();
    }
    
    let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);
    
    for (let optionKey in options) {
        updatedCookie += "; " + optionKey;
        let optionValue = options[optionKey];

        if (optionValue !== true) {
          updatedCookie += "=" + optionValue;
        }
    }

    document.cookie = updatedCookie;
};

export function eraseCookieFromAllPaths(name) {
    // This function will attempt to remove a cookie from all paths.
    var pathBits = window.location.pathname.split('/');
    var pathCurrent = ' path=';

    // do a simple pathless delete first.
    document.cookie = name + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT;';

    for (var i = 0; i < pathBits.length; i++) {
        pathCurrent += ((pathCurrent.substr(-1) != '/') ? '/' : '') + pathBits[i];
        document.cookie = name + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT;' + pathCurrent + ';';
    }
}

export function deleteAllCookies() {
    var cookies = document.cookie.split(";");

    for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        var eqPos = cookie.indexOf("=");
        var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
}

export const getActiveCity = (cityName) => {
    switch(cityName) {
        case '6bf17b9e-3ecd-0491-0179-0e815f2c8b26':
          return {
            name: {uk: 'Дніпро', ru: 'Днепр'},
            id: '6bf17b9e-3ecd-0491-0179-0e815f2c8b26',
            gtm: 'GTM-T72MPSK'
          };
        case 'fe74eb1c-055f-4767-b1e6-1a8d7df54070':
          return {
            name: {uk: 'Запоріжжя', ru: 'Запорожье'},
            id: 'fe74eb1c-055f-4767-b1e6-1a8d7df54070',
            gtm: 'GTM-K2SJCVK'
          }
        case '6bf17b9e-3ecd-0491-0179-0e815f2cf1aa':
          return {
            name: {uk: 'Чернігів', ru: 'Чернигов'},
            id: '6bf17b9e-3ecd-0491-0179-0e815f2cf1aa',
            gtm: 'GTM-5MSC8LM',
          }
        case '6bf17b9e-3ecd-0491-0179-0e815f2c9df9':
          return {
            name: {uk: 'Київ', ru: 'Киев'},
            id: '6bf17b9e-3ecd-0491-0179-0e815f2c9df9',
            gtm: 'GTM-TMWF9DP',
          }
        case '6bf17b9e-3ecd-0491-0179-0e815f2c8027':
          return {
            name: {uk: 'Бердянськ', ru: 'Бердянск'},
            id: '6bf17b9e-3ecd-0491-0179-0e815f2c8027',
            gtm: 'GTM-KKNW5VM',
          }
        case '6bf17b9e-3ecd-0491-0179-0e815f2cc7c8':
          return {
            name: {uk: 'Новомосковськ', ru: 'Новомосковск'},
            id: '6bf17b9e-3ecd-0491-0179-0e815f2cc7c8',
            gtm: 'GTM-552NJS4',
          }
        case '6bf17b9e-3ecd-0491-0179-0e815f2cc139':
          return {
            name: {uk: 'Мелітополь', ru: 'Мелитополь'},
            id: '6bf17b9e-3ecd-0491-0179-0e815f2cc139',
            gtm: 'GTM-K6FLFMW',
          }
        case '50cf0215-f3a4-4f8c-8905-676ff787cb69':
          return {
            name: {uk: 'Енергодар', ru: 'Энергодар'},
            id: '50cf0215-f3a4-4f8c-8905-676ff787cb69',
            gtm: 'GTM-T4PSRD2',
          }
        case '417177e7-cbf6-4039-ac09-9e94d8fea689':
          return {
            name: {uk: 'Павлоград', ru: 'Павлоград'},
            id: '417177e7-cbf6-4039-ac09-9e94d8fea689',
            gtm: 'GTM-PQ3VMKJ',
          }
        case '26980b21-5caf-4298-b933-ce094cc10ac0':
          return {
            name: {uk: 'Токмак', ru: 'Токмак'},
            id: '26980b21-5caf-4298-b933-ce094cc10ac0',
            gtm: 'GTM-N6PSNR8'
          }
        case '924a64d7-6405-4847-a07b-7ec3d077e9be':
          return {
            name: {uk: 'Вольнянск', ru: 'Вольнянск'},
            id: '924a64d7-6405-4847-a07b-7ec3d077e9be',
            gtm: 'GTM-KL848LG',
          }
        case '716229c9-6a8f-4cf9-8a9e-99de17ae4c18':
          return {
            name: {uk: 'Дніпрорудне', ru: 'Днепрорудное'},
            id: '716229c9-6a8f-4cf9-8a9e-99de17ae4c18',
            gtm: 'GTM-WXW2TP6',
          }
        case '3f8bb8cf-f815-493d-a0ac-67f64f2329bd':
          return {
            name: {uk: 'Пологи', ru: 'Пологи'},
            id: '3f8bb8cf-f815-493d-a0ac-67f64f2329bd',
            gtm: 'GTM-K4KS2DR',
          }
        case '5da9c4d0-aaf7-4b1c-85d0-8d8ee6cf7d46':
          return {
            name: {uk: 'Гуляйполе', ru: 'Гуляйполе'},
            id: '5da9c4d0-aaf7-4b1c-85d0-8d8ee6cf7d46',
            gtm: 'GTM-PRZMNTJ'
          }
        case 'a8ec38d3-a1bd-4226-a527-2786bb44634b':
          return {
            name: {uk: 'Першотравенск', ru: 'Першотравенск'},
            id: 'a8ec38d3-a1bd-4226-a527-2786bb44634b',
            gtm: 'GTM-TPLDXK8'
          }
        case '6c4e0035-0ca0-4965-8e18-bbadb05e9775':
          return {
            name: {uk: 'Кам\'янка-Дніпровська', ru: 'Каменка-Днепровская'},
            id: '6c4e0035-0ca0-4965-8e18-bbadb05e9775',
            gtm: 'GTM-PH3V9N5'
          }
        case '6bf17b9e-3ecd-0491-0179-0e815f2c9c1e':
          return {
            name: {uk: 'Кам\'янське', ru: 'Каменское'},
            id: '6bf17b9e-3ecd-0491-0179-0e815f2c9c1e',
            gtm: 'GTM-MTTGWQ5'
          }
        case '0c8397ce-f3b6-b0e6-017e-4e62ecc739dd':
          return {
            name: {uk: 'Нікополь', ru: 'Никополь'},
            id: '0c8397ce-f3b6-b0e6-017e-4e62ecc739dd',
            gtm: null
          }
        case 'ace87627-d363-404d-93c4-7ceab2ed7d97':
          return {
            name: {uk: 'Михайлівка', ru: 'Михайловка'},
            id: 'ace87627-d363-404d-93c4-7ceab2ed7d97',
            gtm: null
          }
        default:
          return {
            name: {uk: 'Київ', ru: 'Киев'},
            id: '6bf17b9e-3ecd-0491-0179-0e815f2c9df9',
            gtm: 'GTM-TMWF9DP'
          };
      }
}