import React from 'react';
import './article.style.css';

const ArticleRu = () => {
    return (
        <div className="privacy-policy">
            <h1>ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ</h1>
            <p>Настоящая Политика конфиденциальности (далее – «Политика конфиденциальности») разработана в соответствии с требованиями Законодательства Украины  «О защите персональных данных» и действует в отношении всей информации, которую Интернет-магазин «IQ PIZZA»,  расположенный на доменном имени https://iqpizza.com.ua/(далее – «Интернет-магазин»), может получить о Пользователе во время использования сайта Интернет-магазина.</p>
            <ol>
                <li>
                    <h2>Термины и определения</h2>
                    <p>В настоящей Политике конфиденциальности используются следующие термины:</p>
                        <ol>
                            <li>«Администрация сайта Интернет-магазина «IQPIZZA» (далее – Администрация сайта) – уполномоченные сотрудники на управления сайтом, действующие от имени Продавца, которые организуют и (или) осуществляет обработку персональных данных, а также определяет цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными.</li>
                            <li>«Персональные данные» – любая информация, относящаяся к прямо или косвенно определенному или определяемому физическому лицу (субъекту персональных данных).</li>
                            <li>«Обработка персональных данных» – любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных.</li>
                            <li>«Конфиденциальность персональных данных» – обязательное для соблюдения Оператором или иным получившим доступ к персональным данным лицом требование не допускать их распространения без согласия субъекта персональных данных или наличия иного законного основания.</li>
                            <li>«Пользователь сайта Интернет-Магазина «IQ PIZZA» (далее – Пользователь)» – лицо, имеющее доступ к Сайту, посредством сети Интернет и использующее Сайт интернет-магазина.</li>
                            <li>«IP-адрес» — уникальный сетевой адрес узла в компьютерной сети, построенной по протоколу IP.</li>
                            <li>«Cookies» — небольшой фрагмент данных, отправленный веб-сервером и хранимый на компьютере пользователя, который веб-клиент или веб-браузер каждый раз пересылает веб-серверу в HTTP-запросе при попытке открыть страницу соответствующего сайта.</li>
                        </ol>
                </li>
                <li>
                    <h2>Общие положения</h2>
                    <ol>
                        <li>Использование сайта Интернет-магазина означает безоговорочное согласие Пользователя с настоящей Политикой и указанными в ней условиями обработки его персональной информации, данное им в соответствии с настоящей Политикой в рамках отношений с Продавцом. В случае несогласия с этими условиями Пользователь должен воздержаться от использования сайта Интернет-магазина.</li>
                        <li>
                            <span>Персональные данные, разрешённые к обработке в рамках настоящей Политики конфиденциальности, предоставляются Пользователем самостоятельно при регистрации (создании учётной записи) или в процессе использования Сервисов путём заполнения регистрационной формы на сайте Интернет-магазина в разделе «ОФОРМЛЕНИЕ ЗАКАЗА» и включают в себя следующую информацию:</span>
                            <ol>
                                <li>ФИО;</li>
                                <li>Адрес;</li>
                                <li>Населенный пункт;</li>
                                <li>Телефон;</li>
                                <li>Средства связи;</li>
                                <li>Иная информация, добровольно предоставляемая Пользователем во время посещения сайта Интернет-магазина.</li>
                            </ol>
                        </li>
                        <li>Данные, которые автоматически передаются Сервисам в процессе их использования с помощью установленного на устройстве Пользователя программного обеспечения, в том числе IP-адрес, информация из cookie, информация о браузере Пользователя (или иной программе, с помощью которой осуществляется доступ к Сервисам), время доступа, адрес запрашиваемой страницы.</li>
                        <li>Настоящая Политика конфиденциальности применяется только к сайту Интернет-магазина.</li>
                        <li>Администрация сайта не проверяет достоверность персональных данных, предоставляемых Пользователем сайта Интернет-магазина.</li>
                    </ol>
                </li>
                <li>
                    <h2>Цели сбора и обработки персональной информации Пользователей</h2>
                    <ol>
                        <li>Интернет-магазин «IQ PIZZA» собирает и хранит только те персональные данные, которые необходимы для предоставления Сервисов (исполнения оферты и/или иных договоров с Пользователем).</li>
                        <li>
                            <span>Персональную информацию Пользователя Интернет-магазин «IQ PIZZA» может использовать в следующих целях:</span>
                            <ol>
                                <li>идентификация стороны в рамках оферты и договоров с Интернет-магазином «IQ PIZZA»;</li>
                                <li>предоставление Пользователю персонализированных Сервисов;</li>
                                <li>предоставление Пользователю с его согласия обновлений продукции, специальных предложений, информации о ценах, новостной рассылки и иных сведений от имени Интернет-магазина, в том числе направление уведомлений, запросов и информации, касающихся использования Сервисов, а также обработка запросов и заявок от Пользователя;</li>
                                <li>улучшение качества Сервисов, удобства их использования, разработка новых Сервисов;</li>
                                <li>рекламы своих продуктов с согласия Пользователя;</li>
                                <li>проведение статистических и иных исследований, на основе предоставленных данных.</li>
                            </ol>
                        </li>
                        <li>Интернет-магазин осуществляет сбор статистики об IP-адресах своих посетителей. Данная информация используется с целью выявления и решения технических проблем, для контроля законности проводимых финансовых платежей.</li>
                    </ol>
                </li>
                <li>
                    <h2>Условия обработки персональной информации Пользователя</h2>
                    <ol>
                        <li>Интернет-магазин «IQ PIZZA» хранит персональную информацию Пользователей в своей базе персональных данныхобеспечивая их конфиденциальность и защиту от неправомерного или случайного доступа к ним третьих лиц.</li>
                        <li>
                            <span>Интернет-магазин «IQ PIZZA» вправе передать персональную информацию Пользователя третьим лицам в следующих случаях:</span>
                            <ol>
                                <li>передача необходима в рамках использования Пользователем определенного Сервиса;</li>
                                <li>такая передача происходит в рамках продажи или иной передачи бизнеса (полностью или в части), при этом к приобретателю переходят все обязательства по соблюдению условий настоящей Политики применительно к полученной им персональной информации;</li>
                                <li>в целях обеспечения возможности защиты прав и законных интересов владельца Интернет-магазина или третьих лиц в случаях, когда Пользователь нарушает оферту с Интернет-магазином «IQ PIZZA»;</li>
                            </ol>
                        </li>
                        <li>Пользователь соглашается с тем, что Администрация сайта вправе передавать персональные данные третьим лицам, в частности, курьерским службам, организациями почтовой связи, операторам электросвязи, в целях выполнения заказа Пользователя, оформленного на сайте Интернет-магазина, включая доставку заказанной продукции, а также в целях информирования и привлечения внимания Пользователя об условиях оказания услуг, включая рекламные акции, новой продукции.</li>
                        <li>При утрате или разглашении персональных данных Администрация сайта информирует Пользователя об утрате или разглашении персональных данных.</li>
                        <li>Администрация сайта принимает необходимые организационные и технические меры для защиты персональной информации Пользователя от неправомерного или случайного доступа, уничтожения, изменения, блокирования, копирования, распространения, а также от иных неправомерных действий третьих лиц.</li>
                        <li>Администрация сайта совместно с Пользователем принимает все необходимые меры по предотвращению убытков или иных отрицательных последствий, вызванных утратой или разглашением персональных данных Пользователя.</li>
                        <li>Персональные данные Пользователя могут быть переданы уполномоченным органам государственной власти Украины только по основаниям и в порядке, установленным законодательством Украины.</li>
                        <li>При обработке персональных данных Пользователей  Интернет-магазином «IQ PIZZA» руководствуется Законом Украины “О защите персональных данных” от 01.06.2010 № 2297-VI.</li>
                    </ol>
                </li>
                <li>
                    <h2>Обязательства Сторон</h2>
                    <ol>
                        <li>
                            <span>Администрация сайта обязуется</span>
                            <ol>
                                <li>Использовать полученную информацию исключительно для целей, указанных в п. 3 настоящей Политики конфиденциальности.</li>
                                <li>Обеспечить хранение конфиденциальной информации в тайне, не разглашать без предварительного письменного разрешения Пользователя, а также не осуществлять продажу, обмен, опубликование, либо разглашение иными возможными способами переданных персональных данных Пользователя, за исключением п.п. 4.3 и 4.7 настоящей Политики Конфиденциальности.</li>
                                <li>Принимать меры предосторожности для защиты конфиденциальности персональных данных Пользователя согласно порядку, обычно используемого для защиты такого рода информации в существующем деловом обороте.</li>
                                <li>Осуществить блокирование персональных данных, относящихся к соответствующему Пользователю, с момента обращения или запроса Пользователя или его законного представителя либо уполномоченного органа по защите прав субъектов персональных данных на период проверки, в случае выявления недостоверных персональных данных или неправомерных действий.</li>
                            </ol>
                        </li>
                        <li>
                            <span>Пользователь обязуется:</span>
                            <ol>
                                <li>Предоставить информацию о персональных данных, необходимую для пользования сайтом Интернет-магазина.</li>
                                <li>Обновить, дополнить предоставленную информацию о персональных данных в случае изменения данной информации.</li>
                            </ol>
                        </li>
                    </ol>
                </li>
                <li>
                    <h2>Дополнительные условия.</h2>
                    <ol>
                        <li>Интернет-магазин «IQ PIZZA» имеет право вносить изменения в настоящую Политику без согласия Пользователя. При внесении изменений в актуальной редакции указывается дата последнего обновления. Пользователь обязуется самостоятельно контролировать наличие изменений в настоящей Политике.</li>
                        <li>Новая редакция Политики вступает в силу с момента ее размещения на Сайте интернет – магазина, если иное не предусмотрено новой редакцией Политики.</li>
                        <li>Действующая Политика конфиденциальности размещена на странице по адресу: https://iqpizza.com.ua/polityka-konfidencijnosti/</li>
                    </ol>
                </li>
            </ol>
        </div>
    );
}

export default ArticleRu;