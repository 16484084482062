import React from 'react';
import { useSelector } from 'react-redux';
import './article.style.css';

const ArticleRu = () => {
    const activeCity = useSelector(state => state.menu.activeCity);
    const price = activeCity.id === 'fe74eb1c-055f-4767-b1e6-1a8d7df54070' ? '59' : activeCity.id === '6bf17b9e-3ecd-0491-0179-0e815f2c9df9' ? '65' : '49';
    return (
        <div className="privacy-policy">
            <h1>Про IQ pizza</h1>
            <p>Сеть умных пиццерий IQ pizza — это новый подход к созданию пиццы. Наша команда приготовит вашу любимую пиццу быстро, качественно и безопасно.</p>
            <p>Доступное качество — это философия нашего бренда, которую мы внедряем и которой руководствуемся в нашей работе. Это вдохновило нас на создание меню, которое с легкостью удовлетворит каждого любителя пиццы и не только.</p>
            <p>Цены приятно удивят своей доступностью — от {price} грн за 30 см пиццу Маргарита.</p>
            <p>Время приготовления позволит получить любимую пиццу уже через 7 минут после заказа.</p>
            <p>Качество продукции мы гарантируем благодаря свежим ингредиентам и проверенным поставщикам</p>
            <p>Быстрая доставка привезет вашу свежую и горячую пиццу быстрее, чем за час.</p>
            <p>Разумный подход — это современное оборудование, профессиональная команда и совершенные стандарты работы.</p>
            <p>Будем рады видеть Вас!</p>
            <p>А даже если что-то пойдет не так, обратитесь на горячую линию 068 115 10 10  — мы быстро все исправим!</p>
        </div>
    );
}

export default ArticleRu;