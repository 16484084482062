import React from 'react';
import { useSelector } from 'react-redux';
import './article.style.css';

const ArticleUa = () => {
    const activeCity = useSelector(state => state.menu.activeCity);
    const price = activeCity.id === 'fe74eb1c-055f-4767-b1e6-1a8d7df54070' ? '59' : activeCity.id === '6bf17b9e-3ecd-0491-0179-0e815f2c9df9' ? '65' : '49';
    return (
        <div className="about-us">
            <h1>Про IQ pizza</h1>
            <p>Мережа розумних піцерій IQ pizza — це новий підхід до створення піци. Наша команда приготує вашу улюблену піцу швидко, якісно та безпечно.</p>
            <p>Доступна якість — це філософія нашого бренду, яку ми впроваджуємо та якою керуємося в нашій роботі. Наша філософія та розумний підхід надихнули нас на створення меню, що задовольнить будь-якого любителя піци і не тільки. </p>
            <p>Ціни приємно здивують своєю доступністю — від {price} грн за 30 см піцу Маргарита. </p>
            <p>Час приготування дозволить отримати улюблену піцу через 7 хвилин після замовлення.</p>
            <p>Якість продукції ми гарантуємо завдяки свіжим інгредієнтам та перевіреним постачальникам.</p>
            <p>Швидка доставка привезе вашу свіжу та гарячу піцу швидше, ніж за годину.</p>
            <p>Розумний підхід — це сучасне обладнання, професійна команда та досконалі стандарти роботи.</p>
            <p>Будемо раді бачити Вас!</p>
            <p>А навіть якщо щось піде не так, зверніться на гарячу лінію 068 115 10 10 — ми швидко все виправимо!</p>
        </div>
    );
}

export default ArticleUa;