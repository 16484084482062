import React from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, connect } from 'react-redux';
import { useTranslation } from "react-i18next";
import { withRouter } from 'react-router-dom';

import ArticleRu from './articles/ru';
import ArticleUk from './articles/uk';
import s from './delivery.module.css';

const Delivery = (props) => {
    const { t, i18n } = useTranslation(); 
    const city = props.cities.find((it) => it.id === props.activeCity.id);
    const lng = useSelector(state => state.menu.languageMenu);
    const isHomePage = useSelector(state => state.menu.isHomePage);
    const cityName = city && lng && city.name[lng];

    const firstTitleMessage = lng === 'uk' ? '🛵 Доставка піци поруч з будинком ' : '🛵 Доставка пиццы рядом с домом ';
    const title = firstTitleMessage + cityName + ' ' + t('deliveryTitle');
    const firstDescriptionMessage = lng === 'uk' ? 'Купити піцу з доставкою ' : 'Купить пиццу с доставкой ';
    const description = firstDescriptionMessage + cityName + ' ' + t('deliveryDescription');

    const isDelivery = props.location.pathname == '/dostavka/' || props.location.pathname == '/ru/dostavka/';
    const host = !isHomePage ? window.location.host.split('.')[0] + '.': '';
    const canonicalUrl = lng === 'uk' ? `https://${host}iqpizza.com.ua/dostavka/` : `https://${host}iqpizza.com.ua/ru/dostavka/`;

    // console.log('canonicalUrl -->>>', canonicalUrl);
    
    return(
        <div className={s.delivery}>
            {isDelivery && (
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{title}</title>
                    <meta name="robots" content="index, follow"/>
                    <meta name="description" content={description} />
                    <link rel="canonical" href={canonicalUrl} />
                    <link rel="alternate" hreflang="x-default" href={`https://${host}iqpizza.com.ua/dostavka/`} />
                    <link rel="alternate" hreflang="ru" href={`https://${host}iqpizza.com.ua/ru/dostavka/`} />
                </Helmet>
            )}
            {isDelivery && (
                <div className="container">
                    <div className="row">
                        {lng === 'uk' ? <ArticleUk cityName={cityName} cityId={city.id} /> : <ArticleRu cityName={cityName} cityId={city.id} />}
                    </div>
                </div>
            )}
            <div className={s.delivery_container}>
                <div className="row">
                    <h2>{t('zones')}</h2>
                    <div className={s.container_wrap}>
                        {/* <h2 className={s.delivery_title}>{t('zones')}</h2> */}
                        <div className={s.delivery_content}>
                            <ul>
                                <li>{t('deliveryCost')}</li>
                                <li>{t('deliveryFree')}</li>
                                <li>{t('minOrder')}</li>
                            </ul>
                            <p>{t('deliveryInfo1')}</p>
                        </div>
                    </div>
                    <div className={s.container_wrap}>
                        <h2 className={s.delivery_title}>{t('payment')}</h2>
                        <div className={s.delivery_content}>
                            <ul>
                                <li>{t('paymentInfo1')}</li>
                                <li>{t('paymentInfo2')}</li>
                                <li>{t('paymentInfo3')}</li>
                            </ul>
                        </div>
                    </div>
                    <div className={s.container_wrap}>
                        <h2 className={s.delivery_title}>{t('acceptOrders')}</h2>
                        <div className={s.delivery_content}>
                            <ul>
                                <li>{t('deliveryInfo2')}</li>
                                <li>{t('deliveryInfo3')}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className={s.pickup}>
                <div className="container">
                    <div className="row">
                        <div className={s.pickup_title}>
                            {t('points')} {city.name[props.language]}
                        </div>
                        {city.terminals.map((item, index) => {
                            return(
                                <div key={index} className={s.pickup_info}>
                                    <span>{item.name[props.language]}</span>
                                    <div className={s.time}>{item.time}</div>
                                </div>
                            )
                        })}
                        <div className={s.zones}>
                            <div className={s.pickup_title}>{t('deliveryAreas')}</div>
                            <iframe src={city.map} width="100%" height="450"></iframe>
                        </div>
                    </div>
                </div>
        </div>
    </div>
    )
}

const mapStateToProps = (state) => {
    return {
        cities: state.menu.cities,
        activeCity: state.menu.activeCity,
        language: state.menu.languageMenu,
    }
}

export default connect(mapStateToProps, null)(withRouter(Delivery));