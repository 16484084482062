import React from 'react';
import './article.style.css';

const ArticleUa = () => {
    return (
        <div className="about-us">
            <h1>ПІЦА ПЕПЕРОНІ</h1>
            <p>Піца пепероні 30 см - пікантна страва для поціновувачів італійської кухні. Соус з «вогником», копченості та соковиті томати - в цій піці прекрасно все! А де найсмачніша піца пепероні 30 см? Звичайно ж в IQ Pizza! У випічки з нашого меню тонке тісто. Ми зробили це для того, щоб покласти якомога більше начинки для вашого більшого задоволення.</p>
            <p>Начинка випічки, яку можна замовити:</p>
            <ul>
                <li>соус томатний гострий;</li>
                <li>сир "моцарела";</li>
                <li>пепероні;</li>
                <li>халапеньйо.</li>
            </ul>
            <p>Ця страва має насичений «вогняний» смак. Те, що потрібно поціновувачам по-гостріше! Хочете зробити свій обід ще більш пікантним і ситним? Додайте до нього додаткові інгредієнти!</p>
            <p>Піца пепероні 30 см буде ідеально поєднуватися з такими продуктами: рукола, солодкий перець, помідори коктейльні, кукурудза. Чи можна зробити свій італійський обід ще кращим? Звісно так! Ви можете додати в бортик сосиски або сир та насолоджуватись!</p>
        </div>
    );
}

export default ArticleUa;