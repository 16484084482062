import React from 'react';
import { useTranslation } from "react-i18next";

const Pica30sm = () => {
	 const { t, i18n } = useTranslation(); 
    return (
        <div style={{
            height: 400,
            overflowY: 'scroll',
        }}>
            <h1>{t('pizza30')}</h1>
			<p>Піца 30 см - це і ситний обід, і смачний перекус, і відмінна вечеря для всієї родини. Де замовити смачну гарячу випічку з апетитною начинкою, з швидкою доставкою, у якої доступна ціна? Звичайно ж в IQ Pizza! Зараз ми розповімо вам про випічку 30 см від нашого закладу.</p>
			<h2>Скільки важить піца 30 см</h2>
			<p>Піца 30 см - це мінімальний розмір випічки в нашому закладі. У нашому меню представлена піца 30 см, яка має різну вагу. Все залежить від типу начинки, яку ви побажаєте додати до випічки, а також від наповнення бортика.</p>
			<p>Ось приблизна вага піци діаметром 30 см:</p>
			<ul>
				<li>«2 сири» - 320 грам;</li>
				<li>«4 м'яса» - 510 грам;</li>
				<li>«4 м'яса» DELUX - 600 грам;</li>
				<li>«Альфредо» - 425 грам;</li>
				<li>«Гавайська» - 465 грам;</li>
				<li>«Карбонара» - 450 грам.</li>
			</ul>
			<p>Ознайомитись з повним асортиментом, вагою, а також вивчити склад нашої випічки на тонкому тісті ви можете, перейшовши до пункту «Меню» на нашому офіційному сайті в розділі «Піца 30 см». Також всі страви з докладним описом начинки і ваги представлені в нашому мобільному додатку. Там же ви можете ознайомитися з умовами доставки від нашого закладу.</p>
			<h2>На скільки людей розрахована піца 30 см</h2>
			<p>Ми хочемо, щоб всі наші клієнти могли насолодитися смачним і апетитним частуванням. Кожна смачна піца 30 см від нашого закладу ріжеться на 8 однакових шматочків. Вона ідеально підійде як для посиденьок в галасливій компанії, так і для домашньої вечері на двох.</p>
			<p>Якщо ви замовляєте випічку для вечірки в компанії з 4 чоловік, то кожен отримає по 2 шматочки смачного частування, у якого апетитне, тонке тісто. Якщо ви вирішили повечеряти вдвох, то кожен зможе насолодитися цілими чотирма шматочками апетитного частування, у якого багато свіжої і ситної начинки.</p>
			<p>Піца 30 см від IQ Pizza - це смачний, швидкий перекус для кожного. Вибирайте випічку собі до душі, та швидше замовляйте ситне і ароматне частування!</p>
		</div>
    );
}

export default Pica30sm;