import React from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { updateActiveCity } from '../../redux/menu';
import s from './modalcity.module.css';

import { setCookie, getCookie, getActiveCity } from './cookie';

const ModalCities = () => {
    const dispatch = useDispatch();
    const cities = useSelector(state => state.menu.cities);
    const language = useSelector(state => state.menu.languageMenu);
    const isHomePage = useSelector(state => state.menu.isHomePage);

    const handleCityClick = (id) => {
        const city = cities.find(t => t.id === id);
        const cityId = city.id;
        const cityCookie = getCookie('city');

        const updateCity = () => {
            if (isHomePage) {
                setCookie('city', cityId, {secure: true, 'max-age': 3600});
            }

            const activeCity = getActiveCity(cityId);
            dispatch(updateActiveCity(activeCity));
        }

        if (!cityCookie) updateCity();

        if (cityCookie) {
            if (cityCookie !== cityId) updateCity();
        }
    }

    return (
        <div className={s.modalCities}>
            <div className={s.screen}></div>
            <div className={s.citiesRow}>
                <div className={s.modal}>
                    <h6 className={s.title}>Виберіть своє місто</h6>
                    <div className={s.cities}>
                        {cities.map(city => (
                            <a 
                                href={city.url}
                                key={city.id}
                                className={s.city} 
                                onClick={() => handleCityClick(city.id)
                            }>
                                {city.name[language]}
                            </a>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(ModalCities)
