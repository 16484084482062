import React from 'react';

import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import s from './categories.module.css';

const Categories = (props) => {
    const { t, i18n } = useTranslation(); 
    const id = props.match.params.id;
    const path = props.match.path;
    const lng = useSelector(state => state.menu.languageMenu);
    const activeCity = useSelector(state => state.menu.activeCity);
    const cityName = activeCity.name[lng];
    const isDefaultLanguage = lng === 'uk';

    const firstMessageNapoji = isDefaultLanguage ? '🥤 Напої до піци з доставкою 🥤 | ' + cityName + ' | ' : '🥤 Напитки к пицце с доставкой 🥤 | ' + cityName + ' | ';
    const titleNapoji = firstMessageNapoji + t('napojiTitle');
    const firstMessagePizza30 = isDefaultLanguage ? '🍕 Піца 30 см 🍕 | ' + cityName + ' | ' : '🍕 Пицца 30 см 🍕 | '  + cityName + '| ';
    const titlePizza30 = firstMessagePizza30 + t('pizza30Title');
    const firstMessagePizza45 = isDefaultLanguage ? '🍕 Піца 45 см 🍕 | ' + cityName + ' | ' : '🍕 Пицца 45 см 🍕 | '  + cityName + '| ';
    const titlePizza45 = firstMessagePizza45 + t('pizza45Title');
    const firstMessageHopHey = isDefaultLanguage ? '🍕 Піца 30 см 🍕 | ' + cityName + ' | ' : '🍕 Пицца 30 см 🍕 | '  + cityName + '| ';
    // const titleHopHey = firstMessageHopHey + t('hophey');

    const firstDescriptionNapoji = isDefaultLanguage ? 'Замовити безалкогольні напої до піци з достакой додому, роботу і офіс ' + cityName + ' ' : 'Заказать безалкогольные напитки к пицце с доставкой на дом, работу и офис ' + cityName + ' ';
    const descriptionNapoji = firstDescriptionNapoji + t('napojiDescription');
    const firstDescriptionPizza30 = isDefaultLanguage ? 'Вибирайте піцу діаметром 30 см для компанії '  + cityName + ' | ' : 'Выбирайте пиццу диаметром 30 см для компании '  + cityName + ' ';
    const descriptionPizza30 = firstDescriptionPizza30 + t('pizza30Description');
    const firstDescriptionPizza45 = isDefaultLanguage ? 'Вибирайте піцу діаметром 45 см для компанії '  + cityName + ' | ' : 'Выбирайте пиццу диаметром 45 см для компании '  + cityName + ' ';
    const descriptionPizza45 = firstDescriptionPizza45 + t('pizza45Description');

    const firstTitleMenu = isDefaultLanguage ? '🍕 Піца Меню 🍕 | ' + cityName + ' | ' : '🍕 Пицца Меню 🍕 | ' + cityName + ' | ';
    const menuTitle = firstTitleMenu + t('menuTitle');
    const firstDescriptionMenu = isDefaultLanguage ? 'Замовити піцу на тонкому тісті ' + cityName + ' ' : 'Заказать пиццу на тонком тесте с сырными и сосисочными бортиками ' + cityName + ' ';
    const descriptionMenu = firstDescriptionMenu + t('menuDescription');

    let title = '';
    let description = '';

    const napitki = path.indexOf(isDefaultLanguage ? 'napoji' : 'napitki') !== -1;
    const isPizza30 = path.indexOf(isDefaultLanguage ? 'pica-30-sm' : 'picca-30-sm') !== -1;
    const isPizza45 = path.indexOf(isDefaultLanguage ? 'pica-45-sm' : 'picca-45-sm') !== -1;
    // const isHophey = path.indexOf(isDefaultLanguage ? 'hophey' : 'hophey') !== -1;

    title = napitki ? titleNapoji : isPizza30 ? titlePizza30 : isPizza45 ? titlePizza45 : menuTitle;
    description = napitki ? descriptionNapoji : isPizza30 ? descriptionPizza30 : isPizza45 ? descriptionPizza45 : descriptionMenu;
    let pathMatch = props.match.path.split('/').filter(t => !!t);
    pathMatch = pathMatch[pathMatch.length - 1];
    const isCategory = pathMatch === 'napoji' || pathMatch === 'napitki' || pathMatch === 'pica-30-sm' || pathMatch === 'picca-30-sm' || pathMatch === 'pica-45-sm' || pathMatch === 'picca-45-sm'

    // console.log('isCategory categorii', isCategory);
    return(
        <div className={s.categoriesContainer}>
            {true && (
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="robots" content="noindex, nofollow"/>
                <title>{title}</title>
                <meta name="description" content={description} />
                <link rel="canonical" href={`${window.location.href}`} />
            </Helmet>
            )}
            <div className={s.categories_container}>
                {props.categories
                    .sort((a, b) => a.order-b.order)
                    .map((item, index) => {
                        let search = props.products.find(it => it.parentGroup === item.id);
                        if (search) {
                            return (
                                <span key={index} onClick={() => props.updateCategory(item.id)} className={props.activeCategory === item.id ? s.active : ''}>
                                    {item.name}
                                </span>
                            );
                     }
                })}
            </div>
        </div>
    )
}

export default withRouter(Categories);