import React from 'react';
import { useTranslation } from "react-i18next";

const Pica45sm = () => {
	 const { t, i18n } = useTranslation(); 
    return (
        <div style={{
            height: 400,
            overflowY: 'scroll',
        }}>
            <h1>{t('pizza45')}</h1>
            <p>Що може бути кращим та смачнішим за піцу? Тільки велика, апетитна піца 45 см. А де найкраще замовляти смачну, гарячу випічку на тонкому тісті, у якої доступна ціна і спритна доставка? Звичайно ж в IQ Pizza!</p>
            <p>Друзі, сьогодні ми розповімо вам, чим така хороша їжа у нас і чому потрібно зробити замовлення в нашому закладі.</p>
            <h2>Скільки важить піца 45 см</h2>
            <p>Піца 45 см на тонкому тісті в нашому закладі має різну вагу незважаючи на те, що страви з однаковий діаметром коржа. Вага випічки безпосередньо залежить від начинки, додаткових інгредієнтів, які ви захочете додати до страви, і від наповнення бортика (сирний або сосисковий). Склад страви ви можете вивчити в картці товару.</p>
            <p>Давайте розберемо приклад, розглянемо мінімальний і максимальний об’єм:</p>
            <ul>
                <li>«Грибна» - 720 грам;</li>
                <li>«Курка BBQ» - 820 грам;</li>
                <li>«Гавайська» - 940 грам;</li>
                <li>«Чізбургер» - 1090 грам.</li>
            </ul>
            <p>З повним асортиментом нашого закладу, можливими додатковими інгредієнтами і наповнювачами для бортика, ви можете ознайомитися в меню, перейшовши в розділ «Піца 45 см», на офіційному сайті або мобільному додатку від IQ Pizza. Там же описані умови доставки. </p>
            <h2>На скільки людей розрахована піца 45 см</h2>
            <p>Кожна смачна піца 45 см від нашого закладу ріжеться на 8 однакових шматочків. «На скільки людей розрахована випічка»: запитаєте ви. Клієнт сам вирішує це! Ви можете з'їсти апетитне і гаряче частування наодинці, а можете розділити його з близькими.</p>
            <p>Приклад: в компанії, що складається з 4 чоловік, кожному дістанеться по 2 шматочки апетитної випічки, у якої тонке тісто. Якщо ви плануєте пообідати вдвох, то кожен зможе поласувати чотирма шматочками апетитної їжі.</p>
            <p>Піца 45 см - ідеальний перекус для кожного. У нашому закладі ви точно знайдете своє улюблене частування. Швидше вибирайте і робіть замовлення. Влаштуйте собі прекрасний обід.</p>
        </div>
    );
}

export default Pica45sm;