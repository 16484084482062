import React from 'react';
import './article.style.css';

const ArticleUa = () => {
    return (
        <div className="4siri">
            <p>Піца 4 Сири 30 см - випічка для тих, хто любить дуже багато сиру в їжі. Тільки уявіть цю смакоту: чотири види апетитного продукту на тонкому тесті. Неможливо встояти перед таким обідом.</p>
            <p>А де ж можна замовити найсмачніше блюдо з золотистою скоринкою? Звичайно ж в IQ Pizza!</p>
            <p>Ми приготуємо, доставимо вам свіжу, гарячу і ситну піцу за будь-якою зручною адресою, до потрібного часу. Піца 4 Сири 30 см, діаметр якої 30 см, розрізана на вісім шматочків. Ви зможете не тільки самі наїстися смачною їжею, а й поділитися нею з близькими.</p>
            <p>Інгредієнти страви:</p>
            <ul>
                <li>соус сирний;</li> 
                <li>"моцарела";</li> 
                <li>"гауда";</li> 
                <li>"дорблю";</li> 
                <li>пармезан.</li> 
            </ul>
            <p>Хочете, щоб частування стало ще смачнішим і ситнішим? Додайте на тісто додаткову начинку з нашого меню. Доповніть склад та оберіть вершковий соус, кукурудзу, зелень чи що вашій душі завгодно! Ми врахуємо і виконаємо всі ваші побажання, щоб зробити смак улюбленої піци ще більш насиченим. Також не забудьте про наповнювачі для бортиків: сири або сосиски.</p>
            <p>Хочете поласувати апетитною випічкою «Чотири сири», але немає часу або лінь йти за нею в піцерію? Вас виручить доставка. Все, що вам потрібно - додати улюблену їжу в кошик, провести оплату. Про інше подбає IQ Pizza! Піца 4 Сири 30 см приїде до вас свіжою та гарячою!</p>
        </div>
    );
}

export default ArticleUa;