import React, { Component } from 'react';
import { setCookie } from '../../components/modalCities/cookie';

import {connect} from 'react-redux';
import Header from './header/header';
import Footer from './footer/footer';

import {Switch, Route, Redirect, withRouter} from 'react-router-dom';
import {updateLanguage, updateActiveCity, updateMenu} from '../../redux/menu';
import { getPathUrl } from './ohter';
import {routes} from './routes';

import Language from '../../components/modalLanguage';
import Cities from '../../components/modalCities';
import i18n from '../../i18n';
import BreadCrums from '../BreadCrumbs';
import MobileMenu from './header/mobileMenu';
import TextMessage from './textMessage/index';

class RouterContainer extends Component{
    constructor(){
        super();
        this.state = {
            loadUrl: false,
            languageModal: false,
            citiesModal: false,
            language: "uk",
            mobileMenu: false,
        }
    }

    componentDidMount(){
        // this.formatUrl();
        let language = localStorage.getItem('language');
        const path = this.props.location.pathname.split('/');
        const isRuLng = path.find(lng => lng === 'ru');

        if (path) {
            this.props.history.push('/');
        }

        // console.log('isRuLng', isRuLng);
        // console.log('path -->>>', path);

        if(language) {
            i18n.changeLanguage(language);
            this.props.updateLanguage(language);
            this.setState({language})
        } else{
            if (isRuLng) {
                i18n.changeLanguage('ru');
                this.props.updateLanguage('ru');
                this.setState({language : 'ru'});    
            } else {
                i18n.changeLanguage('uk');
                this.props.updateLanguage('uk');
                this.setState({language : 'uk'});
            }
            // this.props.history.push('/');
        }
    }

    onMobileMenu = () => {
        this.setState({ mobileMenu: true });
    }

    closeMobileMenu = () => {
        this.setState({ mobileMenu: false });
    }

    formatUrl = () => {
        let defaultUrl = window.location.href.split(window.location.host)[1];
        let url = window.location.href.split(window.location.host)[1].toLowerCase();

        if (url.match(/\?/) && url[url.length -1] === '?') {
            url = url.replace('/?', '');
        }

        if(url.match(/\/\//)) {
            url = url.split('/');
            url = '/' + url.filter(s => !!s).join('/');
        }

        if (defaultUrl !== url) {
            this.props.history.push(url);
            this.setState({ loadUrl: true });
        }
    }

    statusLanguage = () => {
        this.setState({
            languageModal: this.state.languageModal ? false : true
        })
    }

    statusCities = () => {
        this.setState({
            citiesModal: this.state.citiesModal ? false : true
        })
    }

    getProductUrl = (pathname, lng) => {
        const pathArr = pathname.split('/').filter(t => !!t);
        const productUrl = pathArr[pathArr.length - 1];
        let url = '/';
        const dLng = lng === 'uk';

        pathArr.map((path, index) => {
            if (index + 1 < pathArr.length && path !== 'ru') {
                url += path + '/';
            }
        });

        url = !dLng ? url : '/ru' + url;

        const firstPath = getPathUrl(url);
        const result = firstPath + productUrl;

        return result;
    }

    selectLanguage = (lng) => {
        this.props.updateLanguage(lng);
        i18n.changeLanguage(lng);
        this.setState({language: lng});
        localStorage.setItem('language', lng);

        let pathname = this.props.location.pathname;
        let urlNesting = pathname.split('/').filter(t => !!t);

        if (urlNesting.filter(t => t !== 'ru' && t !== 'success').length >= 3) {
            pathname = this.getProductUrl(pathname, lng);
        } else {
            pathname = getPathUrl(pathname);

            const isRuLng = pathname.indexOf('/ru') !== -1;

            if (pathname != '/') {
                pathname = lng === 'uk' ? pathname.replace('ru/', '') : isRuLng ? pathname : '/ru' + pathname;
            } else {
                pathname = lng === 'uk' ? pathname.replace('ru/', '') : isRuLng ? pathname : '/ru' + pathname;
            }
        }

        console.log('pathname -->>>', pathname, urlNesting);

        this.props.history.push(pathname);
    }

    selectCity = (data) => {
        if (this.props.isHomePage) {
            setCookie('city', data.id, {secure: true, 'max-age': 3600});
        }

        this.props.updateActiveCity(data);
        localStorage.setItem('city', JSON.stringify(data));
    }

    render(){
        let lng = this.state.language;
        const dLng = lng === 'uk';
        const isHome = this.props.location.pathname === '/' || this.props.location.pathname === '/ru/';
        return(
            <>
                {this.state.mobileMenu && 
                    <MobileMenu 
                        closeMobileMenu={this.closeMobileMenu}
                        openCities={this.statusCities} 
                        openLanguage={this.statusLanguage} 
                    />}
                <Header 
                    mobileMenu={this.state.mobileMenu}
                    onMobileMenu={this.onMobileMenu} 
                    closeMobileMenu={this.closeMobileMenu} 
                    activeCity={this.props.activeCity} 
                    basketCount={this.props.basketCount} 
                    openLink={this.openLink} 
                    openLanguage={this.statusLanguage} 
                    openCities ={this.statusCities} 
                    language={this.props.language} 
                />
                    {this.state.languageModal && (
                        <Language 
                            handleClick={this.statusLanguage} 
                            selectLanguage={this.selectLanguage} 
                            language={this.props.language}
                        />
                    )}
                    {this.state.citiesModal && (
                        <Cities 
                            cities={this.props.cities}
                            activeCity={this.props.activeCity}
                            handleClick={this.statusCities} 
                            selectCity={this.selectCity} 
                            city={this.state.city}
                            language={this.props.language}
                        />
                    )}
                    <BreadCrums />
                        <Switch>
                            {routes(lng).map(({ exact, path, component }, index) => 
                                <Route key={index} exact={exact} path={path} render={() => component} />)}
                            {!dLng && <Redirect exact path={['/', '/r', '/ru']} to={'/ru/'} />}
                            {/* <Redirect path={'*'} to={'/'} /> */}
                        </Switch>
                {isHome && <TextMessage />}
                <Footer/>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        basketCount: state.menu.basketCount,
        cities: state.menu.cities,
        activeCity: state.menu.activeCity,
        products: state.menu.products,
        categories: state.menu.categories,
        language: state.menu.languageMenu,
        isHomePage: state.menu.isHomePage,
    }
}

export default connect(mapStateToProps, {updateLanguage, updateActiveCity, updateMenu})(withRouter(RouterContainer));