import React from 'react';
import './article.style.css';

const ArticleUa = () => {
    return (
        <div className="about-us">
            <h1>ПІЦА СИРНЕ КУРЧА</h1>
            <p>Піца Сирне курча 30 см - ніжна і смачна випічка з куркою, яка вам точно сподобається! Апетитне куряче м'ясо під хрусткою скоринкою. Слинки вже потекли? Тоді швидше замовляйте це частування від IQ Pizza!</p>
            <p>Варіанти начинки:</p>
            <ul>
                <li>соус сирний;</li>
                <li>Моцарелла";</li>
                <li>"Гауда";</li>
                <li>курча.</li>
            </ul>
            <p>Щоб піца Сирне курча 30 см стала ще смачнішою, додайте на тісто додаткові інгредієнти. Випічку можуть доповнити: сир твердий, м'ясо, ананаси, оливки, зелень і багато іншого! Також не забудьте додати наповнювачі в бортик собі до смаку: сир, сосиски.</p>
            <p>Замовити улюблене блюдо можна щодня, з 10:00 до 22:00.</p>
            <p>Як оформити доставку:</p>
            <ul>
                <li>телефон 068 115 1010;</li>
                <li>сайт;</li>
                <li>мобільний додаток.</li>
            </ul>
            <p>З подробицями кур'єрської служби ви можете ознайомитися в пункті меню «Доставка».</p>
        </div>
    );
}

export default ArticleUa;